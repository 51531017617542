import React, { useEffect } from "react";
import BasePopup from "../../../../tools/components/general/BasePopup";
import { PaymentStatus } from "../../payment.utils";
import MainButton from "../../../../tools/components/general/MainButton";
import { DatePicker } from "tc-minibox";
import { useDispatch } from "react-redux";
import { set_popup_warning } from "../../../../store/global/actions.global";



export interface FullPayPopupProps {
    handleClose : () => void, 
    mode : "comment" | "payment", 
    newStatus : PaymentStatus, 
    setNewStatus : React.Dispatch<React.SetStateAction<PaymentStatus>>, 
    newPayment : any, 
    setNewPayment : React.Dispatch<React.SetStateAction<any>>, 
    handleSubmitPayment : () => void, 
    handleSubmitComment : () => void

}

export default function FullPayPopup(props:FullPayPopupProps) {

    return (
        <BasePopup 
            handleClose={() => {
                props.setNewStatus((state:PaymentStatus) => {return{...state, name : "awaiting-payment"}})
                props.handleClose()
            }} 
            nameClass="full-pay-popup">
            {
                props.mode === "comment" ? 
                    <React.Fragment>
                        <label htmlFor = "comment">Votre commentaire</label>
                        <textarea
                            value={props.newStatus.content}
                            onChange={(e) => props.setNewStatus((state:any) => {return{...state, content : e.target.value}})}
                            placeholder="Tapez ici...."
                            onKeyDown={(e) => {
                                
                            }}
                        ></textarea>
                    </React.Fragment>
                : 
                    <React.Fragment>
                        <label htmlFor = "comment">Veuillez indiquer la date de paiement ?</label>
                        <DatePicker
                            selected={new Date(props.newPayment.date)} 
                            dateFormat="dd/MM/yyyy"
                            onChange = {(e:any) => props.setNewPayment((state:any) => {return{...state, date : e}})}
                            locale="fr"   
                        />
                    </React.Fragment>
            }
            <MainButton handleClick={() => {
                props.handleSubmitComment()
                props.handleSubmitPayment()
                props.handleClose()
            }}>
                Valider
            </MainButton>
        </BasePopup>
    )
}