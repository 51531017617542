import {BrowserRouter, Routes, Route} from 'react-router-dom' ; 
import axios from "axios";
import { server_host } from "../config/server.config";
import SignIn from './sign-in/SignIn';
import ForgotPwd from './forgot-pwd/ForgotPwd';

export default function Auth() {
    const server = axios.create({
        baseURL: server_host(),
    });

    /*
    ///////////////// INTERCEPTORS TO INTEGRATE /////////////////
    api.interceptors.request.use(
        config => {
          const token = user_account.authentification.token_session;
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
    );

    // Add a response interceptor to handle 401 Unauthorized errors
    api.interceptors.response.use(
        response => {
        return response;
        },
        error => {
        if (error.response && error.response.status === 401) {
            dispatch(set_unauthorized())
        }
        return Promise.reject(error);
        }
    );*/

    return (
        <BrowserRouter>
            <Routes>
                <Route path = "/forgot-pwd" element = {<ForgotPwd server = {server} />} />
                <Route path = "*" element = {<SignIn server = {server} />} />
            </Routes>
        </BrowserRouter>
    );
}