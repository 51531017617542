import { ChildrenSignUpProps } from "../ForgotPwd" ; 
import MainButton from "../../../tools/components/general/MainButton";


export default function Form(props:ChildrenSignUpProps) {

    return (
        <div className="forgot-pwd--props.form">
            <h1>Mot de passe oublié</h1>
            <p>Si vous avez un compte, un code de vérification vous sera envoyé par e-mail.</p>
            <input
                type = "text"
                name = "email"
                value = {props.form.mail}
                onChange = {(e) => props.setForm(state => {return{...state, mail : e.target.value}})}
                placeholder='Votre adresse mail'
            />
            <MainButton
                handleClick = {props.handleSubmit}
            >
                Envoyer
            </MainButton>

        </div>
    )
}