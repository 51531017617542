import {useState} from 'react' ; 
import {AnimatePresence, motion} from 'framer-motion'
import { useNavigate } from "react-router-dom"
import { ChildrenSignUpProps } from "../ForgotPwd"
import { useDispatch } from "react-redux"
import { Icons, StringUtils } from "tc-minibox"
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global"
import MainButton from '../../../tools/components/general/MainButton';

export type EventsProps = {
    active : string | null,
    visible_pwd : string | null
}



export default function Pwd(props:ChildrenSignUpProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pwd, setPwd] = useState({
        pwd_1 : "", 
        pwd_2 : ""
    })
    const [events, setEvents] = useState<EventsProps>({
        active : null, 
        visible_pwd : null
    })

    const checkpoints = [
        {
            id : "CHECKPOINTS_01", 
            name : "length", 
            text : "8 caractères.", 
            status : StringUtils.checkPasswordStrength(pwd.pwd_1).length
        }, 
        {
            id : "CHECKPOINTS_02", 
            name : "upper_case", 
            text : "Une majuscule.",
            status : StringUtils.checkPasswordStrength(pwd.pwd_1).upper_case
        }, 
        {
            id : "CHECKPOINTS_03", 
            name : "lower_case", 
            text : "Une minuscule.", 
            status : StringUtils.checkPasswordStrength(pwd.pwd_1).lower_case
        }, 
        {
            id : "CHECKPOINTS_04", 
            name : "digit", 
            text : "Un chiffre.", 
            status : StringUtils.checkPasswordStrength(pwd.pwd_1).digit
        }, 
        {
            id : "CHECKPOINTS_05", 
            name : "special_char", 
            text : "Un caractère spécial (!@#$).", 
            status : StringUtils.checkPasswordStrength(pwd.pwd_1).special_char
        }, 
    ]

    const handleSubmit = () => {
        if(Object.values(StringUtils.checkPasswordStrength(pwd.pwd_1)).filter(cp => !cp).length > 0) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez respecter le format du premier champ."
            }))
        }

        if(pwd.pwd_1 !== pwd.pwd_2) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Les mots de passe ne correspondent pas."
            }))
        }
        dispatch(setLoaderStatus({status : true}))
        return props.server.post('/auth/forgot-pwd/pwd', {
            token : props.form.token, 
            pwd : pwd.pwd_1
        })
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            navigate('/')
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            console.log(err)
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus({
                status : false, 
                message : "Une erreur est survenue, veuillez réessayer."
            }))
        })

    }

    return (
        <div className='forgot-pwd--pwd'>
            <div className='intro'>
                <h1>Nouveau mot de passe</h1>
                <p>Veuillez compléter la procédure de création du nouveau mot de passe.</p>
            </div>
            <div className='pwd_1'>
                <label htmlFor='pwd_1'>Votre nouveau mot de passe</label>

                <div className='input'>
                    <input 
                        onFocus={() => setEvents(state =>{return{...state, active : "CHECKING_PWD01"}})}
                        onBlur={() => setEvents(state =>{return{...state, active : null}})}
                        autoComplete="new-password"
                        placeholder='Mot de passe'
                        type = {events.visible_pwd === "VISIBLE_PWD01" ? "text" : "password"}
                        name = 'pwd_1'
                        value = {pwd.pwd_1}
                        onChange = {(e) => setPwd(state => {return{...state, pwd_1 : e.target.value}})}
                    />
                    <motion.div 
                        className='visible'
                        onClick = {() => setEvents(state =>{return{...state, visible_pwd : state.visible_pwd === "VISIBLE_PWD01" ? null : "VISIBLE_PWD01" }})}
                    >
                        <Icons
                            name = {events.visible_pwd === "VISIBLE_PWD01" ? "eye-slash" : "eye"}
                        />
                    </motion.div>
                </div>

                {
                    events.active === "CHECKING_PWD01" ? 
                        <motion.div 
                            exit={{height : 0, opacity : 0}}
                            animate={{height : "auto", opacity : 1}}
                            initial={{height : 0, opacity : 0}}
                            className='checking-format'
                        >
                        <p>Le mot de passe doit contenir au moins : </p>
                        <div>
                            {
                                checkpoints.map(check => (
                                    <div 
                                        key = {check.id}
                                        className={check.status ? "active" : ""}
                                    >
                                        <Icons name = {check.status ? "check" : "xmark"} mode = "duotone" color = "rgb(199, 1, 1)" />
                                        <p>{check.text}</p>
                                    </div>
                                ))
                            }
                        </div>
                        </motion.div>

                    : null
                }


            </div>
            <div className='pwd_2'>
                <label htmlFor='pwd_2'>Confirmez votre mot de passe</label>
                <div className='input'>
                    <input 
                        onFocus={() => setEvents(state =>{return{...state, active : "CHECKING_PWD02"}})}
                        onBlur={() => setEvents(state =>{return{...state, active : null}})}
                        autoComplete="off"
                        placeholder='Mot de passe'
                        type = {events.visible_pwd === "VISIBLE_PWD02" ? "text" : "password"}
                        name = 'pwd_2'
                        value = {pwd.pwd_2}
                        onChange = {(e) => setPwd(state => {return{...state, pwd_2 : e.target.value}})}
                    />
                    <motion.div 
                        className='visible'
                        onClick = {() => setEvents(state =>{return{...state, visible_pwd : state.visible_pwd === "VISIBLE_PWD02" ? null : "VISIBLE_PWD02" }})}
                    >
                        <Icons 
                            name = {events.visible_pwd === "VISIBLE_PWD02" ? "eye-slash" : "eye"}
                        />
                    </motion.div>

                </div>


                {
                    events.active === "CHECKING_PWD02" && pwd.pwd_1 !== pwd.pwd_2 && pwd.pwd_2.length > 0 ? 
                        <motion.div 
                            exit={{height : 0, opacity : 0}}
                            animate={{height : "auto", opacity : 1}}
                            initial={{height : 0, opacity : 0}}
                            className='checking-format'
                        >
                            <p>
                                {
                                    pwd.pwd_1.length === 0 ? 
                                        "Veuillez remplir le premier champ"
                                    : 
                                        Object.values(StringUtils.checkPasswordStrength(pwd.pwd_1)).filter(cp => !cp).length > 0 ? 
                                            "Veuillez remplir les conditions du premier champ"
                                        : 

                                            "Les mots de passe ne correspondent pas"
                                }
                            </p>
                        </motion.div>

                    : 
                        null
                }
            </div>
            <MainButton handleClick = {handleSubmit}>Valider</MainButton>
        </div>
    )
}