/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react' ; 
import {AnimatePresence} from 'framer-motion' ;
import { useDispatch} from 'react-redux' ;
import NewUser from './layouts/NewUser';
import DetailUser from './layouts/DetailUser';
import ListingUser from './layouts/ListingUser';
import StepsText from '../../../tools/components/general/StepsText';
import { ServerProps } from '../../../config/server.config';
import { addRequestStatus, setLoaderStatus } from '../../../store/global/actions.global';

export interface AccessProps extends ServerProps {}

export default function Access(props:AccessProps) {
    ////////////// VARIABLES //////////////

    //REDUX
    const dispatch = useDispatch()

    //REDUX
    const [manager, setManager] = useState({
        initialization : "pending", 
        add : false, 
        steps : 0, 
        selected_data : [], 
        doubleClicked_data : {}
    })
    const [accessUsers, setAccessUsers] = useState<any>([])

    useEffect(() => {
        if(manager.initialization === "pending") {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données..."}))
            props.server.post('/access/initialize')
            .then(res => {
                setManager(state => { return {...state, initialization : "resolve"}})
                setAccessUsers(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
        }
    }, [])

    const handleAdd = () => {
        setManager(state => {
            return {
                ...state, 
                add : !state.add
            }
        })
    }

    const handleDelete = () => {
        dispatch(setLoaderStatus({status : true, message : ""}))
        props.server.post('/access/deleteUser', manager.selected_data)
        .then((res) => {
            setAccessUsers((state:any) => {
                return state.filter((el:any) => !manager.selected_data.includes(el._id as never))
            })
            //setAccessUsers(accessUsers.filter((el:any) => !manager.selected_data.includes(el._id)))
            setManager(state => { return {...state, selected_data : []}})
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    /*
    useEffect(() => {
        socket.on('newUser-created', (res) => {
            console.log(res)
        });
    }, [socket, dispatch]);*/

    //console.log(accessUsers)
    return (
        <React.Fragment>

            {
                manager.add ? 
                    <NewUser 
                        server = {props.server}
                        handleClose = {handleAdd}
                        setData = {setAccessUsers}
                    />
                : 
                    null
            }




                <div className = "access">
                    <StepsText
                        title = {"Gestion des utilisateurs"}
                        steps = {[
                            {id : 'STEPS-01', 
                                status : 1, 
                                name : "Import des fichiers"
                            }, 
                            {
                                id : 'STEPS-02', 
                                status : 2, 
                                name : "Correction tableur"
                            }, 
                            {
                                id : 'STEPS-03', 
                                status : 3, 
                                name : "Informations de l'affaire"
                            }
                        ].slice(0, manager.steps)}
                        position = {{
                            x : 60, 
                            y : 0
                            
                        }}
                    />
                    {
                        manager.initialization === "resolve" ? 
                            Object.entries(manager.doubleClicked_data).length === 0 ? 
                                <ListingUser
                                    server = {props.server}
                                    data = {accessUsers}
                                    manager = {manager}
                                    setManager = {setManager}
                                    accessUsers = {accessUsers}
                                    handleAdd = {handleAdd}
                                    handleDelete = {handleDelete}
                                />
                            : 
                                <DetailUser 
                                    handleClose = {() => {
                                        setManager(state => {return {...state, doubleClicked_data : ""}})
                                    }}
                                    manager = {manager}
                                    accessUsers = {accessUsers}
                                    setAccessUsers = {setAccessUsers}
                                    server = {props.server}
                                    
                                />
                        :   
                            null
                    }
                </div>
        </React.Fragment>

    )
}

