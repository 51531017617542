/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import BasePopup from '../../../../tools/components/general/BasePopup'; 

import { ServerProps, socket_server } from '../../../../config/server.config';
import InvoiceImport from './deposit/ImportDeposit';
import InvoiceProcessing from './deposit/ProcessingDesposit';
import { useDispatch } from 'react-redux';
import { setLoaderStatus } from '../../../../store/global/actions.global';
import { SuppliersInvManager } from '../SuppliersInv';
import { mode_test_invoice_deposit } from '../../../../config/initials_states';


export interface SupplierDepositProps extends ServerProps {
    setManager : React.Dispatch<React.SetStateAction<SuppliersInvManager>>,
    handleClose : () => void, 

}

export interface SupplierDepositManager {
    mode : "import" | "processing" | "detail", 
}

export const handleChangeObjectState = (setState:any, name:any, val:any) => setState((state:any) => {return{...state, [name] : val}})


export default function SupplierInvDeposit(props:SupplierDepositProps) {
    const [manager, setManager] = useState<SupplierDepositManager>({
        mode : "import"
    })
    const [form, setForm] = useState({
        id_affair : null, 
        file : {}
    })
    const dispatch = useDispatch()

    const test_data = [
        {
            "id_affairs": [
                {
                    "id": 1,
                    "description": "",
                    "ht": 112,
                    "tva": 2.35
                }
            ],
            "dates": {
                "inv_date": "2023-10-26T20:00:00.000Z",
                "due_date": "2023-10-26T20:00:00.000Z",
                "receipt_date": null
            },
            "doc_number": "2024/01",
            "inv_number": "ACP436",
            "total_tva": 2.35,
            "total_ttc": 114.35,
            "comment": "",
            "supposed_supplier": "Talarmor Distri",
            "url": "https://rbnk.me/i/uupi2-s115E",
            "id_supplier": 5,
            "name_supplier": "Talarmor Distri"
        }
    ]

    const [importedData, setImportedData] = useState<any[]>(mode_test_invoice_deposit ? test_data : [])

    useEffect(() => {
        // Écoutez l'événement "deposit-status-res" côté client
        socket_server.on('deposit-inv-supplier-status', (data) => {
            dispatch(setLoaderStatus({status : true, message : data}))
        });
    }, [socket_server])

    return (
        <BasePopup 
            handleClose = {props.handleClose}
            nameClass = {`invoicing-deposit ${manager.mode === "processing" ? "invoicing-deposit--fullscreen" : ""}`}
        >

            {
                manager.mode === "processing" ? 
                    <InvoiceProcessing
                        rerender_affairs = {() => props.setManager((state:SuppliersInvManager) => {return{...state, initialize : false}})}
                        id_affair = {form.id_affair}
                        server = {props.server}
                        data = {importedData} 
                        setData = {setImportedData}
                        manager = {manager}
                        setManager = {setManager}
                        handleClose={props.handleClose}
                    />
                :
                    <InvoiceImport
                        server={props.server}
                        form = {form}
                        setForm = {setForm}
                        setManager={setManager}
                        setImportedData={setImportedData}
                    />
            }

        </BasePopup>
    )
}

