/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' ; 
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addRequestStatus, setLoaderStatus } from '../../../store/global/actions.global';
import { ServerProps } from '../../../config/server.config';
import { StringUtils, URLUtils } from 'tc-minibox';
import BaseApp from '../../../tools/components/general/BaseApp';
import BaseContainer from '../../../tools/components/general/BaseContainer';
import { DataType} from 'tc-minibox/dist/types/table/index.table';
import SupplierInvDeposit from './layouts/Deposit';
import ListingInv from './layouts/Listing';
import StepsText from '../../../tools/components/general/StepsText';
import pako from 'pako'
import MainButton from '../../../tools/components/general/MainButton';
import {ReactComponent as PrintingInvoice} from '../../../assets/svg/illustrations/printing_invoice.svg'

interface SuppliersInvProps extends ServerProps {

}

export interface SuppliersInvManager {
    initialize : boolean, 
    mode : "listing", 
    deposit : boolean, 
    selected_data : number | null
}

export default function SuppliersInv(props:SuppliersInvProps) {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params = searchParams.get('id_affair');

    const [manager, setManager] = useState<SuppliersInvManager>({
        initialize : false, 
        mode : "listing", 
        deposit : false,
        selected_data : null
    })

    const [invoices, setInvoices] = useState<DataType>([])


    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})

    useEffect(() => {
        if(!manager.initialize) {
            setInvoices([])
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            props.server.get('/invoicing/listing')
            .then(res => {
                setManager(state => { return {...state, initialize : true}})
                var result = pako.inflate(res.data.data, { to: 'string' });
                result = JSON.parse(result)
                const data = (result as any).map((dt:any) => {
                    return {
                        ...dt, 
                        dates : Object.fromEntries(Object.entries(dt.dates).map(([key, value]) => [key, new Date(value as string)]))
                    }
                })
                setInvoices(data)
                if(id_affair_params !== null) {
                    const parsedValue = parseInt(id_affair_params);
                    
                    // Check if parsedValue is not undefined
                    if(!StringUtils.isUndefinedValue(parsedValue)) {
                        setManager(state => { return {...state, selected_data: parsedValue} });
                
                        // Assuming removeURLParameter expects a string, don't pass null
                        URLUtils.removeURLParameter('id_affair');
                    }
                }
                
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                handleChangeManager("initialization", "resolve")
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    return (
        <React.Fragment>
            {/* DEPOSIT */}
            {
                
                manager.deposit ? 
                    <SupplierInvDeposit
                        setManager={setManager}
                        handleClose = {() => setManager(state => {return{...state, deposit : false}})}
                        server = {props.server}
                        
                    />
                : 
                    null
            }

            <BaseApp>
                <BaseContainer nameClass="invoicing">
                    <StepsText
                        title = "Facturation fournisseurs"
                        position = {{
                            x : 60, 
                            y : 0
                        }}
                    />
                    {
                        invoices.length > 0 ? 
                            <ListingInv 
                                server={props.server}
                                data = {invoices}
                                setData={setInvoices}
                                handleAdd = {() => handleChangeManager('deposit', true)}
                                setManager={setManager}
                            />
                        : 
                        
                            <div className='no-data'>
                                <PrintingInvoice />
                                <MainButton handleClick = {() => handleChangeManager('deposit', true)}>
                                    Effectuer un dépôt
                                </MainButton>
                            </div>
                    }



                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}