/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react' ; 
import { ServerProps } from '../../../config/server.config';
import { useDispatch } from 'react-redux';
import { type_payments } from '../utils.suppliers';
import { addRequestStatus, setLoaderStatus } from '../../../store/global/actions.global';
import { DataType } from 'tc-minibox/dist/types/table/index.table';
import BasePopup from '../../../tools/components/general/BasePopup';
import MainButton from '../../../tools/components/general/MainButton';
import CategorizerManager from '../../../tools/components/general/Categorizer';
import PostalAddress from './grid/PostalAddress';
import PaymentChoice from './grid/PaymentChoice';
import Contact from './grid/Contact';


export interface SupplierManagerProps extends ServerProps {
    update? : { 
        status : boolean, 
        content : FormSupplierManager
    }, 
    supposed_supplier? : {[key : string] : any}, 
    data? : DataType, 
    setData? : React.Dispatch<React.SetStateAction<DataType>>, 
    handleAdd : (data:any) => void,
    handleClose : () => void,
}

/*type SupplierManagerEvents = {
    hover : string | null
}*/

export type FormSupplierManager = {
    _id? : any,
    name : string,
    payment_choice : string,
    payment_info : {
        account_number : string,
        bank_code : string,
        account_holder : string
    },
    payment_deadline : number, 
    mail : string,
    address : string[],
    zip_code : string,
    city : string,
    contact : string[], 
    handleSubmit? : () => void
}

export default function SupplierManager(props:SupplierManagerProps) {
    const dispatch = useDispatch()

    const [form, setForm] = useState<FormSupplierManager>(props.update?.status ? props.update.content : {
        name : props.supposed_supplier?.name ? props.supposed_supplier?.name : "", 
        payment_choice : type_payments[0].name, 
        payment_info : {
            account_number : "",
            bank_code : "",
            account_holder : ""
        }, 
        payment_deadline : 1,
        mail : "",
        address : ["", ""],
        zip_code : "",
        city : "",
        contact : []
    })


    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:any = '') => {
        var value = e.target.value

        if(reg==="") {
            value = value.replace(reg, '')
        }

        setForm((state:any) => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const config = [
        {
            id : "MOD-00", 
            name : "Adresse postale",
            svg : "home", 
            component : <PostalAddress
                form = {form}
                handleChangeInput = {handleChangeInput}
                setForm = {setForm}
            />
        }, 
        {
            id : "MOD-01", 
            svg : "money-bill", 
            name : "Paiement",
            component : <PaymentChoice
                form = {form}
                setForm = {setForm}
                handleChangeInput={handleChangeInput}
            />
        }, 
        {
            id : "MOD-02", 
            name : "Contact", 
            svg : "users", 
            component : <Contact
                form = {form}
                setForm = {setForm}
            />
        }, 
    ]

        /*const [manager, setManager] = useState({
        initialization : "pending", 
        selected_modal : config[0].id
    })

const [events, setEvents] = useState<SupplierManagerEvents>({
        hover : null
    })*/

    const handleSubmit = () => {

        if(form.name.trim() === "") {
            return dispatch(addRequestStatus({
                status : false,
                message : "Le nom du fournisseur est obligatoire"
            }))
        }

        dispatch(setLoaderStatus({status : true}))

        const data = {
            ...form, 
            payment_info : form.payment_info, 
            address : form.address, 
            contact : form.contact,

        }

        return props.server.post('/suppliers/add', data) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))
            props.handleClose()
            props.handleAdd(res.data.data)
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    //const handleChangeModals = (id:string) => setManager(state => {return{...state, selected_modal : id}})
    //const handleEvents = (event:string, val:any) => setEvents(state => {return{...state, [event] : val}})

    const handleUpdate = async () => {
        dispatch(setLoaderStatus({status : true}))
        const data = {
            ...form, 
            payment_info : form.payment_info, 
            address : form.address, 
            contact : form.contact,

        }

        

        return props.server.post('/suppliers/update', data) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))
            props.handleClose()
            if(props.setData) {
                props.setData(state => state.map(el => {
                    if(el._id === form._id) {
                        return form
                    } else {
                        return el
                    }
                }))
            }

        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    /*useEffect(() => {
        if(manager.initialization === "pending" && props.update?.status) {
            setForm(props.update?.content)
        }
    }, [])*/

    return (
        <BasePopup
            nameClass = "suppliers-creation suppliers-manager"
            handleClose = {props.handleClose}
        >
            <div className='name'>
                <label htmlFor='name'>Nom</label>
                <input 
                    type = "text"
                    name = "name"
                    placeholder='Ex : OGEC'
                    value = {form.name}
                    onChange = {handleChangeInput}
                />
            </div>

            <CategorizerManager config = {config} rerender = {true} />

            {
                props.update?.status ?
                    JSON.stringify(props.update.content) !== JSON.stringify(form) ?
                        <MainButton
                        handleClick = {handleUpdate}
                        >
                            Modifier
                        </MainButton>
                    : 
                        null
                : 
                    <MainButton
                    handleClick = {handleSubmit}
                    >
                        Créer
                    </MainButton>
            }


    
        </BasePopup>
    )
}



