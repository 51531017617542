import React from 'react' ;  
export interface BaseContainerProps {
    key_id? : number | string
    nameClass ?: string, 
    children? : React.ReactNode
}

/*const animationVariants = { 
    initial : {y : 120, opacity : 0}, 
    animate : {y : 0, opacity : 1}, 
    exit : {y : 120, opacity : 0}, 
    transition : {
        type : "linear", 
        duration : 0.2
    }
}*/

export default function BaseContainer(props:BaseContainerProps) {
    return (
        <div 
            key = {props.key_id?? "BASE-CONTAINER"}
            className={`app__container ${props.nameClass}`}
        >
            {props.children as JSX.Element}
        </div>
    )
}