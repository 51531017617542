import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import { useLocation} from "react-router-dom";
import {motion} from 'framer-motion' ; 
import { AffairManager } from "../Affairs";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import { ServerProps } from "../../../config/server.config";
import ContainerLoad from "../../../tools/components/general/ContainerLoad";
import { Icons } from "tc-minibox";
import SummaryDetails, { SummaryDetailsProps } from "./layouts/SummaryDetails";
import Grid, { GridProps } from "./layouts/Grid";


export type SelectedDataItem = {
    id_affair: string;
    [key: string]: any;
  };

export interface DetailProps extends ServerProps {
    manager : AffairManager,
    setManager : React.Dispatch<React.SetStateAction<AffairManager>>,
    selected_data : SelectedDataItem
}

interface DetailManager {
    mode : "load" | "normal", 
    initialize: boolean, 
    totals_update : boolean
}

export default function Detail(props: DetailProps) {
    // * URL PARAMS && REDUX
    const dispatch = useDispatch();
    //const location = useLocation();
    //const searchParams = new URLSearchParams(location.search);
    //const id_affair_params: string | null = searchParams.get('id_affair');
  
    // * STATES
    const [manager, setManager] = useState<DetailManager>({
        totals_update : true,
        mode : "load",
        initialize: false,
    });
    const [selected_affair, setSelectedAffair] = useState<SelectedDataItem>(props.selected_data);
  
    // * USE EFFECTS
    useEffect(() => {
      if (!manager.initialize) {
        props.server.post('/affairs/get-affair-totals', { id_affair: props.selected_data._id})
          .then((res) => {
            setSelectedAffair(state => {return{...state, ...res.data.data}})
            dispatch(setLoaderStatus({ status: false }))
            setManager((state) => {return { ...state, initialize: true, mode : "normal" };});
          })
          .catch((_: any) => {
            dispatch(setLoaderStatus({ status: false }))
            props.setManager((state) => {return { ...state, mode: 'listing' }});
            dispatch(
              addRequestStatus({
                status: false,
                message: "Impossible d'ouvrir l'affaire, veuillez réessayer",
              })
            );
          });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manager.initialize]);

    // * COMPONENTS CONFIG 
    const summarydetail_props:SummaryDetailsProps = {
        selected_data : selected_affair, 
        setSelectedData : setSelectedAffair,
        server : props.server
    }
    const grid_props:GridProps = {
        rerender_detail : () => setManager(state => {return{...state, initialize : false}}),
        server : props.server,
        selected_affair : selected_affair, 
        id_affair : selected_affair._id
    }
    return (
        <ContainerLoad load = {manager.mode === "load"}>

            <div className="affair-detail">
              <motion.button 
                  className='button-return' 
                  onClick = {() => props.setManager(state => {return{...state, mode : "listing", selected_data : null, initialize : false }})}
              >
                  <Icons name = "arrow-turn" mode = "duotone" color = "white" />
              </motion.button>
              <SummaryDetails {...summarydetail_props} />

              <Grid {...grid_props} />

            </div>
        </ContainerLoad>

    )
  }
  