/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react' ; 
import {v4} from "uuid" ; 
import { useDispatch } from 'react-redux';
import { ArrayUtils, Icons, ObjectUtils } from 'tc-minibox';
import { motion, AnimatePresence } from 'framer-motion';
import { access_type, icons_config, users_group } from '../../../../config/context.config'
import { navigation} from '../../../nav/data.nav';
import {ReactComponent as IllustrationUser} from '../../../../assets/svg/illustrations/user-manager_illustration.svg'; 
import { ServerProps } from '../../../../config/server.config';
import { addRequestStatus } from '../../../../store/global/actions.global';

export interface DetailUserProps extends ServerProps {
    handleClose : () => void, 
    manager : any, 
    accessUsers : any, 
    setAccessUsers : any, 
}

export default function DetailUser(props:DetailUserProps) {
    const dispatch = useDispatch()
    const user_selected = props.accessUsers.find((el:any) => el._id === props.manager.doubleClicked_data)
    const [user, setUser] = useState(user_selected)

    const [manageSubList, setManageSubList] = useState<any>(null)



    const handleChangeInput = (e:any, reg:RegExp|string = '') => {
        const value = e.target.value.replace(reg, '').trim()

        setUser((state:any) => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }


    const handleChangeAccess = (id:number, val:string) => {

        setManageSubList(null)

        //Dans le cas où il n'était pas dans la bdd, 
        if(!user.access.find((acc:any) => acc.id_menu === id)) {
            return setUser((state:any) => {
                const getid = v4()
                return {
                    ...state, 
                    access : [
                        ...state.access, 
                        {
                            "id_temp" : getid, 
                            "token_access" : getid,
                            "id_user": user._id,
                            "id_menu": id,
                            "mode": val, 
                        }
                    ]
                }
            })
        }

        // Dans le cas où il y était
        if(user.access.find((acc:any) => acc.id_menu === id)) {
            return setUser((state:any) => {
                return {
                    ...state, 
                    access : state.access.map((acc:any) => acc.id_menu === id ? {...acc, mode : val} : acc )
                }
            })
        }



    }

    //DEBOUNCING
    useEffect(() => {
        const timeoutId = setTimeout(() => { 
            const oldUser = props.accessUsers.find((el:any) => el._id === props.manager.doubleClicked_data)
            const user_diff = ObjectUtils.getObjectDifference(ObjectUtils.removeKeyFromObject(oldUser, "access") , ObjectUtils.removeKeyFromObject(user, "access"))
            const access_diff = ArrayUtils.getArrayDifference(oldUser.access, user.access)



            if(access_diff.length > 0 || Object.entries(user_diff).length > 0) {
                dispatch(addRequestStatus({
                    status : null, 
                    message : "Enregistrement en cours..."
                }))

                /*console.log(user.access)
                console.log("Access diff : ", access_diff)
                console.log('Create :', access_diff.filter(acc => !acc.id_droit_acces))
                console.log('Update :', access_diff.filter(acc => acc.id_droit_acces))*/

                props.server.post('/access/update', {id_user : user._id, user_diff : user_diff, access_diff : { create : access_diff.filter(acc => acc.id_temp), update : access_diff.filter(acc => !acc.id_temp)}})
                .then(res => {
                    const result = {
                        ...user, 
                        //access : user.access.map(acc => !acc.id_droit_acces ? {...acc, id_droit_acces : 0} : acc)
                        access : user.access.map((acc:any) => ObjectUtils.removeKeyFromObject(acc, "id_temp"))
                    }
                    props.setAccessUsers((state:any) => {
                        return state.map((el:any) => el._id === user._id ? result : el)
                    })

                    setUser(result)

                    dispatch(addRequestStatus({
                        status : true, 
                        message : "Modifications effectuées."
                    }))
                })
                .catch(err => {
                    console.log(err)
                }) 
            } 

        }, 1500); // Attendre 1000ms avant d'envoyer la requête
      
          return () => clearTimeout(timeoutId);
    }, [user, dispatch])
    
    
    //console.log(user.access)

    return (
        <div className='detail_user'>
            <motion.div
                whileHover = {{scale : 1.1}}
                whileTap = {{scale : 0.90}}
                onClick = {props.handleClose} 
                className='return'

            >
                <Icons name = "arrow-turn" {...icons_config} />
            </motion.div>
            <div className='content'>
                <motion.div 
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    variants = {{
                        exit : {y : 100, opacity : 0}, 
                        animate : {y : 0, opacity : 1}, 
                        initial : {y : 100, opacity : 0}
                    }}
                    transition = {{duration : 0.3}}
                    className='identification'

                >
                    <h1>Identification</h1>
                    <div>
                        <div>
                            <label htmlFor='lastname'>Nom</label>
                            <input 
                                type = "text"
                                name = "lastname"
                                value = {user.lastname}
                                onChange = {(e) => handleChangeInput(e, /[0-9]/g)}
                            />
                        </div>
                        <div>
                            <label htmlFor='firstname'>Prénom</label>
                            <input 
                                type = "text"
                                name = "firstname"
                                value = {user.firstname}
                                onChange = {(e) => handleChangeInput(e, /[0-9]/g)}
                            />
                        </div>
                    </div>
                </motion.div>

                <motion.div 
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    transition = {{duration : 0.3, delay : 0.2 * 1}}
                    variants = {{
                        exit : {y : 100, opacity : 0}, 
                        animate : {y : 0, opacity : 1}, 
                        initial : {y : 100, opacity : 0}
                    }}
                    className='functions'
                >
                    <h1>Fonction</h1>
                    <div>
                        <div>
                            <label htmlFor='function'>Poste occupé</label>
                            <input 
                                type = "text"
                                name = "function"
                                value = {user.function}
                                onChange = {handleChangeInput}
                            />
                        </div>
                        <div>
                            <label htmlFor='user_group'>Groupe utilisateur</label>

                            <div className='select'>
                                {
                                    users_group.map(el => (
                                        <motion.button
                                            className={user.user_group === el.name ? "active" : ""}
                                            key = {JSON.stringify(el)}
                                            onClick = {() => handleChangeInput({target : {name : "user_group", value : el.name}})}
                                            {...user.user_group !== el.name ? {
                                                whileHover : {scale : 1.05}, 
                                                whileTap : {scale : 0.95}
                                            } : {}}
                                        >
                                            <p>{el.name}</p>
                                        </motion.button>
                                    ))
                                } 
                            </div>
                        </div>
        
                    </div>
                </motion.div>


                <motion.div 
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    transition = {{duration : 0.3, delay : 0.2 * 2}}
                    variants = {{
                        exit : {y : 100, opacity : 0}, 
                        animate : {y : 0, opacity : 1}, 
                        initial : {y : 100, opacity : 0}
                    }}
                    className='connexion'
                >
                    <h1>Connexion</h1>
                    <div>
                        <div>
                            <label htmlFor='mail'>E-mail</label>
                            <input 
                                type = "text"
                                name = "mail"
                                value = {user.mail}
                                onChange = {handleChangeInput}
                            />
                        </div>

                        {
                            //PASSWORD
                            /*
                            <div>
                            <label htmlFor='pwd'>Mot de passe</label>
                            <input 
                                type = "password"
                                name = "pwd"
                                value = {user.pwd}
                                onChange = {handleChangeInput}
                            />
                            </div>
                            
                            */
                        }

        
                    </div>
                </motion.div>

                <motion.div 
                    exit = "exit"
                    animate = "animate"
                    initial = "initial"
                    transition = {{duration : 0.3, delay : 0.2 * 3}}
                    variants = {{
                        exit : {y : 100, opacity : 0}, 
                        animate : {y : 0, opacity : 1}, 
                        initial : {y : 100, opacity : 0}
                    }}
                    className='infos'
                >
                    <h1>Autres informations</h1>
                    <div>
                        <div>
                            <label htmlFor='phone_number'>Téléphone</label>
                            <input 
                                type = "text"
                                name = "phone_number"
                                value = {user.phone_number}
                                onChange = {(e) => handleChangeInput(e, /[a-zA-Z]/g)}
                            />
                        </div>    
                    </div>
                </motion.div>
            </div>

            <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                transition = {{duration : 0.3, delay : 0.2 * 4}}
                variants = {{
                    exit : {y : 100, opacity : 0}, 
                    animate : {y : 0, opacity : 1}, 
                    initial : {y : 100, opacity : 0}
                }}
                className='manage_access'
            >
                <h1>Gestion des accès</h1>
                
                <div>
                    {
                    
                    navigation({server : props.server}).slice(1).map((nav:any, index:number) => (
                        <div 
                            key = {`DetailUserNav-${nav.id}`}
                        >
                            <p>{nav.content}</p>
                                <div 
                                    className='submenu'
                                    onMouseEnter={() => { setManageSubList(index)}}
                                    onMouseLeave={() => { setManageSubList(null)}}
                                >
                                    <p>
                                        {
                                            user.access.map((el:any) => el.id_menu).includes(nav.id_menu) && access_type.find((acc:any) => user.access.find((el:any) => el.id_menu === nav.id_menu)) ? 
                                                access_type.find((acc: any) => {
                                                    const userAccess = user.access.find((el: any) => el.id_menu === nav.id_menu);
                                                    return userAccess && userAccess.mode === acc.name;
                                                })?.text || "Aucun accès"
                                            : 
                                                "Aucun accès"
                                        }
                                    </p>
                                    <Icons name = "chevron-up" {...icons_config}/>

                                    {
                                        manageSubList === index ? 
                                            <motion.div 
                                                exit = {{height : 0, opacity : 0., transition : {delay : 0.2}}}
                                                animate = {{height : "auto", opacity : 1}}
                                                initial = {{height : 0, opacity : 0.}}
                                                //transition = {{duration : 0.1}}
                                                className='submenu--list'
                                            >   
                                                {
                                                    access_type.filter(acc => acc.name !== (user.access.find((el:any) => el.id_menu === nav.id_menu) ? user.access.find((el:any) => el.id_menu === nav.id_menu).mode : "neant")).map((acc, position)=> (
                                                            <motion.div 
                                                                exit = {{scale : 0}}
                                                                animate = {{scale : 1, transition : {delay : 0.1}}}
                                                                initial = {{scale : 0}}
                                                                //whileHover = {{scale : 1.1}}
                                                                //whileTap = {{scale : 0.90}}
                                                                onClick = {() => handleChangeAccess(nav.id_menu, acc.name)}
                                                                key = {acc.id}
                                                            >

                                                                {acc.text}
                                                            </motion.div>
                              
                                                    ))
                                                }
                                            </motion.div>
                                        : 
                                            null
                                    }
                                </div>

                        </div>
                    ))}
                </div>
            </motion.div>

            <motion.div 
                exit = {{scale : 0, x : 500}}
                animate = {{scale : 1, x : 0}}
                initial = {{scale : 0, x : 500}}
                transition = {{duration : 0.3}}
                variants = {{
                    exit : {y : 100, opacity : 0}, 
                    animate : {y : 0, opacity : 1}, 
                    initial : {y : 100, opacity : 0}
                }}
                className='illustration'
            >
                <IllustrationUser />
            </motion.div>
        </div>
    )
}