/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch } from 'react-redux';
import { ServerProps } from '../../config/server.config';
import { setLoaderStatus, addRequestStatus, set_popup_warning } from '../../store/global/actions.global';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { icons_config } from '../../config/context.config';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { Table } from 'tc-minibox';
import { paymentDeadlines, type_payments } from './utils.suppliers';
import SupplierManager from './manager/Manager';


export type SuppliersManager = {
    add : boolean
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null,
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Suppliers(props:ServerProps) {
    // * VARIABLES
    const dispatch = useDispatch()
    

    //TO MANAGE COMPONENT
    const [manager, setManager] = useState<SuppliersManager>({
        add : false, 
        initialize : false,
        mode : "listing",
        selected_data : null, 
        update : initialize_update
    })
    const [suppliers, setSuppliers] = useState<DataType>([])

    //DATA 
    const tbody = suppliers.map(supp => {
            return {
                ...supp, 
                payment_choice : type_payments.find(tp => tp.name === supp.payment_choice)?.text, 
                payment_deadline : paymentDeadlines.find(pd => pd.name === supp.payment_deadline)?.text
            }
        })


    // * USE EFFECTS

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des fournisseurs en cours..."}))
            props.server.get('/suppliers/initialize')
            .then(res => {
                setManager(state => { return {...state, initialization : "resolve"}})
                
                setSuppliers(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [])

    // * FUNCTIONS 
    const handleDelete = (e:any) => {
        if(e.length > 0) {
            dispatch(setLoaderStatus({status : true}))
            const selected_data = e.map((sup:any) => sup._id)
            props.server.post('suppliers/delete', {ids_supplier : selected_data } )
            .then(res => {
                setSuppliers(state => state.filter((st:any) => !selected_data.includes(st._id)))
                dispatch(addRequestStatus(res.data))
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }
    const handleDoubleClick = (selected_data:any) => {
        const sd = suppliers.find(supp => supp._id === selected_data._id)


        setManager(state => {
            return{
                ...state, 
                add : true, 
                update : {
                    status : true, 
                    content : sd
                }
        }})
    }

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
    
                style : {
                    width : "calc(100% - 400px)",
                }
            },
            {
                info : {
                    text : "Type de paiement", 
                    name : "payment_choice",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : "Échéances paiement", 
                    name : "payment_deadline",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setSuppliers,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces fournisseurs ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }
        }, 
        handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }


    return (
        <React.Fragment>
            {

                manager.add ? 
                    <SupplierManager
                        update = {manager.update}
                        data = {suppliers}
                        setData = {setSuppliers}
                        server = {props.server}
                        handleAdd = {(val) => {
                            setSuppliers(state => {return[val, ...state]})
                        }}
                        handleClose = {() => setManager(state => {return{...state, add : false, update : initialize_update}})}
                    />
                : 
                    null
            }

            <BaseApp>

                <BaseContainer nameClass = "suppliers">
                    <Table {...table_config}/>
                </BaseContainer>
        
            </BaseApp>



        </React.Fragment>
    )
}