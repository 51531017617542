import { LoaderType, StatusRequestType, WarningType } from "../types.store";
import { ADD_STATUS_REQUEST, CLEAN_STATUS_REQUEST, MANAGE_LOADER, SET_GLOBAL, SET_NAV_WARNING} from "./reducer.global";


export const addRequestStatus = (message:StatusRequestType) => ({
    type : ADD_STATUS_REQUEST, 
    payload : message
})

export const clearRequestStatus = () => ({type : CLEAN_STATUS_REQUEST})

export const setLoaderStatus = (status:LoaderType) => ({
    type : MANAGE_LOADER,
    payload : status
})


export const close_popup_warning = () => ({type : "CLOSE_POPUP_WARNING"})

export const set_popup_warning = (warning:WarningType) => ({type : "SET_POPUP_WARNING", payload : warning})

export const set_nav_warning = (data:any) => ({type : SET_NAV_WARNING, payload : data})

export const set_global = (data:any) => ({type : SET_GLOBAL, payload : data})

