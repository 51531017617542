import React from 'react' ;
import {motion} from 'framer-motion'
import { Icons, StringUtils } from 'tc-minibox';
import SearchSelect from '../../../../../../tools/components/general/SearchSelect';
import { ServerProps } from '../../../../../../config/server.config';
import EditInvAffairs from '../popups/EditInvAffairs';
import DropDown from '../../../../../../tools/components/general/DropDown';
import { types_invoice } from '../../../suppliersinv.utils';

export interface UpdatingInvIdentificationProps extends ServerProps {
    selected_data : any,
    handleChangeInput : any,
    //setManager : any,
    handleSupposedSupplier? : any, 
    setData : React.Dispatch<React.SetStateAction<any[]>>,
}

interface UpdatingInvIdManager {
    edit_affairs : boolean,

}

interface UpdatingInvIdEvents {
    hover : string|null
}

export default function Identification(props:UpdatingInvIdentificationProps) {
    const [manager, setManager] = React.useState<UpdatingInvIdManager>({
        edit_affairs : false,
    })

    const [events, setEvents] = React.useState<UpdatingInvIdEvents>({
        hover : null
    })

    return (
        <React.Fragment>
            {
                manager.edit_affairs ? 
                    <EditInvAffairs 
                        server={props.server}
                        handleClose = {() => setManager(state => {return{...state, edit_affairs : false}})}
                        selected_data = {props.selected_data}
                        setData = {props.setData}
                        handleChangeInput={props.handleChangeInput}
                    />
                : 
                    null
            }

            {/* TYPE */}
            <div className='type'>
                <label htmlFor='type'>Type</label>
                <DropDown
                    reference = {"AMENDMENTS-TYPE"}
                    hover = {events.hover}
                    setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                    value = {props.selected_data.type}
                    setValue = {(e) => {
                        //props.handleChangeInput({target : {name : "type", value : e.name}})

                        props.setData((state:any) => {
                            // NEGATIVE IF TYPE CREDIT NOTE

                            return {
                                ...state, 
                                type : e.name, 
                                total_tva : e.name === "credit-note" ? Math.abs(state.total_tva) * -1 : Math.abs(state.total_tva), 
                                total_ttc : e.name === "credit-note" ? Math.abs(state.total_ttc) * -1 : Math.abs(state.total_ttc), 
                                id_affairs : state.id_affairs.map((aff:any) => {
                                    return {
                                        ...aff, 
                                        ht : e.name === "credit-note" ? Math.abs(aff.ht) * -1 : Math.abs(aff.ht),
                                        tva : e.name === "credit-note" ? Math.abs(aff.tva) * -1 : Math.abs(aff.tva),
                                    }
                                })
                            }
                        })
                    }}
                    list = {types_invoice}
                />
            </div>

            {/*INVOICE NUMBER */}
            <div className='doc_number'>
                <label htmlFor='doc_number'>Numéro de pièce comptable</label>
                <input 
                    type = "text"
                    name = "doc_number"
                    value = {props.selected_data.doc_number}
                    onChange={props.handleChangeInput}
                />
            </div>

            {/*INVOICE NUMBER */}
            <div className='inv_number'>
                <label htmlFor='inv_number'>Numéro de facture</label>
                <input 
                    type = "text"
                    name = "inv_number"
                    value = {props.selected_data.inv_number}
                    onChange={props.handleChangeInput}
                />
                {StringUtils.isUndefinedValue(props.selected_data.inv_number) || props.selected_data.inv_number === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }
            </div>


            {/*ID_SUPPLIER */}
            <div className='id_supplier'>
                <label htmlFor='id_supplier'>Fournisseur</label>
                <SearchSelect
                    {...{
                        adding : true, 
                        handleAdd : (e:string) => {
                            props.handleSupposedSupplier(e)
                        },
                        text_no_data : "Aucune affaire trouvée", 
                        handleClick : (data) => {
                            props.handleChangeInput({target : {name : "id_supplier", value : data.id}})
                            props.handleChangeInput({target : {name : "name_supplier", value : data.text}})
                        },
                        handleDelete : () => props.handleChangeInput({target : {name : "id_supplier", value : null}}),
                        no_data_text : "Aucune affaire trouvée", 
                        server : {
                            axios : props.server,
                            route : "/invoicing/search-suppliers"
                        },
                        value : props.selected_data.id_supplier,
                    }}
                />
                {StringUtils.isUndefinedValue(props.selected_data.id_supplier) || props.selected_data.id_supplier === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }

            </div>
            {
                props.handleSupposedSupplier && StringUtils.isUndefinedValue(props.selected_data.id_supplier) ? 
                    <p className = "id_supplier--supposed-supplier">
                        <span>Fournisseur supposé :</span>
                        <span onClick = {() => props.handleSupposedSupplier(props.selected_data.supposed_supplier)}>{`${props.selected_data.supposed_supplier}`}</span>
                    </p>
                : 
                    null
            }

            <div className='more_affairs'>
                <motion.button
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                    onClick = {() => setManager(state => {return{...state, edit_affairs : true}})}
                >
                    Édition de la répartition des affaires
                </motion.button>
            </div>
            
        </React.Fragment>
    )
}