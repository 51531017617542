import React, { useState } from "react";
//import { useLocation } from "react-router-dom";
import AllLines from './grid/AllLines';
import { ServerProps } from '../../../../config/server.config';
import CategorizerManager from '../../../../tools/components/general/Categorizer';
import DetailedLines from "./grid/DetailedLines";
import Amendments from "./grid/amendments/Amendments";

export interface GridProps extends ServerProps{
    rerender_detail : () => void,
    id_affair : number|string,
    selected_affair : any
}

export type GridEvents = {
    fullscreen : "MODAL" | null,
}

export default function Grid(props:GridProps) {
    //const location = useLocation()
    //const searchParams = new URLSearchParams(location.search);
    //const id_amendment_params: string | null = searchParams.get('id_amendment');

    const [events, setEvents] = useState<GridEvents>({
        fullscreen : null, 
    })


    const modals_config = {
        server : props.server, 
        id_affair : props.id_affair, 
        events : events, 
        key_resize : "CATEGORIZER", 
        selected_affair : props.selected_affair
    }

    const config = [
        {
            id : "MOD-00", 
            name : "Toutes les lignes", 
            svg : "listing-tree", 
            component : <AllLines {...modals_config} />
        }, 
        {
            id : "MOD-01", 
            name : "Devis de base", 
            svg : "file-invoice-dollar", 
            component : <DetailedLines {...modals_config} id_amendment={null}/>
        }, 
        {
            id : "MOD-02", 
            name : "Avenants", 
            svg : "file-signature", 
            component : <Amendments {...modals_config} rerender_detail = {props.rerender_detail}/>
            /*modal : <Amendments 
                        api = {props.api} 
                        id_affair = {props.id_affair} 
                        affair_info = {props.affair_info} 
                        setSelectedAffairManager = {props.setManager}
                        thead = {affair_model}
                    />*/
            
        }, 
        {
            id : "MOD-03", 
            name : "Documents", 
            svg : "folder-tree", 
            component : <div>À paramétrer</div>
            /*modal : <div className='docpopup'><AffairDMS api = {props.api} manager = {{id_affair : props.id_affair}} forbidden_buttons = {['go-to']}/></div>*/
        }, 
    ]

    /*const [manager, setManager] = useState({
        initialization : "pending", 
        selected_modal : !StringUtils.isUndefinedValue(id_amendment_params) ? config[2].id : config[0].id
    })

    const handleChangeModals = (id:string) => setManager(state => {return{...state, selected_modal : id}})
    const handleEvents = (event:string, val:string|null) => setEvents(state => {return{...state, [event] : val}})
    */

    const handleEvents = (event:string, val:any) => setEvents(state => {return{...state, [event] : val}})
    return (
        <React.Fragment>
            <CategorizerManager 
                resize = {{
                    status : true, 
                    key : "CATEGORIZER", 
                    value : events.fullscreen, 
                    setValue : (val) => handleEvents("fullscreen", val)
                }}
                config = {config} 
                rerender = {false}
            />
        </React.Fragment>
    )
}