import { ServerProps } from "../../../config/server.config";
import {motion} from 'framer-motion'
import UpdatePwd from "./layouts/UpdatePwd";



export interface MyAccountProps extends ServerProps{}

export type ModuleMyAcc = {
    id : string, 
    name : string, 
    component : JSX.Element
}

export default function MyAccount(props:MyAccountProps) {
    const modules:ModuleMyAcc[] =[
        {
            id : "MOD-MY-ACC-01", 
            name : "Modifier mon mot de passe", 
            component : <UpdatePwd server={props.server} />
        }
    ]
    return (
        <div className="my-account">
            {
                modules.map((el:ModuleMyAcc, index:number) => (
                    <motion.div
                        exit={{y : 100, opacity : 0}}
                        initial={{y : 100, opacity : 0}}
                        animate={{y : 0, opacity : 1, transition : {type : "linear",  delay : 0.2 + (index * 0.2)}}}
                    >
                        <h1>{el.name}</h1>
                        {el.component}
                    </motion.div>
                ))
            }
        </div>
    )

}