import { ActionType, GlobalType } from "../types.store"


const initialState:GlobalType = { 
    nav_warning : {
        status : false, 
        action : null
    }, 
    statusRequest : {}, 
    loader : {
        status : false
    }, 
    warning : {status : false}
}

export const ADD_STATUS_REQUEST = "ADD_STATUS_REQUEST"
export const CLEAN_STATUS_REQUEST = "CLEAN_STATUS_REQUEST"
export const MANAGE_LOADER = "MANAGE_LOADER"
export const CLOSE_POPUP_WARNING = "CLOSE_POPUP_WARNING"
export const SET_POPUP_WARNING = "SET_POPUP_WARNING"
export const SET_NAV_WARNING = "SET_NAV_WARNING"
export const SET_GLOBAL = "SET_GLOBAL"


export default function global_reducer(state = initialState, action:ActionType) {
    switch(action.type) {
        case MANAGE_LOADER : 
            return {
                ...state, 
                loader : action.payload
            }

        case CLEAN_STATUS_REQUEST : 
            return {
                ...state, 
                statusRequest : {}
            }

        case ADD_STATUS_REQUEST : 
            return {
                ...state, 
                statusRequest : action.payload
            }

        case CLOSE_POPUP_WARNING :
            return {
                ...state, 
                warning : initialState.warning
            }

        case SET_POPUP_WARNING :
            return {
                ...state, 
                warning : action.payload
            }
        
        case SET_NAV_WARNING :
            return {
                ...state,
                nav_warning : action.payload
            }

        case SET_GLOBAL : {
            return {
                ...state,
                ...action.payload
            }
        }
        
        default : 
            return state
    }
}