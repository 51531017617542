import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../../../store/global/actions.global"
import { ServerProps } from "../../../../config/server.config"
import { account_user } from "../../../../store/account/selector.account"
import { logout } from "../../../../store/account/actions.account"
import MainButton from "../../../../tools/components/general/MainButton"


export interface UpdatePwdProps extends ServerProps{

}

export type UpdatePwdForm = {
    old_pwd : string, 
    new_pwd : string, 
    confirm_pwd : string
}

export default function UpdatePwd(props:UpdatePwdProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)

    // * STATES
    const [form, setForm] = useState<UpdatePwdForm>({
        old_pwd : "", 
        new_pwd : "", 
        confirm_pwd : ""
    })

    // * FUNCTIONS
    const handleSubmit = () => {
        if(form.old_pwd === "" || form.new_pwd === "" || form.confirm_pwd === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez remplir tous les champs"
            }))
        }

        if(form.new_pwd !== form.confirm_pwd) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Les mots de passe ne correspondent pas"
            }))
        }
        dispatch(setLoaderStatus({status : true, message : "Modification en cours"}))
        props.server.post('/access/update-user-pwd', {id_user : user_account.infos._id, new_pwd : form.new_pwd})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            dispatch(logout())
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })

        

    }


    return (
        <div className="my-account--update-pwd">
            <div>
                <label htmlFor="old-pwd">Ancien mot de passe</label>
                <input 
                    type="password" 
                    id="old-pwd" 
                    name="old-pwd" 
                    value={form.old_pwd}
                    onChange = {(e) => setForm(state => {return {...state, old_pwd : e.target.value}})}
                />
            </div>
            <div>
                <label htmlFor="new-pwd">Nouveau mot de passe</label>
                <input 
                    type="password" 
                    id="new-pwd" 
                    name="new-pwd" 
                    value={form.new_pwd}
                    onChange = {(e) => setForm(state => {return {...state, new_pwd : e.target.value}})}
                />
            </div>
            <div>
                <label htmlFor="confirm-pwd">Confirmer le nouveau mot de passe</label>
                <input 
                    type="password" 
                    id="confirm-pwd" 
                    name="confirm-pwd" 
                    value={form.confirm_pwd}
                    onChange = {(e) => setForm(state => {return {...state, confirm_pwd : e.target.value}})}
                />
            </div>
            
            <MainButton
                handleClick = {() => {
                    return dispatch(set_popup_warning({
                        status : true, 
                        text : "Voulez-vous vraiment modifier votre mot de passe ? À la fin de l'opération, vous serez déconnecté.", 
                        handleYes : () => handleSubmit()
                    }))
                }}
            >
                Modifier
            </MainButton>
            


        </div>
    )
}