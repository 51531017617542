import React from 'react' ; 

export interface BaseAppProps {
    children? : React.ReactNode
}

export default function BaseApp(props:BaseAppProps) {
    return (
        <div className='app'>{props.children as JSX.Element}</div>
    )
}