/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react' ; 
import { motion } from 'framer-motion';
import { WarningType } from '../../../store/types.store';
import BasePopup from './BasePopup';


export interface WarningProps extends WarningType{
    handleClose : () => void
}


export default function PopupWarning(props:WarningProps) {
    const nameClass = props.nameClass ? props.nameClass : ""
    const text = props.text ? props.text : "No text in arguments"
    const handleYes = props.handleYes ? props.handleYes : () => console.log('No function handleYes() in arguments')
    const handleNo = props.handleNo ? props.handleNo : () => console.log('No function handleYes() in arguments')
    /*
        Needed props : 
            - text : 
            - handleYes ; 
            - handleNo : 
    */
        useEffect(() => {
            // Retire le focus de tous les éléments focusables
            const removeFocusFromAllElements = () => {
                if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
                }
            };
        
            // Appel de la fonction pour retirer le focus au moment du montage du composant
            removeFocusFromAllElements();
        
            // Nettoyage de l'effet lors du démontage du composant (si nécessaire)
            return () => {
                // Logique de nettoyage (si nécessaire)
            };
            }, []);


    useEffect(() => {
        const handleKeyPress = (event:KeyboardEvent):void => {
            event.preventDefault()
            if (event.key === "Enter") {
                handleYes();
                props.handleClose(); 

            }
        };
    
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <BasePopup handleClose = {() => props.handleClose()} nameClass={`popup-warning ${nameClass}`}>
            <p>{text}</p>
            <div>
                <motion.div
                    onClick = {() => {
                        props.handleClose()
                        handleYes()
                    }}
                >
                    Oui
                </motion.div>
                <motion.div
                    onClick={() => {
                        props.handleClose()
                        handleNo()
                    }}
                >
                    Non
                </motion.div>
            </div>
            

        </BasePopup>
    )
}