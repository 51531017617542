import React from 'react';
import './styles/style.css';
import './styles/normalize.css';
import Auth from './auth/Auth';
import BasicTools from './tools/components/basictools/BasicTools';
import { useSelector } from 'react-redux';
import { account_user } from './store/account/selector.account';
import Main from './app/Main';

function App() {
  const account = useSelector(account_user)

  return (
    <React.Fragment>
      <BasicTools />
      {
        account.status && account.token !== null ? 
          <Main />
        : 
          <Auth />
      }

    </React.Fragment>
  );
}

export default App;
