/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' ; 
import {AnimatePresence} from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addRequestStatus, setLoaderStatus } from '../../store/global/actions.global';
import { ServerProps } from '../../config/server.config';
import { StringUtils, URLUtils } from 'tc-minibox';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import MainButton from '../../tools/components/general/MainButton';
import {ReactComponent as PrintingInvoice} from '../../assets/svg/illustrations/printing_invoice.svg'
import { DataType} from 'tc-minibox/dist/types/table/index.table';
import ListingInv from './layouts/Listing';
import StepsText from '../../tools/components/general/StepsText';
import PaymentDetail from './layouts/PaymentDetail';

interface PaymentsProps extends ServerProps {

}

export interface PaymentsManager {
    initialize : boolean, 
    mode : "listing", 
    deposit : boolean, 
    selected_data : number | null
}

export default function Payments(props:PaymentsProps) {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params = searchParams.get('id_affair');

    const [manager, setManager] = useState<PaymentsManager>({
        initialize : false, 
        mode : "listing", 
        deposit : false,
        selected_data : null
    })

    const [invoices, setInvoices] = useState<DataType>([])

    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})

    useEffect(() => {
        if(!manager.initialize) {
            setInvoices([])
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            props.server.get('/payments/listing')
            .then(res => {
                setManager(state => { return {...state, initialize : true}})
                const data = res.data.data.map((dt:any) => {
                    return {
                        ...dt, 
                        dates : Object.fromEntries(Object.entries(dt.dates).map(([key, value]) => [key, new Date(value as string)]))
                    }
                })
                setInvoices(data)
                if(id_affair_params !== null) {
                    const parsedValue = parseInt(id_affair_params);
                    
                    // Check if parsedValue is not undefined
                    if(!StringUtils.isUndefinedValue(parsedValue)) {
                        setManager(state => { return {...state, selected_data: parsedValue} });
                
                        // Assuming removeURLParameter expects a string, don't pass null
                        URLUtils.removeURLParameter('id_affair');
                    }
                }
                
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                handleChangeManager("initialization", "resolve")
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])


    return (
        <React.Fragment>
                    {
                        manager.selected_data !== null ? 

                                <PaymentDetail
                                    setManager={setManager}
                                    server={props.server}
                                    handleClose = {() => setManager(state => {return {...state, selected_data : null}})}
                                    data = {manager.selected_data}
                                    setData = {(data:any) => setInvoices(state => {
                                        return state.map((el:any) => {
                                            if((el._id as any) === (manager.selected_data as any)._id) {
                                                return {
                                                    ...el, 
                                                    ...data
                                                }
                                            }
                                            return el
                                        })
                                    })}

                                />

                        : 
                            null
                    }


            <BaseApp>
                <BaseContainer nameClass="payments">
                    <StepsText
                        title = "Règlements"
                        position = {{
                            x : 60, 
                            y : 0
                        }}
                    />
                    {
                        invoices.length > 0 ? 
                                <ListingInv 
                                    server={props.server}
                                    data = {invoices}
                                    setData={setInvoices}
                                    handleAdd = {() => handleChangeManager('deposit', true)}
                                    setManager={setManager}
                                />
                            : 
                                <div className='no-data'>
                                    <PrintingInvoice />
                                    <MainButton handleClick = {() => handleChangeManager('deposit', true)}>
                                        Effectuer un dépôt
                                    </MainButton>
                                </div>
                    }

                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}