import React from 'react' ; 
import { motion, AnimatePresence} from 'framer-motion' ;
import {Icons} from 'tc-minibox'
import { navigation } from '../../../nav/data.nav';
import { ServerProps } from '../../../../config/server.config';


export interface ListingUserProps extends ServerProps {
    data : any[], 
    manager : any, 
    setManager : any, 
    handleAdd : () => void, 
    handleDelete : () => void, 
    accessUsers : any[]
}

export default function ListingUser(props:ListingUserProps) {
    const handleSelect = (id:any) => {
        if(!props.manager.selected_data.includes(id)) {
            
            return props.setManager((state:any) => {
                return {
                    ...state, 
                    selected_data : [...state.selected_data, id]
                }
            })
        } 

        return props.setManager((state:any) => {
            return {
                ...state, 
                selected_data : state.selected_data.filter((el:any) => el !== id)
            }
        })
    }

    const handleSelectAll = () => {
        if(props.manager.selected_data.length === props.accessUsers.length) {
            return props.setManager((state:any) => {
                return {
                    ...state, 
                    selected_data : []
                }
            })
        }

        return props.setManager((state:any) => {
            return {
                ...state, 
                selected_data : props.accessUsers.map(el => el._id)
            }
        })
    }

    const handleDoubleClick = (id:any) => {
        props.setManager((state:any) => {
            return {
                ...state, 
                doubleClicked_data : id
            }
        })
    }

    return (
        <motion.div 
            animate = {{  y : 0}}
            initial = {{  y : 500}}
            exit = {{ y : 500}}
            transition = {{duration : 0.3}}
            className='listing'
        >
            <div className='toolbar'>
                <motion.button
                    className='add'
                    whileHover={{scale : 1.03}}
                    whileTap={{scale : 0.97}}
                    onClick = {props.handleAdd}
                >
                    Nouvel utilisateur
                </motion.button>


                {
                    props.manager.selected_data.length > 0 ? 
                        <motion.button
                        exit = {{scale : 0}}
                        initial = {{scale : 0}}
                        animate = {{scale : 1}}
                        transition = {{duration : 0.1}}
                        className='delete'
                        whileHover={{scale : 1.03}}
                        whileTap={{scale : 0.97}}
                        onClick={props.handleDelete}
                        //onClick = {() => dispatch(manage_window("new_user"))}
                        >
                            Supprimer
                        </motion.button>
                    : 
                        null
                }



            </div>
            <div className='thead'>
                <motion.div
                    className={`${props.manager.selected_data.length === props.accessUsers.length ? "user_selected" : "" } `}
                    whileHover={{scale : 1.03}}
                    whileTap={{scale : 0.9}}
                    onClick = {handleSelectAll}
                >
                    <Icons name = "check" mode = "duotone" color = "white" />

                </motion.div>
                <div>Nom</div>
                <div>Prénom</div>
                <div>Groupe</div>
                <div>Fonction</div>
                <div>Accès</div>
            </div>
            <div className='tbody'>
                <div>
                {
                    props.data.length > 0 ? 
                        props.data.map(el => (
                            <div 
                                key = {JSON.stringify(el)}
                                onDoubleClick = {() => handleDoubleClick(el._id)}
                            >
                                <motion.div
                                    className={`${props.manager.selected_data.includes(el._id) ? "user_selected" : "" } `}
                                    whileHover={{scale : 1.03}}
                                    whileTap={{scale : 0.9}}
                                    onClick = {() => handleSelect(el._id)}
                                >
                                    <Icons name = "check" mode = "duotone" color = "whitesmoke" />
                                </motion.div>
                                <div><p>{el.lastname}</p></div>
                                <div><p>{el.firstname}</p></div>
                                <div><p>{el.user_group}</p></div>
                                <div><p>{el.function}</p></div>
                                <div className='detail_access'>
                                    <p>{`${el.access.filter((acc:any) => acc.droit !== "neant").length} accès`}</p>
                                    <div>
                                        {navigation({server : props.server}).slice(1).map(nav => (
                                            <div key = {`Navigation-${nav.id}`}>
                                                <motion.div 
                                                    className={`circle ${el.access.filter((acc:any) => acc.mode !== "neant").find((sub:any) => sub.id_menu === nav.id_menu) ? "active" : ""}`}
                                                
                                                ><div></div></motion.div>
                                                <div className='navtitle'>
                                                    <p>{nav.content}</p>
                                                    <p>{el.access.filter((acc:any) => acc.mode !== "neant").find((sub:any) => sub.id_menu === nav.id_menu) ? el.access.filter((acc:any) => acc.mode !== "neant").find((sub:any) => sub.id_menu === nav.id_menu).mode : ""}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    : 
                        <div className='noData'>Aucune donnée</div>

                }
                </div>
            </div>

        </motion.div>
    )
}