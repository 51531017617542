import React, {useState, useEffect} from 'react' ; 
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { DateTimeUtils, Icons} from 'tc-minibox';
import { account_user } from '../../store/account/selector.account';
import { MainManager } from '../Main';
import { addRequestStatus } from '../../store/global/actions.global';
import { logout } from '../../store/account/actions.account';
import { icons_config } from '../../config/context.config';

export interface InfoAccountProps {
    setManager : React.Dispatch<React.SetStateAction<MainManager>>

}

export default function InfoAccount(props:InfoAccountProps) {
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    const [currentDateTime, setCurrentDateTime] = useState(DateTimeUtils.getCurrentDateTime())

    const handleLogout = () => {
        dispatch(addRequestStatus({
            status : true, 
            message : "Vous êtes déconnecté avec succès."
        }))
        dispatch(logout())
    }

    //CHANGE DATE TIME
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(DateTimeUtils.getCurrentDateTime());
          }, 1000); // Mettre à jour toutes les secondes
      
          return () => {
            clearInterval(intervalId);
          };
    }, [])
    
    return (
        <motion.div 
            animate ={{translateY : ['-100%', '0%'], transition : { duration: 0.3, delay : 1.5 }}}
            className='info-account'
        >
            <div className='datetime'>
                <p>{currentDateTime.date}</p>
                <p>{currentDateTime.time}</p>

            </div>
            <div className='name'>{`${user_account.infos.firstname} ${user_account.infos.lastname}`}</div>
            <div className='buttons'>

                <motion.div onClick = {() => props.setManager(state => {return{...state, settings : true}})}><Icons name = "gear" {...icons_config}/></motion.div>
                <motion.div onClick = {handleLogout}><Icons name = "right-from-bracket" mode = "duotone" color = "red"/></motion.div>

            </div>
        </motion.div>
    )
}

//                <motion.div {...button_action_animation}><Icons name = "bell" {...icons_config}/></motion.div>