
import React from 'react' ; 
import {motion} from 'framer-motion' ; 
import {ReactComponent as ArrowRight} from '../../../assets/svg/icons/arrow_right.svg' ; 

export interface StepsTextProps {
    title : string,
    slice? : number, 
    steps? : Array<{
        id : string,
        name : string
    }>,
    position : {
        x : number,
        y : number
    }
}


export default function StepsText(props:StepsTextProps) {
    return (
        <motion.div 
            exit = {{x : -100, opacity : 0}}
            animate = {{x : 0, opacity : 1 ,transition : {delay : 0.6}}}
            initial = {{opacity : 0, x : -100}}
            style = {{left : props.position.x, top: props.position.y}}
            className="stepsText"
        >
            <h1>{props.title}</h1>
            {
                props.steps && props.slice ? 
                    <div 
                    className="stepsText__subCategories">
                        {
                            props.steps.slice(props.slice).map(step => (
                                <motion.div 
                                    key = {step.id}
                                    exit = {{
                                        x : -100, 
                                        opacity : 0
                                        
                                    }}
                                    animate = {{
                                        x : 0, 
                                        opacity : 1 ,
                                        transition : {
                                            delay : 0.3
                                        }
                                    }}
                                    initial = {{
                                        opacity : 0, 
                                        x : -100, 
                
                                    }}
                                >
                                    <ArrowRight />
                                    <p>{step.name}</p>
                                </motion.div>
                            ))
                        }
                    </div>

                : 
                    null
            }

        </motion.div>
    )

}