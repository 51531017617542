//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

// Définir la variable hostname
export const hostname = window.location.hostname === 'myogec.com' ? 'https://myogec.com' : 'http://localhost:4000';

// Configuration du socket
export const socket_server = io(`${hostname}`, { transports : ['polling'] });

// Fonction pour obtenir l'URL du serveur
export const server_host = (route = "") => { return `${hostname}/api/${route}` }

