
export const generate_totals = (total_ht:number, tva_rate=0.085) => {
    const str_ht = `${total_ht}`
    const ht = parseFloat(str_ht)
    const tva = ht * tva_rate
    const ttc = ht + tva
    return {
        total_ht : parseFloat(ht.toFixed(2)),
        total_tva : parseFloat(tva.toFixed(2)), 
        total_ttc : parseFloat(ttc.toFixed(2))
    }
}


export const analytic_codes = [
    {
        id : 1, 
        title : "Travaux", 
        name : "travaux", 
        seq : 438
    }, 
    {
        id : 2, 
        title : "Entretiens", 
        name : "entretiens", 
        seq : 32
    }, 
    {
        id : 3, 
        title : "S.A.V", 
        name : "sav", 
        seq : 590
    }, 
]