import { Icons, StringUtils } from "tc-minibox";
import {motion} from 'framer-motion' ; 
import React from "react";
import { icons_config } from "../../config/context.config";

export interface ListingCategoryProps {
    data : any, 
    manager : {
        category : string | null, 
        subCategory : string | null, 
    }, 
    setManager : React.Dispatch<React.SetStateAction<any>>
}

export default function ListingCategory(props:ListingCategoryProps) {
    // * CATEGORIES
    const raw_categories = props.data.map((el:any) => el.category)
    const categories = Array.from(new Set(raw_categories))

    // * SUBCATEGORIES
    const raw_subCategories = props.manager.category !== null ? 
                                    props.data.filter((el:any) => el.category === props.manager.category).map((el:any) => el.sub_category).filter((el:any) => el !== "")
                                :
                                    null
    const subCategories = raw_subCategories !== null ? Array.from(new Set(raw_subCategories)) : null

    // * VARIABLES
    const tbody:any = props.manager.category === null ? categories : subCategories


    // * FUNCTIONS
    const handleChoose = (val:string) => {
        if(props.manager.category === null) {
            return props.setManager({
                ...props.manager, 
                category : val
            })
        }
        return props.setManager({
            ...props.manager, 
            subCategory : val
        })
    }

    const handleUnselect = (val:'category'|'subCategory') => {
        if(val === 'category') {
            return props.setManager({
                ...props.manager, 
                category : null, 
                subCategory : null
            })
        }

        return props.setManager({
            ...props.manager, 
            subCategory : null, 
        })
    }


    return (
        <React.Fragment>
            {
                props.manager.category !== null ? 
                    <motion.div 
                        initial={{ x : 100, opacity : 0 }}
                        animate={{ x : 0, opacity : 1 }}
                        exit={{ x : 100, opacity : 0 }}
                        className = "listing-category__choosecat"
                    >
                        <motion.div 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="close"
                            onClick = {() => handleUnselect('category')}
                        >
                            <Icons name = "xmark" {...icons_config} />
                        </motion.div>
                        <p>{props.manager.category}</p>
                    </motion.div>
                : 
                    null
            }

            {
                props.manager.subCategory !== null ? 
                    <motion.div 
                        className = "listing-category__choosecat"
                        initial={{ scale : 0 }}
                        animate={{ scale : 1 }}
                        exit={{ scale : 0 }}
                    >
                        <motion.div 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className="close"
                            onClick = {() => handleUnselect('subCategory')}
                            
                        >
                            <Icons name = "xmark" {...icons_config} />
                        </motion.div>
                        <p>{props.manager.subCategory}</p>
                    </motion.div>
                : 
                    null
            }

            {
                (StringUtils.isUndefinedValue(props.manager.subCategory) || tbody.length === 0)  ?
                    <div className = "listing-category">
                        <Icons name ="listing-tree" mode = "duotone" color = "white" />
                        <p>{props.manager.category !== null ? "Sous-catégorie" : "Catégorie"}</p>
                        <div className="listing-category__container">
                            <div>
                            {
                                
                                tbody.map((el:any) => (
                                    <div className = "listing-category__container--item">
                                        <motion.p
                                            whileHover={{ color : "#fbd68b" }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick = {() => handleChoose(el)}
                                        >
                                            {el}
                                        </motion.p>
                                    </div>
                                ))
                            }
                            </div>
                        </div>

                    </div>
                : 
                    null
            }

        </React.Fragment>
    )
}