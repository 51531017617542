import { useState } from "react"
import BaseApp from "../../tools/components/general/BaseApp"
import BaseContainer from "../../tools/components/general/BaseContainer"
import ImportModule, { ImportProps } from "../../tools/components/general/ImportModule"
import { ServerProps } from "../../config/server.config"
import { account_user } from "../../store/account/selector.account"
import { useDispatch, useSelector } from "react-redux"
import { addRequestStatus, setLoaderStatus } from "../../store/global/actions.global"
import { DataType } from "tc-minibox/dist/types/table/index.table"
import Processing, { ProcessingProps } from "./layouts/Processing"
import Completion, { CompletionProps } from "./layouts/Completion"
import StepsText from "../../tools/components/general/StepsText"


interface NewAffairProps extends ServerProps {

}

export type NewAffairManager = {
    mode : "import" | "processing" | "completion" | "load", 
    slice : number
}

export default function NewAffair(props:NewAffairProps) {
    // * REDUX
    const account = useSelector(account_user)
    const dispatch = useDispatch()

    // * STATES
    const [manager, setManager] = useState<NewAffairManager>({ mode : "import", slice : 1})
    const [generalities, setGeneralities] = useState<any>({})
    const [line_devis, setLineDevis] = useState<DataType>([])

    const handleClearStates = () => {
        setManager({mode : "import", slice : 1})
        setGeneralities({})
        setLineDevis([])
    }

    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        subtitle : "Importez votre fichier Excel",
        nameClass : 'new-affair--import', 
        handleSubmit : (file:File) => {
            if(file) {
                const mimetype_accepted = [
                    'text/csv'
                ]

                if(!mimetype_accepted.includes(file.type)) {
                    return dispatch(addRequestStatus({
                        status : false, 
                        message : "Le fichier importé n'est pas un fichier CSV"
                    }))
                }
                
                dispatch(setLoaderStatus({status : true}))
                const container = new FormData()
                
                container.append("id_user", account.infos._id)
                container.append("file", file)
                props.server.post("/affairs/create", container, { headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    setManager({mode : "processing", slice : 2})
                    setGeneralities(res.data.affaire)
                    setLineDevis(res.data.l_devis)
                    dispatch(addRequestStatus({
                        status : true, 
                        message : "Enregistrement dans la base de données effectué"
                    }))

                    return dispatch(setLoaderStatus({status : false}))
                })
                .catch(err => {
                    dispatch(setLoaderStatus({status : false}))
                    return dispatch(addRequestStatus(err.response.data))
                })

            }
        }
    }



    // ! REVOIR GENERALITIES IL N'Y A QUE LE COSTESTIMATE_D

    const processing_config:ProcessingProps = {
        id_affair : generalities._id,
        handleClearStates : handleClearStates,
        server : props.server, 
        setManager : setManager, 
        data : line_devis, 
        setData : setLineDevis
    }


    const completion_props:CompletionProps = {
        server : props.server, 
        data : generalities, 
        setData : setGeneralities,
        setManager : setManager,
    }



    return (
        <BaseApp>
            <BaseContainer nameClass = "new-affair" key = {manager.mode} >
                <StepsText 
                    {...{
                        title : "Nouvelle affaire",
                        slice : manager.slice, 
                        steps : [
                            {
                                id : "ST - 01",
                                name : "Importation du fichier"
                            }
                        ],
                        position : {
                            x : 60,
                            y : 0
                        }
                    }}
                />
                {
                    manager.mode === "completion" ? 
                        <Completion {...completion_props} />
                    :
                    manager.mode === "processing" ? 
                        <Processing {...processing_config} />
                    : 
                        <ImportModule {...import_config} />
                }

            </BaseContainer>
        </BaseApp>
    )
}