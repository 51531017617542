import { useState } from "react" ; 
import { AnimatePresence, motion } from "framer-motion";
import { contacts_types } from "../../utils.suppliers"
import { Icons } from "tc-minibox";
import DropDown from "../../../../tools/components/general/DropDown";
import {ReactComponent as NoData} from '../../../../assets/svg/illustrations/no_data.svg';


export interface SupplierContactProps {
    form : any, 
    setForm : React.Dispatch<React.SetStateAction<any>>
}

type SupplierContactEvents = {
    hover : string | null
}

export default function Contact(props:SupplierContactProps) {
    const [events, setEvents] = useState<SupplierContactEvents>({
        hover : null
    })

    const [insert, setInsert] = useState({
        label : "",
        type : contacts_types[0].name, 
        value : ""
    })

    const handleAddContact = () => {
        const new_contact = {
            label : insert.label, 
            type : insert.type, 
            value : insert.value
        }

        props.setForm((state:any) => {
            return {
                ...state, 
                contact : [...state.contact, new_contact]
            }
        })

        setInsert({
            label : "",
            type : contacts_types[0].name, 
            value : ""
        })
    }

    const handleKeyDown = (e:React.KeyboardEvent) => {
        if(e.key === 'Enter') {
            handleAddContact()
        }
    }

    const tbody = props.form.contact.map((el:any, index:number) => {
        return {
            ...el, 
            type : contacts_types.find(ct => ct.name === el.type)?.text
        }
    })


    const regex_contact = contacts_types.find(ct => ct.name === insert.type)?.regex;

    return (
        <motion.div className="contact">
        <label htmlFor='contact'>Contact</label>
        <div className='insert'>

                {insert.label !== "" && insert.value.match(regex_contact instanceof RegExp ? regex_contact : "") ?
                    <motion.div 
                        className='add_button' 
                        exit = {{opacity : 0}}
                        animate = {{opacity : 1, transition : {type : "linear", duration : 0.15}}}
                        initial = {{opacity : 0}}
                        onClick = {handleAddContact}
                    >
                        <Icons name = "plus" mode = "duotone" color = "white" />
                    </motion.div>
                : 
                    null
                }

            <input 
                type = "text"
                name = "label"
                id = "label"
                value = {insert.label}
                placeholder = "Ex : M. Dupont"
                onChange = {(e) => setInsert(state => {return{...state, label : e.target.value}})}
            />

            <div id = "type">
                <DropDown
                    reference={"TYPE_OF_CONTACT_HOVER"}
                    hover={events.hover}
                    setHover={(e) => setEvents((state) => { return { ...state, hover: e } })}
                    value={insert.type}
                    setValue={(e) => setInsert((state) => { return { ...state, type: e.name } })}
                    list={contacts_types.map((ct) => { return { ...ct, id: ct.id, name: ct.name, text: ct.text } })}
                />
            </div>
        
            <input 
                id = "value"
                type = "text"
                name = "value"
                onKeyDown={handleKeyDown}
                value = {insert.value}
                placeholder = {contacts_types.find(ct => ct.name === insert.type)?.placeholder}
                onChange = {(e) => setInsert(state => {return{...state, value : e.target.value}})}
            />


        </div>
        <div className='thead'>
            <div id='label'>Nom</div>
            <div id='type'>Type</div>
            <div id='value'>Valeur</div>
        </div>

        <div className = 'tbody'>
            {
                props.form.contact.length > 0 ?
                    <div>

                    {
                        tbody.map((el:any, index:number) => (
                            <motion.div 
                                exit = {{opacity : 0, y : 20}}
                                animate = {{opacity : 1, y : 0}}
                                initial = {{opacity : 0, y : 20}}
                                key = {`CONTACT${index}`} 
                                className = 'contact_line'
                            >
                                <div id='label'>{el.label}</div>
                                <div id='type'>{el.type}</div>
                                <div id='value'>{el.value}</div>
                                <div className='delete_button' onClick = {() => props.setForm((state:any) => {return{...state, contact : state.contact.filter((el:any, i:number) => i !== index)}})}>
                                    <Icons name = "xmark" mode = "duotone" color = "white" />
                                </div>
                            </motion.div>
                        ))
                    }

                    </div>

                : 
                    <div className='nodata'>
                        <NoData />
                        <div>Aucun contact enregistré</div>
                    </div>
            }

        </div>

    </motion.div>
    )
}