import React from 'react' ; 
import { DatePicker, Icons, StringUtils } from 'tc-minibox';

export interface DatesUpdatingInvProps {
    selected_data : any ;
    selected_data_toMap : any ;
    handleChangeInput : (e:any) => void ;
    setData : React.Dispatch<React.SetStateAction<any[]>>,
}

export default function Dates(props:DatesUpdatingInvProps) {
    
    return (
    <React.Fragment>
        {/* DATE */}
        <div className='date'>
            <label htmlFor='date'>Date de la facture</label>
            <DatePicker
                selected={StringUtils.isUndefinedValue(props.selected_data_toMap.dates.inv_date) ? new Date() : new Date(props.selected_data_toMap.dates.inv_date)} 
                dateFormat="dd/MM/yyyy"
                onChange = {(e:any) => props.handleChangeInput({target : {name : "dates", value : {...props.selected_data.dates, inv_date : e}}})}
                locale="fr"       
            />
            {StringUtils.isUndefinedValue(props.selected_data.dates.inv_date) || props.selected_data.dates.inv_date === "" ? 
                <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
            : 
                null
            }
        </div>

        {/* RECEIPT DATE */}
        <div className='receipt_date'>
            <label htmlFor='receipt_date'>Date de réception</label>
            <DatePicker
                selected={StringUtils.isUndefinedValue(props.selected_data_toMap.dates.receipt_date) ? new Date(props.selected_data_toMap.dates.inv_date) : new Date(props.selected_data_toMap.dates.receipt_date)} 
                dateFormat="dd/MM/yyyy"
                onChange = {(e:any) => props.handleChangeInput({target : {name : "dates", value : {...props.selected_data.dates, receipt_date : e}}})}
                locale="fr"       
            />
            {StringUtils.isUndefinedValue(props.selected_data.dates.receipt_date) || props.selected_data.dates.receipt_date === "" ? 
                <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
            : 
                null
            }
        </div>

        {/* DUE DATE */}
        <div className='due_date'>
            <label htmlFor='due_date'>Date de règlement</label>
                <div className='inv_on_receipt'>
                <div 
                    className = {`checkbox ${props.selected_data.is_invoice_on_receipt ? "checkbox--true" : ""}`}
                    onClick = {() => {
                        props.handleChangeInput({target : {name : "is_invoice_on_receipt", value : !props.selected_data.is_invoice_on_receipt}})
                    }}
                >
                    <div></div>
                </div>
                <p>À réception de la facture</p>
            </div>

            {
                !props.selected_data.is_invoice_on_receipt ?
                    <React.Fragment>
                        <DatePicker
                            selected={StringUtils.isUndefinedValue(props.selected_data_toMap.dates.due_date) ? new Date() : new Date(props.selected_data_toMap.dates.due_date)} 
                            dateFormat="dd/MM/yyyy"
                            onChange = {(e:any) => props.handleChangeInput({target : {name : "dates", value : {...props.selected_data.dates, due_date : e}}})}
                            locale="fr"
                        />
                        {StringUtils.isUndefinedValue(props.selected_data.dates.due_date) || props.selected_data.dates.due_date === "" ? 
                            <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                        : 
                            null
                        }
                    </React.Fragment>
                : 
                    null
            }

        </div>


    </React.Fragment>
    )
}