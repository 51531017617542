

export type AmendmentType = {
    id : string,
    name : string,
    text : string
}

export const amendments_type:AmendmentType[] = [
    {
        id : "AMENDMENT-TYPE-01", 
        name : "amendments", 
        text : "Avenants"
    }, 
    {
        id : "AMENDMENT-TYPE-02", 
        name : "class_service", 
        text : "Ordre de service"
    },
    {
        id : "AMENDMENT-TYPE-03", 
        name : "additional_works", 
        text : "Travaux suppl."
    },
]