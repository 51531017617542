/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react' ; 
import { useDispatch } from 'react-redux';
import { ServerProps } from '../../../../../config/server.config';
import Totals from '../../../../base/Totals';
import { generate_totals } from '../../../utils.affairs';
import { GridEvents } from '../Grid';
import ContainerLoad from '../../../../../tools/components/general/ContainerLoad';
import { icons_config } from '../../../../../config/context.config';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { StringUtils, Table } from 'tc-minibox';
//import { useNavigate } from 'react-router-dom';
import { DescriptionCustom, PricingCustom, UnitCustom } from '../../../../base/tcminibox.utils';
import ListingCategory from '../../../../base/ListingCategory';


export interface DetailedLinesProps extends ServerProps {
    id_affair : number|string,
    id_amendment : number | null, 
    events? : GridEvents, 
    key_resize? : string | number,
}

interface DetailedLinesManager {
    mode : "load" | "normal" | "error",
    initialize : boolean,
    category : string | null, 
    subCategory : string | null
}

export default function DetailedLines(props:DetailedLinesProps) {
    //const navigate = useNavigate()
    const dispatch = useDispatch()
    const [manager, setManager] = useState<DetailedLinesManager>({
        mode : "load", 
        initialize : false, 
        category : null,
        subCategory : null
    })
    const [lines, setLines] = useState<any[]|[]>([])

   useEffect(() => {

        if(!manager.initialize) {
            setManager(state => {return{...state, mode : "load"}})
            props.server.post("/affairs/get-lines", {id_affair : props.id_affair, id_amendment : props.id_amendment})
            .then(res => {
                setManager(state => {return{...state, initialize : true, mode : "normal"}})
                setLines(res.data.data)
            })
            .catch(err => {
                setManager(state => {return{...state, initialize : false, mode : "normal"}})
                dispatch(err.response.data)
            })
        }
    }, [])

    useEffect(() => {
        if(props.events?.fullscreen === null && manager.category !== null) {
            setManager(state => {return{...state, category : null, subCategory : null}})
        }

    }, [props.events?.fullscreen])
    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Type",
                    name : "denomination", 
                }, 
 
                style : {
                    width : "80px", 
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Désignation", 
                    name : "description", 
                    custom : (tr, td) => <DescriptionCustom status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <DescriptionCustom status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>,
                style : {
                    width : `calc(100% - 420px ${props.events?.fullscreen === props.key_resize ? "- 500px" : ""})`, 
                }
            }, 
            ...props.events?.fullscreen === props.key_resize || !props.events ? 
                [
                    {
                        info : {
                            text : "Catégorie", 
                            name : "category", 
                        }, 
                        style : {
                            width : "250px"
                        }
                    }, 
                    {
                        info : {
                            text : "Sous-catégorie", 
                            name : "sub_category", 
                        }, 
                        style : {
                            width : "250px"
                        }
                    }, 
                ]
            : [],
            {
                info : {
                    text : "Unité", 
                    name : "unit", 
                    custom : (tr, td) => <UnitCustom status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <UnitCustom status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>,
                style : {
                    width : "100px"
                }
            }, 
            {
                info : {
                    text : "Quantité", 
                    name : "quantity", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "P.U.", 
                    name : "unit_price", 
                    custom : (tr, td) => <PricingCustom name = "unit_price" status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <PricingCustom name = "unit_price" status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>, 
                style : {
                    width : "100px"
                }
            }, 
            {
                info : {
                    text : "Total H.T", 
                    name : "total_ht", 
                    custom : (tr, td) => <PricingCustom name = "total_ht" status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <PricingCustom name = "total_ht" status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>, 
                style : {
                    width : "100px"
                }
            }, 
        ], 
        header : {
            custom : (data:any) => {
                return (
                    <div>
                        {
                            lines.length > 0 && props.events?.fullscreen !== null ? 
                                <ListingCategory
                                    data = {lines}
                                    manager = {manager}
                                    setManager = {setManager}
                                />
                            : 
                                null
                        }

                    </div>
                )
            }, 
        }, 
        filter_custom : (data:any) => {
            if(manager.category !== null) {
                return data.map((el:any) => {
                    if(manager.category !== null) {
                        if(StringUtils.undress_string(el[3]) === StringUtils.undress_string(manager.category)) {
                            if(manager.subCategory !== null) {
                                if(StringUtils.undress_string(el[4]) === StringUtils.undress_string(manager.subCategory)) {
                                    return el
                                }
                                return null
                            }
                            return el
                        }
                        return null
                    }
                    return el
                }).filter((el:any) => el !== null)
            }
            return data

        }, 
        data : lines,
        setData : setLines,
    }
    

    return (
        <ContainerLoad load = {manager.mode === "load"}>
            <Totals
                {...generate_totals(lines.map(ld => ld.total_ht).reduce((acc, curr) => acc + curr, 0))}
            />
            <Table {...table_config} />
        </ContainerLoad>
    )
}

