
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react' ; 
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { account_user } from '../../../store/account/selector.account';
import { ServerProps, server_host } from '../../../config/server.config';
import { addRequestStatus, setLoaderStatus} from '../../../store/global/actions.global';
import BasePopup from '../../../tools/components/general/BasePopup';
import { Icons, ObjectUtils, Table } from 'tc-minibox';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import MainButton from '../../../tools/components/general/MainButton';
import 'tippy.js/dist/tippy.css';
import { paymentStatuses } from '../payment.utils';
import { green_style_type_payments, type_payments } from '../../suppliers/utils.suppliers';
import Tippy from '@tippyjs/react';
import FilterBox, { FilterManager, initial_state_manager } from './FilterBox';
import ChangeStatusPopup from './grids/ChangeStatusPopup';
import { credit_note_style } from '../../invoices/suppliers/suppliersinv.utils';
import moment from 'moment';



export interface ListingProps extends ServerProps {
    data : DataType,
    setData : React.SetStateAction<any>, 
    handleAdd : () => void, 
    setManager : React.SetStateAction<any>
}

export interface ListingInvManager {
    initialization : boolean, 
    preview : {
        status : boolean, 
        content : any
    }, 
    comment : {
        status : boolean, 
        mode : null | "refused" | "validated" | "sent", 
        selected_data : string[]
    }, 
    refused : {
        status : boolean, 
        content : any
    }, 
    change_status_popup : {
        status : boolean, 
        selected_data : string[]
    }, 
    status : string, 
    message : string | null, 
    filter : string, 
    selected_data : any
}

export default function ListingInv(props:ListingProps) {
    //* GLOBAL
    const dispatch = useDispatch()

    // * STATES
    const user_account = useSelector(account_user)
    const [manager, setManager] = useState<ListingInvManager>({
        initialization : false, 
        comment : {
            status : false, 
            mode : null, 
            selected_data : []
        }, 
        preview : {
            status : false,
            content : null
        }, 
        refused : {
            status : false, 
            content : {}
        }, 
        change_status_popup : {
            status : false, 
            selected_data : []
        }, 
        status : "pending", 
        message : null, 
        filter : "all", 
        selected_data : null
    })

    const [filterBoxManager, setFilterBoxManager] = useState<FilterManager>(initial_state_manager)

    const tbody:DataType = props.data.map(inv => {
        const last_comment_log = inv.comments_log.length === 0 ? null : inv.comments_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
        
        const totalPaidAmount = inv.payments_log.reduce((total:any, payment:any) => total + parseFloat(payment.amount), 0);
        const restToPay = (parseFloat(inv.total_ttc) - totalPaidAmount).toFixed(2);
        
        return {
            ...inv, 
            tc_affairs_numbers :  inv.id_affairs.map((aff:any) => aff.id.affair_number).join(inv.id_affairs.length === 1 ? '' : ' ; '), 
            tc_supplier : inv.id_supplier.name, 
            tc_due_date : inv.dates.due_date,
            tc_receipt_date : inv.dates.receipt_date,
            tc_inv_date : inv.dates.inv_date,
            tc_total_ht : `${(inv.total_ttc - inv.total_tva).toFixed(2)} €`,
            tc_total_tva : `${inv.total_tva.toFixed(2)} €`,
            tc_total_ttc : `${inv.total_ttc.toFixed(2)} €`,
            tc_payments_mode : last_comment_log === null ? `${type_payments.find((tp:any) => tp.name === inv.id_supplier.payment_choice)?.text} (Par défaut)` : `${last_comment_log.content.length > 0 ? last_comment_log.content : "Aucun commentaire"}`, 
            tc_designations : "", 
            tc_payment_status : parseFloat(restToPay) === 0 ? "Payée" : inv.type === "credit-note" ? "Avoir" : `${restToPay}€`,

        }
    })

    // * FUNCTIONS
    const handleChangeStatus = (message : string) => {
        const formContainer = {
            status : manager.comment.mode, 
            user : user_account.infos._id, 
            date : new Date(), 
            comment : message, 
            selected_data : manager.comment.selected_data
        }
        
        return props.server.post('/invoicing/manage-inv-status', formContainer)
        .then(res => {
            dispatch(addRequestStatus(res.data))
            setManager(state => {
                return {
                ...state, 
                comment : {status : false, mode : null, selected_data : []}
            }})

            props.setManager((state:any) => {return {...state, initialize : false}})
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }


    const handleExport = async (selected_data:any, mode:string, attachements:boolean) => {
        return props.server.post('/payments/export', {
            selected_data : selected_data,
            mode : mode, 
            attachements : attachements
        })
        .then(res => {
            //dispatch(addRequestStatus(res.data))
            const link = document.createElement('a');
            link.href = server_host(`${res.data.data}?token=${user_account.infos.token_doc}`);
            link.download = attachements ? 'Suivi facturation.zip' : "Suivi facturation.xlsx"; // Nom du fichier à télécharger
            link.target = '_blank'; // O
              // Ajouter le lien au DOM et le déclencher
                document.body.appendChild(link);
                link.click();

                // Supprimer le lien du DOM une fois le téléchargement terminé
                document.body.removeChild(link);

            dispatch(addRequestStatus(res.data))
            
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
        /*
        try {
            const res = await props.server.post('/invoicing/export', { selected_data: selected_inv, date: current_date, id_user: user_account.infos.id_user });
            dispatch(addRequestStatus(res.data));

            const link = document.createElement('a');
            link.href = server_host(res.data.data);
            link.download = 'Factures.zip'; // Nom du fichier à télécharger
            link.target = '_blank'; // O


            // Ajouter le lien au DOM et le déclencher
            document.body.appendChild(link);
            link.click();

            // Supprimer le lien du DOM une fois le téléchargement terminé
            document.body.removeChild(link);

            dispatch(setLoaderStatus({ status: false }));
        } catch (err:any) {
            dispatch(setLoaderStatus({ status: false }));
            dispatch(addRequestStatus(err.response.data));
        }*/
    }

    const element_sizes = {
        status : 190, 
        payment : 130, 
        doc_number : 100,
        inv_number : 150, 
        supplier : 250, 
        inv_date : 100,
        reception_date : 100,
        payment_date : 110,
        total_ht : 90,
        total_tva : 90,
        total_ttc : 90,
        payment_mode : 300,
        designation : 300,
        codes : 100
    }
    const sum_sizes = Object.values(element_sizes).reduce((a:number, b:number) => a + b, 0)

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            {
                info : {
                    text : "Statut",
                    name : "status", 
                    custom : (tr, td) => {
                        var lastCommentStatus = tr.comments_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
 
                        lastCommentStatus = paymentStatuses.find(status => status.name === lastCommentStatus?.name)

                        return (
                            <div 
                                style={{
                                    backgroundColor : lastCommentStatus?.color, 
                                    color : lastCommentStatus?.textColor
                                }}
                                className="comment-status"
                            >
                                <p 
                                    style={{
                                        color : lastCommentStatus?.textColor
                                    }}
                                    className="text"
                                >{
                                    lastCommentStatus ? 
                                        lastCommentStatus.title
                                    : 
                                        "Aucun statut"
                                }</p>
                            </div>
                        )
                    }
                }, 
 
                style : {
                    width : `${element_sizes.status}px`, 
                }
            }, 
            {
                info : {
                    text : "À payer", 
                    name : "tc_payment_status", 
                    custom : (tr:any, _) => {
                        const totalPaidAmount = tr.payments_log.reduce((total:any, payment:any) => total + parseFloat(payment.amount), 0);
                        const restToPay = (parseFloat(tr.total_ttc) - totalPaidAmount).toFixed(2);

                        return (
                            <div className={`tc-payment-status tc-payment-status${parseFloat(restToPay) === 0 ? "--paid" : tr.type === "credit-note" ? "--credit-note" : "--to_pay" } `}>
                                {tr.tc_payment_status}
                            </div>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.payment}px`, 
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Codes", 
                    name : "tc_affairs_numbers", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_affairs_numbers}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.codes}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "N° pièce comptable", 
                    name : "doc_number",
                    custom : (tr:any, _) => {
                        return (
                            <p 
                                className={`doc_number ${green_style_type_payments.includes(tr.id_supplier.payment_choice) ? "doc_number--green" : ""}`}
                                style = {{
                                    ...tr.type === "credit-note" ? credit_note_style : {}, 
                                }} 
                            >
                                {tr.doc_number}
                            </p>
                        ) 
                    }  
                }, 
                style : {
                    width : `${element_sizes.doc_number}px`, 
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "N° de facture", 
                    name : "inv_number", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.inv_number}
                            </p>
                        )
                    }  
                }, 
                style : {
                    width : `${element_sizes.inv_number}px`, 
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }
                }
            }, 
            {
                info : {
                    text : "Fournisseur", 
                    name : "tc_supplier", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_supplier}
                            </p>
                        )
                    }  
                }, 
                style : {
                    width : `${element_sizes.supplier}px`, 
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de la facture", 
                    name : "tc_inv_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {moment(tr.tc_inv_date).format('DD/MM/YYYY')}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.inv_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de réception", 
                    name : "tc_receipt_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {moment(tr.tc_receipt_date).format('DD/MM/YYYY')}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.reception_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de règlement", 
                    name : "tc_due_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {
                                    tr.is_invoice_on_receipt ? 
                                        "À réception de la facture"
                                    : 
                                        moment(tr.tc_due_date).format('DD/MM/YYYY')
                                }
                            </p>
                        )
                    } 
                }, 
                style : {
                    width :`${element_sizes.payment_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "H.T", 
                    name : "tc_total_ht", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_ht}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_ht}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TVA", 
                    name : "tc_total_tva", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_tva}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_tva}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TTC", 
                    name : "tc_total_ttc", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_ttc}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_ttc}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "Mode de règlement", 
                    name : "tc_payments_mode", 
                    custom : (tr:any, _) => {
                        // tc_payments_mode
                        return (
                            <div className='tc_payments_mode'>
                                <Tippy
                                    content={(
                                        <div>{tr.tc_payments_mode}</div>
                                    )}
                                    interactive={true}
                                    trigger="click"
                                    placement="bottom"
                                    popperOptions={{
                                        modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                            fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                            offset: [0, 10], // Décale le tooltip de 10px vers le bas
                                            },
                                        },
                                        ],
                                    }}
                                >
                                    <p style = {tr.type === "credit-note" ? credit_note_style : {}}>{tr.tc_payments_mode}</p>

                                </Tippy>
                            </div>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.payment_mode}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "start"
                    }
                }
            }, 
            {
                info : {
                    text : "Désignation", 
                    name : "comment", 
                    custom : (tr:any, _) => {
                        // tc_payments_mode
                        return (
                            <div className='tc_comment'>
                                <Tippy
                                    content={(
                                        <div>{tr.comment}</div>
                                    )}
                                    interactive={true}
                                    trigger="click"
                                    placement="bottom"
                                    popperOptions={{
                                        modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                            fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                            offset: [0, 10], // Décale le tooltip de 10px vers le bas
                                            },
                                        },
                                        ],
                                    }}
                                >
                                    <p style = {tr.type === "credit-note" ? credit_note_style : {}}>{tr.comment}</p>

                                </Tippy>
                            </div>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.designation}px`,
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }, 

                }
            }, 

        ], 
        filter_custom : (data:any) => {
            var filtered_data = data

            if(filterBoxManager.analytic_code !== "") {
                filtered_data = filtered_data.filter((inv:any) => props.data[inv[0]].id_affairs.map(((aff:any) => `${aff.id.analytic_code}`.trim())).includes(filterBoxManager.analytic_code))
            }

            if(filterBoxManager.payment_mode !== null) {
                filtered_data = filtered_data.filter((inv:any) => {
                    const data_inv = props.data[inv[0]]
                    const totalPaidAmount = data_inv.payments_log.reduce((total:number, payment:any) => total + parseFloat(payment.amount), 0);
                    const restToPay = (parseFloat(data_inv.total_ttc) - totalPaidAmount).toFixed(2);
 
                    return filterBoxManager.payment_mode === "paid" ? parseFloat(restToPay) === 0 : filterBoxManager.payment_mode === "credit-note" ? data_inv.type === "credit-note" : parseFloat(restToPay) > 0
                })
            }

            if(filterBoxManager.supplier !== null) {
                filtered_data = filtered_data.filter((inv:any) => props.data[inv[0]].id_supplier._id === filterBoxManager.supplier)
            }

            if(filterBoxManager.status !== "all") {
                if(filterBoxManager.status === "no-status") {
                    filtered_data = filtered_data.filter((inv:any) => props.data[inv[0]].comments_log.length === 0)
                } else {
                    filtered_data = filtered_data.filter((inv:any) => {
                        const last_status = props.data[inv[0]].comments_log.sort((a:any, b:any) => b.date - a.date)[0]

                        if(last_status === undefined) {
                            return false
                        }

                        return last_status.name === filterBoxManager.status
                    })
                }
            }

            if( filterBoxManager.inv_date !== null) {
                filtered_data = filtered_data.filter((inv:any) => {
                    const inv_date = new Date(props.data[inv[0]].dates.inv_date)
                    return inv_date.getTime() === filterBoxManager.inv_date?.getTime()
                })
            }

            if( filterBoxManager.receipt_date !== null) {
                filtered_data = filtered_data.filter((inv:any) => {
                    const receipt_date = new Date(props.data[inv[0]].dates.receipt_date)
                    return receipt_date.getTime() === filterBoxManager.receipt_date?.getTime()
                })
            }

            if( filterBoxManager.due_date_start !== null) {
                filtered_data = filtered_data.filter((inv:any) => {
                    const due_date = new Date(props.data[inv[0]].dates.due_date)

                    const selected_due_date_end = filterBoxManager.due_date_end === null ? new Date() : filterBoxManager.due_date_end
                    return due_date.getTime() >= (filterBoxManager.due_date_start as any).getTime() && due_date.getTime() <= selected_due_date_end.getTime()
                    
                })
            }

            return filtered_data
        }
        , 
        data : tbody,
        setData : props.setData,
        header : {
            buttons : {

            }, 
            custom : (selected_data:any) => {
                var get_info_selected_data = selected_data.map((sd:any) => props.data[sd])
                get_info_selected_data = get_info_selected_data.map((st:any) => {
                    return {
                        ...st, 
                        last_status : st.status_log.sort((a:any, b:any) => b.date - a.date)[0]
                    }
                })


                return (
                    <div className="invoice-detail-header"> 
                        <div className='buttons'>
                        {
                            selected_data.length > 0 ? 
                                <motion.button 
                                    className='buttons--change-status'
                                    onClick = {() => setManager(state => {return {...state, change_status_popup : {...state.change_status_popup, status : true, selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})}
                                >
                                    Changer le statut
                                </motion.button>

                            : null
                        }

                        <motion.button 
                            className='export'>
                            Exporter

                            <div className='export--menu'>
                                {
                                    selected_data.length > 0 ? 
                                        <React.Fragment>
                                            <div
                                                onClick = {() => handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)}
                                            >
                                                Exporter la sélection
                                            </div>
                                            <div
                                                onClick = {() => handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)}
                                            >
                                                Exporter la sélection + pièces
                                            </div>
                                        </React.Fragment>
                                    : 
                                        null
                                }
                                <div
                                    onClick = {() => handleExport([], "all", false)}
                                >
                                    Exporter tout
                                </div>
                                <div
                                    onClick = {() => handleExport([], "all", true)}
                                >
                                    Exporter tout + pièces
                                </div>
                            </div>
                        </motion.button>
                        </div>
                        
                        <div className='tippy-filter-box'>
                            <Tippy
                                content={(
                                    <FilterBox 
                                        server = {props.server}
                                        manager = {filterBoxManager}
                                        setManager = {setFilterBoxManager}
                                    />
                                )}
                                interactive={true}
                                trigger="click"
                                placement="bottom"
                                popperOptions={{
                                    modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                        fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                        },
                                    },
                                    {
                                        name: 'offset',
                                        options: {
                                        offset: [0, 5], // Décale le tooltip de 10px vers le bas
                                        },
                                    },
                                    ],
                                }}
                            >
                                <motion.div className={`filter-box__button ${Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length > 0 ? "filter-box__button--active" : null } `}>
                                    {
                                        //
                                    }
                                    {
                                        Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length > 0 ? 
                                            <div className='filter-box__button--length'>
                                            {`${Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length}`}
                                            </div>
                                        : null
                                    }

                                    <Icons name = "filter" mode = "duotone" color = "#007aff" />
                                </motion.div>
                            </Tippy>
                        </div>

                    </div>
                
            )  
            }
        }, 
        handleDoubleClick : (tr:any) => {
            props.setManager((state:any) => {return {...state, selected_data : tr}})
        }
            

    }

    return (
        <React.Fragment>
            {
                manager.change_status_popup.status ? 
                    <ChangeStatusPopup 
                        server={props.server}
                        handleClose = {() => setManager(state => {return {...state, change_status_popup : {status : false, selected_data : []}}})}
                        rerender = {() => props.setManager((state:any) => {return {...state, initialize : false}})}
                        selected_data = {manager.change_status_popup.selected_data}
                        data = {props.data}
                        setData = {props.setData}
                    />

                : null 
            }
            {
                manager.comment.status ? 
                    <PopupStatusMessage
                        handleClose = {() => {
                            setManager(state => {return {...state, comment : {status : false, mode : null, selected_data : [], content : ""}}})
                        }}
                        handleSubmit = {(message:any) => handleChangeStatus(message)}
                    />
                : null
            }
            {
                manager.preview.status ?
                    <PopupInvoicePreview
                        handleClose = {() => setManager(state => {return {...state, preview : {status : false, content : null}}})}
                        url = {manager.preview.content}
                    />
                : 
                    null
            }
            <div className = "invoice-detail" style={{width : `${sum_sizes}px`}}>
                <Table  {...table_config}/>
            </div>
        </React.Fragment>
    )
}


function PopupStatusMessage(props:any) {
    const [message, setMessage] = useState("")

    return (
        <BasePopup
            nameClass = "popup-message-refused"
            handleClose = {props.handleClose}
        >
            <p>Souhaitez-vous ajouter un commentaire ?</p>
            <textarea
                value = {message}
                onChange = {(e) => setMessage(e.target.value)}
                placeholder='Tapez ici...'
            />
            <MainButton
                handleClick = {() => props.handleSubmit(message)}
            >
                Valider
            </MainButton>
        
        </BasePopup>
    )
}

function PopupInvoicePreview(props:any) {
    return (
        <BasePopup
            nameClass = "popup-invoice-preview"
            handleClose = {props.handleClose}
        >
            <iframe src={`${props.url}`} title="Web Page Viewer" className="iframe-container"/>
        </BasePopup>
    )
}