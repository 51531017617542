import React, {useState} from 'react' ; 
import BasePopup from '../../../../tools/components/general/BasePopup';
import {motion} from'framer-motion' ; 
import { useDispatch } from 'react-redux';
import { users_group } from '../../../../config/context.config';
import MainButton from '../../../../tools/components/general/MainButton';
import { addRequestStatus, setLoaderStatus } from '../../../../store/global/actions.global';
import { ServerProps } from '../../../../config/server.config';

export interface NewUserProps extends ServerProps {
    handleClose : () => void, 
    setData : any
}

export type NewUserForm = {
        firstname: string;
    lastname: string;
    mail: string;
    phone_number: string;
    user_group: string;
    function: string;
}

export default function NewUser(props:NewUserProps) {
    const dispatch = useDispatch()

    const [form, setForm] = useState<NewUserForm>({
        firstname : "", 
        lastname : "", 
        //pwd2 : "", 
        mail : "", 
        phone_number : "", 
        user_group : users_group[0].name, 
        function : "", 
    })

    const handleChangeInput = (e:any, reg:RegExp|string = '') => {
        const value = e.target.name !== "function" ? e.target.value.replace(reg, '').trim() : e.target.value

        setForm(state => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const handleSubmit = () => {

        //CHECK IF WE ARE ALL VALUES
        for (let key of Object.keys(form) as Array<keyof NewUserForm>) {
            if (`${form[key]}`.length === 0) {
                return dispatch(addRequestStatus({
                    status: false,
                    message: "Veuillez remplir tous les champs."
                }));
            }
        }

        //CHECK MAIL FORMAT 
        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.mail)) {
            dispatch(addRequestStatus({
                status : false, 
                message : "Le format de l'adresse mail est invalide"
            }))
        }

        dispatch(setLoaderStatus({status : true}))

        props.server.post('/access/createUser', form) 
        .then(res => {
            props.setData((state:any) => {
                return [
                    res.data.data, 
                    ...state
                ]
            })
            

            
            dispatch(addRequestStatus(res.data))
            props.handleClose()

            dispatch(setLoaderStatus({status : false}))

        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    return (
        <BasePopup
            nameClass = "newuser"
            handleClose = {props.handleClose}
        >
            <div className='identification'>
                <h1>Identification</h1>
                <div>
                    <div>
                        <label htmlFor='lastname'>Nom</label>
                        <input 
                            type = "text"
                            name = "lastname"
                            value = {form.lastname}
                            onChange = {(e) => handleChangeInput(e, /[0-9]/g)}
                        />
                    </div>
                    <div>
                        <label htmlFor='firstname'>Prénom</label>
                        <input 
                            type = "text"
                            name = "firstname"
                            value = {form.firstname}
                            onChange = {(e) => handleChangeInput(e, /[0-9]/g)}
                        />
                    </div>
                </div>
            </div>

            <div className='functions'>
                <h1>function</h1>
                <div>
                    <div>
                        <label htmlFor='function'>Poste occupé</label>
                        <input 
                            type = "text"
                            name = "function"
                            value = {form.function}
                            onChange = {handleChangeInput}
                        />
                    </div>
                    <div>
                        <label htmlFor='firstname'>Groupe utilisateur</label>

                        <div className='select'>
                            {
                                users_group.map(el => (
                                    <motion.button
                                        className={form.user_group === el.name ? "active" : ""}
                                        key = {JSON.stringify(el)}
                                        onClick = {() => handleChangeInput({target : {name : "user_group", value : el.name}})}
                                        {...form.user_group !== el.name ? {
                                            whileHover : {scale : 1.05}, 
                                            whileTap : {scale : 0.95}
                                        } : {}}
                                    >
                                        <p>{el.name}</p>
                                    </motion.button>
                                ))
                            } 
                        </div>
                    </div>
    
                </div>
            </div>


            <div className='connexion'>
                <h1>Connexion</h1>
                <div>
                    <div>
                        <label htmlFor='email'>E-mail</label>
                        <input 
                            type = "text"
                            name = "mail"
                            value = {form.mail}
                            onChange = {handleChangeInput}
                        />
                    </div>    
                </div>
            </div>

            <div className='infos'>
                <h1>Autres informations</h1>
                <div>
                    <div>
                        <label htmlFor='phone_number'>Téléphone</label>
                        <input 
                            type = "text"
                            name = "phone_number"
                            value = {form.phone_number}
                            onChange = {(e) => handleChangeInput(e, /[a-zA-Z]/g)}
                        />
                    </div>    
                </div>
            </div>

            <MainButton
                handleClick = {handleSubmit}
            >
                    Créer
            </MainButton>
    
        </BasePopup>
    )
}