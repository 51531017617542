import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icons, StringUtils } from 'tc-minibox'
import { addRequestStatus } from '../../../../../../store/global/actions.global'

export interface UpdatingInvTotalsProps {
    selected_data: any,
    selected_data_toMap: any,
    handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>, reg:RegExp|string) => void
}


export default function Totals(props:UpdatingInvTotalsProps) {
    const [ht, setHt] = useState<string>(`${(props.selected_data_toMap.total_ttc - props.selected_data_toMap.total_tva).toFixed(2)}`)
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            {/*TOTAL_HT */}
            <div className='total_ht'>
                <label htmlFor='total_ht'>Total HT</label>
                <div>
                    <input 
                        type = "text"
                        name = "total_ttc"
                        value = {`${ht}`}
                        onBlur={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(isNaN(props.selected_data.total_ttc) ) {
                                setHt("")
                            }
                        }}
                        onFocus={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(props.selected_data.total_ttc === "") {
                                setHt("-")

                                return setTimeout(() => {
                                    if (e.target) {
                                      e.target.setSelectionRange(ht.length, ht.length);
                                    }
                                  }, 0);
                            }

                            return setTimeout(() => {
                                if (e.target) {
                                  e.target.setSelectionRange("-".length, "-".length);
                                }
                              }, 0);

                        }}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(e.target.value.includes('-') && props.selected_data.type !== "credit-note") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'Pour que ce soit négatif, veuillez utiliser modifier le type de facture.'
                                }))
                            }

                            if(!/^-/.test(e.target.value) && props.selected_data.type === "credit-note" && e.target.value !== "") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être positive pour un avoir. Veuillez modifier le type de facture.'
                                }))
                            }

                            const reg = props.selected_data.type === "credit-note" ? /^(\s*|-?\d*[.,]?\d*)$/gm : /^(?:\s*|\d+[.,]?\d*)$/gm
                            // /^(?:\s*|\d+[.,]?\d*)$/gm
                            var value = e.target.value
        
                            if (reg instanceof RegExp && !reg.test(value)) {
                                // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
                                // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
                                return;
                            }
                    
                            value = value.replace(',', '.')
                            setHt(value)
                            props.handleChangeInput({
                                target : {
                                    name: 'total_ttc',
                                    value: `${(value.trim().length > 0 ? parseFloat(value) : 0) + parseFloat(props.selected_data_toMap.total_tva)}`
                                }
                            } as React.ChangeEvent<HTMLInputElement>
                            , reg)
                        }}
                    />
                    <p>€</p>
                </div>


            </div>

            {/*TOTAL_TVA */}
            <div className='total_tva'>
                <label htmlFor='total_tva'>Total TVA</label>
                <div>
                    <input 
                        type = "text"
                        name = "total_tva"
                        value = {`${props.selected_data_toMap.total_tva}`}
                        onBlur={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(isNaN(props.selected_data.total_tva)) {
                                props.handleChangeInput({
                                    target : {
                                        name: 'total_tva',
                                        value: ""
                                    }
                                } as React.ChangeEvent<HTMLInputElement>, "")


                            }
                        }}
                        onFocus={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(props.selected_data.total_tva === "") {
                                props.handleChangeInput({
                                    target : {
                                        name: 'total_tva',
                                        value: "-"
                                    }
                                } as React.ChangeEvent<HTMLInputElement>, "")
                            }
                            setTimeout(() => {
                                if (e.target) {
                                  e.target.setSelectionRange("-".length, "-".length);
                                }
                              }, 0);
                        }}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(e.target.value.includes('-') && props.selected_data.type !== "credit-note") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être négative pour une facture. Veuillez modifier le type de facture.'
                                }))
                            }

                            if(!/^-/.test(e.target.value) && props.selected_data.type === "credit-note" && e.target.value !== "") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être positive pour un avoir. Veuillez modifier le type de facture.'
                                }))
                            }

                            const reg = props.selected_data.type === "credit-note" ? /^(\s*|-?\d*[.,]?\d*)$/gm : /^(?:\s*|\d+[.,]?\d*)$/gm
                            // /^(?:\s*|\d+[.,]?\d*)$/gm
                            props.handleChangeInput(e, reg)
                        }}
                    />
                    <p>€</p>
                </div>
                {StringUtils.isUndefinedValue(props.selected_data.total_tva) || props.selected_data.total_tva === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }
            </div>

            {/*TOTAL_TTC*/}
            <div className='total_ttc'>
                <label htmlFor='total_ttc'>Total TTC</label>
                <div>{`${((ht.trim().length > 0 ? parseFloat(ht) : 0) + props.selected_data_toMap.total_tva).toFixed(2).replace('.', ',')} €`}</div>
                {/*StringUtils.isUndefinedValue(props.selected_data.total_ttc) || props.selected_data.total_ttc === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
            */}
            </div>
        </React.Fragment>
    )
}