import {useRef, useCallback} from 'react'; 
import {useDropzone} from 'react-dropzone';
import {ReactComponent as AddIllustration} from '../../../assets/svg/illustrations/illustration__add.svg' ; 
import {ReactComponent as DragOn} from '../../../assets/svg/icons/drag_on.svg' ; 

export interface ImportProps {
    multiple? : boolean, 
    subtitle : string,
    nameClass? : string,
    handleSubmit : (files:any) => void
}

/*const dragNdrop_animation = {
    initial : {y : 100, opacity : 0}, 
    animate : {y : 0, opacity : 1, transition : {type : "linear", delay : 0.6}}, 
    exit : {y : 100, opacity : 0}
}*/

export default function ImportModule(props:ImportProps) {
    /*
        Neccesary props : 
            subtitle = {}
            file = {}
            handleSubmit = {}
    */

    const fileInputRef = useRef(null)
    const onDrop = useCallback((acceptedFiles:File[]) => {
        props.handleSubmit(props.multiple ? acceptedFiles : acceptedFiles[0])
    }, [props])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: false})

    return (
        <div 
            {...getRootProps()}
            className={`import ${props.nameClass ? props.nameClass : ""}`}
        > 
            <div>
                <div className='import__intro' >
                            <h1>Importer un fichier</h1>
                            <p>{props.subtitle}</p>
                        </div>

                            <div 
                                className='import__illustration'
                                
                            >
                                <AddIllustration />
                                <p>
                                    Glissez et déposez vos fichiers ici ou choisissez des fichiers.
                                </p>
                </div>
            </div>

        {
            isDragActive ?
                <div className='import__dragMode'>
                    <DragOn />    
                </div>
            : null
        }

        <input 
            type = "file"
            ref={fileInputRef} 
            {...getInputProps()}
        />
        </div>


    )
}