import React, { useState } from "react" ; 
import {motion} from 'framer-motion' ; 
import { ServerProps } from "../../../../../config/server.config";
import { Icons, StringUtils } from "tc-minibox";
import Identification from "./grids/Identification";
import Dates from "./grids/Dates";
import Totals from "./grids/Totals";
import Comment from "./grids/Comment";
import SupplierManager from "../../../../suppliers/manager/Manager";
import { icons_config } from "../../../../../config/context.config";

export interface UpdatingInvoiceProps extends ServerProps {
    data : any[],
    selected_data : string | null | number,
    setData : React.Dispatch<React.SetStateAction<any[]>>,
    status_data : boolean,
    status_config : any[],
    key_id : string | number | null, 
}

export interface UpdatingInvoiceManager {

}

export default function UpdatingInvoice(props:UpdatingInvoiceProps) {
    const [manager, setManager] = useState({
        create_invoice : false, 
        selected_modal : "MOD-01", 
        supposed_supplier : ""
    })


    const [events, setEvents] = useState({
        hover : null, 
        fullscreen : null
    })

    const selected_data = props.data.find(dt => dt.url === props.selected_data)
    //const status_data = Object.values(selected_data).map(dt => isUndefinedValue(dt) || dt === "").find(dt => dt === true)
    const selected_data_toMap = Object.fromEntries(Object.entries(selected_data).map(dt => {
                                    return dt
                                }))

    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:RegExp|string = '') => {
        var value = e.target.value
        
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        if(['total_tva', 'total_ttc'].includes(e.target.name)) {
            value = value.replace(',', '.')
        }

        props.setData(state => state.map(st => st.url === props.selected_data ? {...st, [e.target.name] : value} : st))
        //props.setData(state => {return state.map(st => st.url === props.selected_data ? )})
    }

    const handleSupposedSupplier = (val:string) => {
        setManager(state => {return{...state, supposed_supplier : val, create_invoice : true}})
    }

    const handleSelectSearch = (e:any) => {
        props.setData(state => state.map(st => st.url === props.selected_data ? {...st, id_supplier : e._id, name_supplier : e.name} : st))

        const similarities = props.data.filter(dt => StringUtils.undress_string(dt.supposed_supplier) === StringUtils.undress_string(selected_data.supposed_supplier) && StringUtils.isUndefinedValue(dt.id_supplier)).map(dt => dt.url)

        props.setData(state => state.map(st => {
            if(st.url === props.selected_data || similarities.includes(st.url)) {
                return {...st, id_supplier : e._id, name_supplier : e.name}
            }
            
            return st
        }))

    }

    const handleChangeModals = (id:string) => setManager(state => {return{...state, selected_modal : id}})

    const config = [
        {
            id : "MOD-00", 
            name : "identification",
            svg : "id-badge", 
            text : "Identification",
            modal : <Identification
                server = {props.server}
                selected_data = {selected_data}
                handleChangeInput = {handleChangeInput}
                handleSupposedSupplier = {handleSupposedSupplier}
                //setManager = {setManager}
                setData={props.setData}
            />
            
        }, 
        {
            id : "MOD-01", 
            name : "dates", 
            svg : "calendar-days", 
            text : "Dates",
            modal : <Dates
                selected_data_toMap = {selected_data_toMap}
                selected_data = {selected_data}
                handleChangeInput = {handleChangeInput}
                setData={props.setData}
            />
        }, 
        {
            id : "MOD-02", 
            name : "totals", 
            svg : "users",
            text : "Totaux",
            modal : <Totals
                selected_data_toMap = {selected_data_toMap}
                selected_data = {selected_data}
                handleChangeInput = {handleChangeInput}
            />
        }, 
        {
            id : "MOD-03", 
            name : "comment", 
            svg : "comments", 
            text : "Désignation",
            modal : <Comment
                selected_data_toMap = {selected_data_toMap}
                handleChangeInput = {handleChangeInput}
            />
        }, 
    ]


    return (
        <React.Fragment>

            {
                manager.create_invoice ? 
                    <SupplierManager
                        supposed_supplier = {{name : manager.supposed_supplier?? "" }}
                        server = {props.server}
                        handleAdd = {(e) => {
                            handleSelectSearch(e) 
                        }}
                        handleClose = {() => setManager(state => {return{...state, create_invoice : false}})}
                    />
                : null
            }


            <motion.div 
                exit = {{opacity : 0, y : 0}}
                initial = {{opacity : 0, y : 250}}
                animate = {{opacity : 1, y : 0, transition : {type : "linear"}}}
                className='invoice-update'
                key = {props.key_id}

            >
                <motion.div 
                    {...{
                        exit : {opacity : 0, y : -100},
                        initial : {opacity : 0, y: -100},
                        animate : {opacity : 1, y : 0, transition : {type : "linear", delay : 0.2}},
                    }}
                    className={`status ${`status--${props.status_config.find(sd => sd.bool === props.status_data).name}`}`}
                >
                    {props.status_config.find(sd => sd.bool === props.status_data).text}
                </motion.div>
                <div className='form'>
                    <div className='buttons'>
                        {
                            config.map((cf, cf_index) => (
                                <motion.div 
                                    exit = {{x : 100, opacity : 0}}
                                    initial = {{x : 100, opacity : 0}}
                                    animate = {{x : 0, opacity : 0.5}}
                                    transition = {{
                                        type : "linear", 
                                        delay : 0.2 + (cf_index * 0.2)
                                    }}
                                    onClick = {() => handleChangeModals(cf.id)}
                                    key ={cf.id} 
                                    className={manager.selected_modal === cf.id ? "active" : ""}
                                >
                                    <Icons name = {cf.svg} {...icons_config} />
                                    <p>{cf.text}</p>
                                </motion.div>
                            ))
                        }
                    </div>

                    <motion.div 
                        key ={manager.selected_modal}
                        className={`selected-modal`}
                    >
                        {events.fullscreen === "MODAL" ? 
                            <motion.div 
                                exit = {{opacity : 0}}
                                animate = {{opacity : 1, transition : {delay : 0.5, duration : 0.5}}}
                                initial = {{opacity : 0}}
                                className='selected-modal__background'
                                onClick={() => setEvents(state => {return{...state, fullscreen : null}})}
                            >

                            </motion.div> 
                        : null}

                        {config.find(cf => cf.id === manager.selected_modal)?.modal}



                    </motion.div>

                </div>
            </motion.div>
        </React.Fragment>
    )
}


