import React from 'react'

export interface CommentUpdatingInvProps {
    selected_data_toMap: any, 
    handleChangeInput: (e:any) => void
}

export default function Comment(props:CommentUpdatingInvProps) {
    return (
    <React.Fragment>

        <div className='comment'>
            <label htmlFor='date'>Désignation (commentaire) : </label>
            <textarea
                name = "comment"
                value = {props.selected_data_toMap.comment}
                placeholder = "Tapez ici..."
                onChange = {(e) => props.handleChangeInput(e)}
            >
            </textarea>
        </div>
    </React.Fragment>
    
)}