import BasePopup from "../../../../../tools/components/general/BasePopup";
import CategorizerManager from "../../../../../tools/components/general/Categorizer";
import Comment from "./grids/Comment";
import Dates from "./grids/Dates";
import Identification from "./grids/Identification";
import React, { useEffect, useState } from "react";
import { server_host, ServerProps} from "../../../../../config/server.config";
import Totals from "./grids/Totals";
import { Icons, ObjectUtils } from "tc-minibox";
import MainButton from "../../../../../tools/components/general/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../../store/global/actions.global";
import { checkInvStatus } from "../deposit/utils.deposit";
import { account_user } from "../../../../../store/account/selector.account";
import SupplierManager from "../../../../suppliers/manager/Manager";
import { ListingInvManager, validation_config } from "../Listing";
import moment from "moment";
import ContainerLoad from "../../../../../tools/components/general/ContainerLoad";


export interface PopupUpdateInvProps extends ServerProps{
    selected_data : any, 
    data : any, 
    handleClose : () => void,
    setData : any, 
    setManager : React.SetStateAction<any>, 
    filtering_data : any
}

interface PopupUpdateInvManager {
    create_invoice : boolean, 
    supposed_supplier : string,
    load : boolean
}



export default function PopupUpdateInv(props:PopupUpdateInvProps) {
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()
    const [form, setForm] = useState<any>(props.selected_data)
    const [manager, setManager] = useState<PopupUpdateInvManager>({
        create_invoice : false, 
        supposed_supplier : "", 
        load : false, 
    })

    const last_status = form.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
    const selected_data_index = props.filtering_data.findIndex((dt:any) => dt === form._id)

    //const [manager, setManager] = useState<any>({})
    const handleChangeInput = (e:any) => {
        /*const last_status_log = form.status_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0] 

        if(last_status_log.status === "sent") {
            return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas modifier une facture envoyée au comptable"}))
        }*/
        return setForm((state:any) => {return{...state, [e.target.name] : e.target.value}})
    }

    const config = [
        {
            id : "MOD-00", 
            name: "Identification", 
            svg : "id-badge", 
            component : <Identification
                server = {props.server}
                selected_data = {form}
                handleChangeInput = {handleChangeInput}
                setData = {setForm}
                handleSupposedSupplier={(e:string) => {
                    setManager(state => {return{...state, supposed_supplier : e, create_invoice : true}})
                }}
            />
        }, 
        {
            id : "MOD-01", 
            name : "Dates",
            svg : "calendar-days", 
            component : <Dates
                selected_data_toMap = {form}
                selected_data = {form}
                handleChangeInput = {handleChangeInput}
                setData = {setForm}
            />
        }, 
        {
            id : "MOD-02", 
            svg : "money-bill", 
            name : "Totaux",
            component : <Totals
                selected_data_toMap = {form}
                selected_data = {form}
                handleChangeInput = {handleChangeInput}
            />
        }, 
        {
            id : "MOD-03", 
            name : "Désignation", 
            svg : "comments",  
            component : <Comment
                selected_data_toMap = {form}
                handleChangeInput = {handleChangeInput}
            />
        }, 
    ]
    const handleUpdate = () => {
        dispatch(setLoaderStatus({status : true}))

        if(!checkInvStatus(form)) {
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus({status : false, message : "Veuillez remplir tous les champs."}))
        }
        
        props.server.post("/invoicing/update-inv", {...Object.fromEntries(Object.entries(ObjectUtils.getObjectDifference(props.selected_data,form))), id_inv : props.selected_data._id})
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            props.setData((state : any) => {
                return state.map((dt:any) => {
                    if(dt._id === props.selected_data._id) {
                        return res.data.data
                    }
                    return dt
                })
            
            })
            props.handleClose()
            return dispatch(addRequestStatus({
                status : true, 
                message : "Facture modifiée avec succès"
            }))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(err.response.data))
        })
    }

    useEffect(() => {
        if(props.data.find((dt:any) => dt._id === props.selected_data._id).status_log.length !== form.status_log.length) {
            setForm((state:any) => {
                return {
                    ...state, 
                    status_log : props.data.find((dt:any) => dt._id === props.selected_data._id).status_log
                }
            })
        }
    }, [props.data])

    const handleChangeInvoice = (status:"next"|"prev") => {
        var current_index = selected_data_index

        if(status === "next") {
            current_index++
        } else {
            current_index--
        }

        const get_id = props.filtering_data[current_index]
        setManager(state => {return{...state, load : true}})
        props.server.post('/invoicing/select-inv', {id_inv : get_id})
        .then(res => {
            const data = {
                ...res.data.data,
                url : server_host(res.data.data.url)
            }
            //dispatch(setLoaderStatus({status : false}))
            setForm(data)

            setTimeout(() => {
                setManager(state => {return{...state, load : false}})
            }, 500)
        })
        .catch(err => {
            setManager(state => {return{...state, load : false}})
            dispatch(addRequestStatus(err.response.data))
        })
    }

    return (
        <React.Fragment>
             {
                manager.create_invoice ? 
                    <SupplierManager
                        supposed_supplier = {{name : manager.supposed_supplier?? "" }}
                        server = {props.server}
                        handleAdd = {(e) => {
                            setForm((state:any) => {return{...state, id_supplier : e._id, name_supplier : e.name}})
                        }}
                        handleClose = {() => setManager(state => {return{...state, create_invoice : false}})}
                    />
                : null
            }
            <BasePopup handleClose = {props.handleClose} nameClass="popup-inv-update">
                <ContainerLoad load = {manager.load}>
                    <div className = "popup-inv-update--header">
                        {
                            selected_data_index > 0 ?
                                <div 
                                    onClick = {() => handleChangeInvoice("prev")}
                                    className="arrows arrows--prev"
                                >
                                    <Icons name = "arrow-down" color = "#116633"/>
                                <p>Précédent</p>
                                </div>
                            : null
                        }


                        <div className="actions">
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "pending", selected_data : [form._id]}}})}
                                className={last_status.status === "pending" ? "actions--pending" : ""}
                            >
                                <p>En attente</p>
                                {
                                    last_status.status === "pending" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "validated", selected_data : [form._id]}}})}
                                className={last_status.status === "validated" ? "actions--validated" : ""}
                            >
                                <p>Valider</p>
                                {
                                    last_status.status === "validated" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "refused", selected_data : [form._id]}}})}
                                className={last_status.status === "refused" ? "actions--refused" : ""}
                            >
                                <p>Refuser</p>
                                {
                                    last_status.status === "refused" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                        </div>

                        {
                            selected_data_index < props.filtering_data.length - 1 ?
                                <div 
                                    onClick = {() => handleChangeInvoice("next")}
                                    className="arrows arrows--next">
                                    <p>Suivant</p>
                                    <Icons name = "arrow-down" color = "#116633"/>
                                </div>
                            : null
                        }
                    </div>
                    <div className = "popup-inv-update--container">
                        <div className='preview'>
                            <iframe src={form.url} title="Web Page Viewer" className="iframe-container"/>
                        </div>
                        <div className = "update">
                            <CategorizerManager config = {config} rerender = {true} />
                            {
                                // ! !form.sent_to_accountant.status &&  (À paramétrer dans le back-end pour les factures envoyées au comptable)
                                Object.entries(ObjectUtils.getObjectDifference(form, props.selected_data)).length > 0 && user_account.infos.user_group === "direction" ? 
                                    <MainButton
                                        handleClick = {handleUpdate}
                                    >
                                        Modifier
                                    </MainButton>
                                : 
                                    null
                            }

                        </div>
                    </div>   
                </ContainerLoad>
            </BasePopup>
        </React.Fragment>
    )
}

interface LastStatusInterface {
    st : any
}

function LastStatus(props:LastStatusInterface) {
    return <div>
    <p>{`${props.st.comment.trim().length === 0 ? "Aucun commentaire" : props.st.comment}`}</p>
    <p>
        <span>{`Posté le ${moment(new Date(props.st.date)).format('DD/MM/YYYY')} à ${moment(new Date(props.st.date)).format('HH:mm')} `}</span>
        <span>{`Par ${props.st.user.firstname} ${props.st.user.lastname}`}</span>
    </p>
</div>
}