/*

exit = "exit"
animate = "animate"
initial = "initial"


export const variables = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {

    }
}

*/

export const intro_homepage = {
    exit : {
        x : -100, 
        opacity : 0
    }, 
    animate : {
        x : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4
        }
    }, 
    initial : {
        x : -100, 
        opacity : 0
    }
}

export const container_homepage = {
    exit : {
        x : -100, 
        opacity : 0
    }, 
    animate : {
        x : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.2
        }
    }, 
    initial : {
        x : -100, 
        opacity : 0
    }
}

export const illustration_homepage = {
    exit : {
        x : 200, 
        opacity : 0
    }, 
    animate : {
        x : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.2
        }
    }, 
    initial : {
        x : 200, 
        opacity : 0
    }
}
