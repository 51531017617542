import React, { useEffect, useRef, useState } from "react";
import BasePopup from "../../../tools/components/general/BasePopup";
import {AnimatePresence, motion} from 'framer-motion'
import { ServerProps, server_host } from "../../../config/server.config";
import { PaymentStatus, paymentStatuses, payment_report } from "../payment.utils";
import { DatePicker, Icons, StringUtils } from "tc-minibox";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../../store/account/selector.account";
import {ReactComponent as NoDataCuate} from '../../../assets/svg/illustrations/no-data-cuate.svg'
import {ReactComponent as BankNotePana} from '../../../assets/svg/illustrations/banknote-pana.svg'
import moment from "moment";
import { addRequestStatus, set_popup_warning } from "../../../store/global/actions.global";
import { v4 } from "uuid";
import ContainerLoad from "../../../tools/components/general/ContainerLoad";
import { icons_config } from "../../../config/context.config";
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import {Table} from "tc-minibox"
import FullPayPopup from "./popup/FullPayPopup";


export interface PaymentDetailProps extends ServerProps {
    setManager : React.Dispatch<React.SetStateAction<any>>,
    handleClose : () => void, 
    data : any, 
    setData : React.Dispatch<React.SetStateAction<any>>
}

export interface EventsProps {
    hover : any
}

export interface PaymentDetailManager {
    status_comments_load : boolean,
    status_payments_load : boolean,
    mode : "payment" | "comments", 
    full_pay_popup : {
        status : boolean, 
        mode : "comment" | "payment",
    }
}

function isDateValid(dateString:string) {
    // Crée un nouvel objet Date à partir de la chaîne de date fournie
    const date = new Date(dateString);
    // Utilise la méthode isNaN() pour vérifier si la date est invalide
    // La date est invalide si elle est égale à NaN ou si la date est postérieure à "Invalid Date"
    return !isNaN(date as any) && date as any !== 'Invalid Date';
}


export default function PaymentDetail(props:PaymentDetailProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)

    // * REFS
    const endOfLogRef = useRef<HTMLDivElement>(null);


    // 1. For histories
    const [commentsLog, setCommentsLog] = useState<any[]>(props.data.comments_log)
    const [paymentsLog, setPaymentsLog] = useState<any[]>(props.data.payments_log)

    // 2. For forms
    const [newStatus, setNewStatus] = useState<PaymentStatus>({
        id : v4(),
        name : paymentStatuses[0].name, 
        content : "", 
        date : new Date(), 
        user : user_account.infos._id,
    })

    const [newPayment, setNewPayment] = useState<any>({
        id : v4(),
        date : isDateValid(props.data.dates.due_date) ? new Date(props.data.dates.due_date) : new Date(), 
        amount : "", 
        user : user_account.infos._id
    })

    // 3. Managers
    const [manager, setManager] = useState<PaymentDetailManager>({
        status_comments_load : false, 
        status_payments_load : false,
        mode : "comments", 
        full_pay_popup : {
            status : false, 
            mode : "payment"
        }
    })

    const [events, setEvents] = useState<EventsProps>({
        hover : null, 
    })
    
    // * VARIABLES
    const current_status = paymentStatuses.find(status => status.name === newStatus.name)

    const tbody__payments = paymentsLog.map((el, index) => {
        return {
            ...el,
            amount_text : `${parseFloat(el.amount).toFixed(2)} €`, 
            user : `${el.user.firstname} ${el.user.lastname}`,
        }
    }).sort((a, b) => {
        // Convertir les dates en objets Date
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
    
        // Trier par date en ordre décroissant
        return dateB - dateA;
    })

    const totalPaidAmount = paymentsLog.reduce((total, payment) => total + parseFloat(payment.amount), 0);
    const restToPay = (parseFloat(props.data.total_ttc) - totalPaidAmount).toFixed(2);
    var lastCommentStatus = commentsLog.reduce((prevComment, currentComment) => {
        if (!prevComment || new Date(currentComment.date) > new Date(prevComment.date)) {
            return currentComment;
        }
        return prevComment;
    }, null);
    lastCommentStatus = paymentStatuses.find(status => status.name === lastCommentStatus?.name)

    const handleUpdate = (data:any) => {
        setManager(state => {
            return {
                ...state, 
                status_comments_load : true, 
                status_payments_load : true
            }
        })

        props.server.post('/payments/update', {
            id: props.data._id, 
            pay_comments_log : commentsLog,
            pay_payments_log : paymentsLog,
            ...data
        })
        .then(res => {
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : res.data.message
            
            }))
            props.setData({
                comments_log : res.data.data.comments_log,
                payments_log : res.data.data.payments_log,
            })
            setManager(state => {
                return {
                    ...state, 
                    status_comments_load : false, 
                    status_payments_load : false
                }
            })
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            setManager(state => {
                return {
                    ...state, 
                    status_comments_load : false, 
                    status_payments_load : false
                }
            })
        })
    }

    // * FUNCTIONS 
    const handleSubmitComment = () => {
        setManager(state => {
            return {
                ...state, 
                status_comments_load : true
            }
        })
        props.server.post('/payments/add-comment-log', {
            id_inv : props.data._id,
            data : newStatus,
        })
        .then(res => {
            setCommentsLog(res.data.data.comments_log)
            setNewStatus((state:any) => {
                return {
                    ...state, 
                    id : v4(), 
                    content : "", 
                    name : "awaiting-payment"
                }
            })
            setManager(state => {
                return {
                    ...state, 
                    status_comments_load : false
                }
            })
            props.setData({comments_log : res.data.data.comments_log})
            dispatch(addRequestStatus({
                status : res.data.status, 
                message : res.data.message
            }))
        })
        .catch(err => {
            setManager(state => {
                return {
                    ...state, 
                    status_comments_load : false
                }
            })
            dispatch(addRequestStatus(err.response.data))
        })
    }
    const handleSubmitPayment = () => {
        setManager(state => {
            return {
                ...state, 
                status_payments_load : true
            }
        })
        props.server.post('/payments/add-payment-log', {
            id_inv : props.data._id,
            data : {
                ...newPayment, 
                amount : parseFloat(newPayment.amount).toFixed(2)
            },
        })
        .then(res => {
            setPaymentsLog(res.data.data.payments_log)
            setNewPayment((state:any) => {
                return {
                    ...state, 
                    id : v4(), 
                    amount : ""
                }
            })
            setManager(state => {
                return {
                    ...state, 
                    status_payments_load : false
                }
            })
            props.setData({payments_log : res.data.data.payments_log})

            dispatch(addRequestStatus({
                status : res.data.status, 
                message : res.data.message
            }))
        })
        .catch(err => {
            setManager(state => {
                return {
                    ...state, 
                    status_comments_load : false
                }
            })
            dispatch(addRequestStatus(err.response.data))
        })
    }

    const handleDeleteComment = (id:string) => {
        setCommentsLog((state:any) => {
            return state.filter((el:any) => el.id !== id)
        })
        handleUpdate({
            pay_comments_log : commentsLog.filter((el:any) => el.id !== id)
        })
    }

    const handleDeletePayment = (data:any[]) => {
        setPaymentsLog((state:any) => {
            return state.filter((el:any) => !data.map(d => d.id).includes(el.id))
        })
        handleUpdate({
            pay_payments_log : paymentsLog.filter((el:any) => !data.map(d => d.id).includes(el.id))
        })
    }

    const handleExport = () => {
        const data = paymentsLog.map((el:any) => {
            return {
                pay_date : el.date, 
                pay_amount : el.amount,
                pay_user : el.user,
                ...props.data, 
            }
        })
        payment_report(data)

        return dispatch(addRequestStatus({
            status : true, 
            message : "Le rapport a été exporté avec succès."
        }))
    }
    

    const table_config:TableForProps = {
        height : 30, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Date", 
                    name : "date",
                    type : "date" 
                }, 
                style : {
                    width : "100px",
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "EUR", 
                    name : "amount_text", 
                }, 
                style : {
                    width : "80px",
                    tbody : {
                        textAlign : "end"
                    }
                }
            },
            {
                info : {
                    text : "Utilisateur", 
                    name : "user", 
                }, 
                style : {
                    width : "calc(100% - 180px)",
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody__payments,
        setData : setPaymentsLog,
        header : {
            buttons : {
                include : ["delete"], 
                actions : {
                    //add : () => navigate('/new')
                    delete : (data:any) => {
                        if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins un paiement."}))
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : `Êtes-vous sûr de vouloir supprimer ${data.length} paiement(s)`, 
                            handleYes : () => handleDeletePayment(data)
                        }))
                    }
                }
            }, 
            custom : () => {
                return (
                    <button 
                        onClick = {() => handleExport()}
                        className="export"
                    >
                        Exporter 
                    </button>
                )
            }
        }, 
    }


    // * USE EFFECTS 
    useEffect(() => {
        endOfLogRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [commentsLog, manager]);

    //TIMER PAYMENT LOG LOAD WHEN PAYMENT LOG CHANGE
    useEffect(() => {
        setManager(state => {return{...state, status_payments_load : true}})
        setTimeout(() => {
            setManager(state => {return{...state, status_payments_load : false}})
        }, 1000);
    }, [paymentsLog])
    return (
        <React.Fragment>
            {
                manager.full_pay_popup.status ? 
                    <FullPayPopup 
                        handleClose = {() => setManager(state => {return{...state, full_pay_popup : {status : false, mode : "comment"}}})}
                        mode = {manager.full_pay_popup.mode}
                        newStatus = {newStatus}
                        setNewStatus = {setNewStatus}
                        newPayment = {newPayment}
                        setNewPayment = {setNewPayment}
                        handleSubmitComment={handleSubmitComment}
                        handleSubmitPayment={handleSubmitPayment}
                    />

                : null
            }
            <BasePopup handleClose = {props.handleClose} nameClass="payment-detail">
                <div className="payment-detail__header">
                    <h1>{`Facture ${props.data.doc_number} - ${props.data.id_supplier.name}`}</h1>
                    <div className = "general-statuses">
                        <div className="general-statuses--affairs">
                            {`Code${props.data.id_affairs.length > 1 ? "s" : ""}: ${props.data.id_affairs.map((aff:any) => aff.id.affair_number).join(props.data.id_affairs.length > 1 ? " / " : "")}`}
                        </div>
                        {
                            lastCommentStatus ?
                                <div 
                                    style={{backgroundColor : lastCommentStatus.color, color : lastCommentStatus.textColor}}
                                    className="general-statuses--comment"
                                >{lastCommentStatus.title}</div>
                            : 
                                <div className="general-statuses--comment">Aucun commentaire</div>
                        }
                        {
                            parseFloat(restToPay) !== 0 ?
                                <div 
                                    style={{backgroundColor : "#f44336", color : "#fff"}}
                                    className="general-statuses--payment"
                                >{`${restToPay} € à régler`}</div>
                            : 
                                <div 
                                    style={{backgroundColor : "#4caf50", color : "#fff"}}
                                    className="general-statuses--payment"
                                >
                                        {`Soldé`}</div>
                        }


                    </div>
                </div>
                <div className="payment-detail__body">
                    <div className="payment-detail__body--invoice">
                        <iframe src={server_host(`${props.data.id_doc.url}?token=${user_account.infos.token_doc}`)} title="Web Page Viewer" className="iframe-container"/>
                    </div>

                    <div className="payment-detail__body--payments">
                        <motion.div 
                            whileHover={{scale : 1.1}}
                            whileTap={{scale : 0.90}}
                            onClick = {() => setManager(state => {return{...state, mode : state.mode === "comments" ? "payment" : "comments"}})}
                            className="button-mode"
                        >
                            <Icons name = {manager.mode === "comments" ? "comments" : "money-bill"} mode = "duotone"  color = "white"/>
                            <Icons name = "arrows-repeat" mode = "plain"  color = "white"/>
                        </motion.div>
                        <h1>{manager.mode === "comments" ? "Commentaires" : "Paiements"}</h1>
                        {
                            manager.mode === "comments" ?
                                <div>
                                    <div className="status-log">
                                        <ContainerLoad load = {manager.status_comments_load}>
                                        {
                                            commentsLog.sort((a:any, b:any) => new Date(a.date).getTime() - new Date(b.date).getTime()).length > 0 ? 
                                                <div className="comments-history">
                                                    <div>
                                                    {
                                                        commentsLog.map((log, index) => {
                                                            const isLastItem = index === commentsLog.length - 1;

                                                            return (
                                                                <div 
                                                                    className="comments-history__item" 
                                                                    key={`LOG-INDEX-${index}`}
                                                                >
                                                                    <div className="comments-history__item--status">
                                                                        <div className="bubble" style={{backgroundColor : paymentStatuses.find(status => status.name === log.name)?.color}}></div>
                                                                        <p className="title">{paymentStatuses.find(status => status.name === log.name)?.title}</p>
                                                                        <div className="buttons-actions">
                                                                            <div 
                                                                                onClick={() => dispatch(set_popup_warning({
                                                                                    status : true, 
                                                                                    text : "Êtes-vous sûr de vouloir supprimer ce commentaire ?", 
                                                                                    handleYes : () => handleDeleteComment(log.id)
                                                                                }))}
                                                                                className="buttons-actions--delete"
                                                                            >
                                                                                <Icons name = "trash" mode = "duotone" color = "rgb(167, 1, 1)" />
                                                                                <p>Supprimer</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="comments-history__item--comment">
                                                                        {StringUtils.isUndefinedValue(log.content) || log.content.trim().length === 0 ? "Aucun commentaire" : log.content }
                                                                    </div>
                                                                    <div className="comments-history__item--date" ref={isLastItem ? endOfLogRef : null}>{`Fait le ${moment(new Date(log.date)).format('DD/MM/YYYY')} à ${moment(new Date(log.date)).format('HH:mm')} par ${log.user.firstname} ${log.user.lastname}`}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            :   
                                                <div className="no-data">
                                                    <NoDataCuate />
                                                    <p>Aucun log</p>
                                                </div>
                                        }
                                        </ContainerLoad>

                                    </div>
                                    <div className="status-entry">
                                        <div 
                                            className="status-entry__chooseStatus"
                                            onMouseEnter={() => setEvents({...events, hover : 'chooseStatus'})}
                                            onMouseLeave={() => setEvents({...events, hover : null})}
                                        >
                                            <div 
                                                style={{backgroundColor : current_status?.color}}
                                                className="bubble"
                                            ></div>
                                            <p className="title">{current_status?.title}</p>
                                            {
                                                events.hover === 'chooseStatus' ?
                                                //true ?
                                                    <motion.div 
                                                        initial={{opacity : 0, y : 50}}
                                                        animate={{opacity : 1, y : 0}}
                                                        exit={{opacity : 0, y : 50}}
                                                        className="listing"
                                                    >

                                                    {
                                                        paymentStatuses.filter(el => el.name !== newStatus.name).map((status, index) => {
                                                            return (
                                                                <motion.div 
                                                                    whileHover={{scale : 1.02}}
                                                                    whileTap={{scale : 0.98}}
                                                                    onClick = {() => {
                                                                        setEvents({...events, hover : null})
                                                                        setNewStatus((state:any) => {return{...state, name : status.name}})
                                                                    }}
                                                                    className="listing__item" 
                                                                    key={index}
                                                                >
                                                                    <div 
                                                                        className="bubble"
                                                                        style={{backgroundColor : status.color}}
                                                                    ></div>
                                                                    <p className="title">{status.title}</p>

                                                                </motion.div>
                                                            )
                                                        })
                                                    }
                                                    </motion.div>

                                                : 
                                                    null
                                            }

                                        </div>
                                        <div className="status-entry__comment">
                                            <textarea
                                                value={newStatus.content}
                                                onChange={(e) => setNewStatus((state:any) => {return{...state, content : e.target.value}})}
                                                placeholder="Tapez ici...."
                                            >

                                            </textarea>
                                            <motion.div 
                                                whileHover={{scale : 1.1}}
                                                whileTap={{scale : 0.95}}
                                                className="button-send"
                                                onClick={() => {
                                                    var total_pay = [...paymentsLog]
                                                    total_pay = total_pay.reduce((total, payment) => total + parseFloat(`${payment.amount}`.replace('.', '') !== "" ? payment.amount : 0) , 0);
                                                    const total_inv = parseFloat(props.data.total_ttc)
                                                    var total_pay_number = parseFloat(`${total_pay}`)
                            

                                                    if(["paid-awaiting-reconciliation"].includes(newStatus.name) && total_inv !== total_pay_number) {
                                                        return setTimeout(() => {
                                                            return dispatch(set_popup_warning({
                                                                status: true,
                                                                text: "Votre facture est sur le point d'être en rapprochement bancaire. Souhaitez-vous solder votre facture ?",
                                                                handleYes: () => {
                                                                    setNewStatus((state:PaymentStatus) => {return{...state, name : "paid-awaiting-reconciliation"}})
                                                                    setManager((state:PaymentDetailManager) => {return{...state, full_pay_popup : {status : true, mode : "payment"}}})
                                                                    setNewPayment((state:any) => {
                                                                        return {
                                                                            ...state, 
                                                                            id : v4(), 
                                                                            amount : restToPay
                                                                        }
                                                                    })
                                                                },
                                                                handleNo : () => handleSubmitPayment()
                                                            }));
                                                        }, 0); // Délai de 2000 millisecondes (2 secondes)
                                                    }
                                                    return handleSubmitComment()
                                                }}
                                            >
                                                <Icons name = "paper-plane-top" mode = "duotone" color = "white" />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <div className="payments-history">
                                        <ContainerLoad load = {manager.status_payments_load}>
                                        {
                                            paymentsLog.length > 0 ? 
                                                <Table {...table_config}/>
                                            :   
                                                <div className="no-data">
                                                    <BankNotePana />
                                                    <p>Aucun paiement</p>
                                                </div>
                                        }
                                        </ContainerLoad>
                                    </div>
                                    {
                                        parseFloat(restToPay) !==0 ? 
                                            <motion.div className="payments-entry">
                                                <DatePicker
                                                    selected={new Date(newPayment.date)} 
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange = {(e:any) => setNewPayment((state:any) => {return{...state, date : e}})}
                                                    locale="fr"   
                                                />
                                                <input 
                                                    type="text"
                                                    className="input-amount"
                                                    value={newPayment.amount}
                                                    onChange={(e) => {
                                                        setNewPayment((state:any) => {
                                                            //REGEX FOR DOUBLE TYPE
                                                            const value = e.target.value.replace(',', '.') 
                                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                                            if(!regex.test(value)) {
                                                                return state
                                                            }
                                                            return {
                                                                ...state, 
                                                                amount : value
                                                            }
                                                        })
                                                    }}
                                                    onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => {
                                                        //props.data.total_ttc
                                                        var total_pay = [...paymentsLog, newPayment]
                                                        total_pay = total_pay.reduce((total, payment) => total + parseFloat(`${payment.amount}`.replace('.', '') !== "" ? payment.amount : 0) , 0);
                                                        const total_inv = parseFloat(props.data.total_ttc)
                                                        var total_pay_number = parseFloat(`${total_pay}`)
                                                        
                                                        if(e.key === "Enter" && !e.shiftKey) {
                                                            if(total_pay_number > total_inv) {
                    
                                                    
                                                                dispatch(addRequestStatus({
                                                                    status : false, 
                                                                    message : "Le montant total des paiements ne peut pas être supérieur au montant total de la facture."
                                                                }))
                                                                return
                                                            }

                                                            if(total_pay_number === total_inv) {
                                                                return setTimeout(() => {
                                                                    return dispatch(set_popup_warning({
                                                                        status: true,
                                                                        text: "Votre facture est sur le point d'être soldée. Voulez-vous la basculer en attente de rapprochement bancaire ?",
                                                                        handleYes: () => {
                                                                            setNewStatus((state:PaymentStatus) => {return{...state, name : "paid-awaiting-reconciliation"}})
                                                                            setManager((state:PaymentDetailManager) => {return{...state, full_pay_popup : {status : true, mode : "comment"}}})
                                                                        },
                                                                        handleNo : () => handleSubmitPayment()
                                                                    }));
                                                                }, 0); // Délai de 2000 millisecondes (2 secondes)
                                                            }

                                                            if(newPayment.amount.length === 0) {
                                                                return dispatch(addRequestStatus({
                                                                    status : false, 
                                                                    message : "Veuillez entrer un montant valide"
                                                                }))
                                                            }
                                                            return handleSubmitPayment()
                                                        }
                                                    }}
                                                    placeholder="Montant"
                                                />
                                                <p>€</p>

                                                {
                                                    newPayment.amount.trim().length === 0 ?
                                                        null
                                                    : 
                                                    <motion.div 
                                                        exit={{opacity : 0, x : -50}}
                                                        initial={{opacity : 0, x : -50}}
                                                        animate={{opacity : 1, x : 0}}
                                                        className="button-send"
                                                        onClick={() => {
                                                            var total_pay = [...paymentsLog, newPayment]
                                                            total_pay = total_pay.reduce((total, payment) => total + parseFloat(`${payment.amount}`.replace('.', '') !== "" ? payment.amount : 0) , 0);
                                                            const total_inv = parseFloat(props.data.total_ttc)
                                                            var total_pay_number = parseFloat(`${total_pay}`)

                                                            if(total_pay_number > total_inv) {
                                                                dispatch(addRequestStatus({
                                                                    status : false, 
                                                                    message : "Le montant total des paiements ne peut pas être supérieur au montant total de la facture."
                                                                }))
                                                                return
                                                            }

                                                            if(total_pay_number === total_inv) {
                                                                return setTimeout(() => {
                                                                    return dispatch(set_popup_warning({
                                                                        status: true,
                                                                        text: "Votre facture est sur le point d'être soldée. Voulez-vous la basculer en attente de rapprochement bancaire ?",
                                                                        handleYes: () => {
                                                                            setNewStatus((state:PaymentStatus) => {return{...state, name : "paid-awaiting-reconciliation"}})
                                                                            setManager((state:PaymentDetailManager) => {return{...state, full_pay_popup : {status : true, mode : "comment"}}})
                                                                        },
                                                                        handleNo : () => handleSubmitPayment()
                                                                    }));
                                                                }, 0); // Délai de 2000 millisecondes (2 secondes)
                                                            }

                                                            if(newPayment.amount.length === 0) {
                                                                return dispatch(addRequestStatus({
                                                                    status : false, 
                                                                    message : "Veuillez entrer un montant valide"
                                                                }))
                                                            }

                                                            return handleSubmitPayment()
                                                        }}
                                                    >
                                                        <Icons name = "paper-plane-top" mode = "duotone" color = "white" />
                                                    </motion.div>
                                                }


                                                
                                            </motion.div>
                                        : null
                                    }
                                </div>

                        }

                    </div>
                </div>
            </BasePopup>
        </React.Fragment>
    )
}