import React from 'react';
import MiniLoad from './MiniLoad';
export type ContainerLoadType = {
    load : boolean, 
    children : React.ReactNode
}

export default function ContainerLoad(props:ContainerLoadType) {
    if(props.load) {
        return <MiniLoad />

    } else {
        return props.children as JSX.Element
    }
}