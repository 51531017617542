import React from "react";
import { StringUtils, Table } from "tc-minibox";
import { DataType, TableForProps } from "tc-minibox/dist/types/table/index.table";
import { icons_config } from "../../../config/context.config";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../../store/global/actions.global";
import { NewAffairManager } from "../NewAffair";
import { ServerProps } from "../../../config/server.config";
import Totals from "../../base/Totals";
import { generate_totals } from "../../affairs/utils.affairs";
import { DescriptionCustom, PricingCustom, QuantityCustom, TotalHTCustom, UnitCustom } from "../../base/tcminibox.utils";
import ListingCategory from "../../base/ListingCategory";



export interface ProcessingProps extends ServerProps{
    setManager : React.Dispatch<React.SetStateAction<NewAffairManager>>,
    data : DataType, 
    setData : React.Dispatch<React.SetStateAction<DataType>>,
    handleClearStates : () => void
    id_affair : number|string
}

export interface ProcessingManager {
    category : string | null, 
    subCategory : string | null
}


export default function Processing(props:ProcessingProps) {
    // * GLOBAL VARIABLES
    const dispatch = useDispatch()



    // * STATES
    const [manager, setManager] = React.useState<ProcessingManager>({
        category : null,
        subCategory : null
    })

    // * VARIABLES
    const tbody = props.data.map((el:any) => {
        return {
            ...el, 
            total_ht : (parseFloat(el.unit_price) * parseFloat(el.quantity)).toFixed(2)
        }
    })
    // * FUNCTIONS
    const handleReturn = () => {
        dispatch(setLoaderStatus({status : true, message : "Suppression de l'affaire initialisée... Veuillez-patienter"}))
        props.server.post('/affairs/definitive-delete', [props.id_affair])
        .then(res => {
            dispatch(setLoaderStatus({status : false, message : ""}))
            return props.handleClearStates()
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false, message : ""}))
            dispatch(addRequestStatus(err.response.data))
        })
    }

    const handleAutoSave = async (data_changed:any) => {
        dispatch(addRequestStatus({status : null, message : "Enregistrement des lignes modifiées..."
        }))

        props.server.post('/affairs/autosave-costestimate-line', data_changed)
        .then(res => {
            const tokens_lines = data_changed.map((el:any) => el.token_line)
            dispatch(addRequestStatus({status : true, message : "Modifications effectuées."}))

            props.setData(state => {
                return state.map(el => {
                    if(tokens_lines.includes(el.token_line)) {
                        return data_changed.find((sub:any) => sub.token_line === el.token_line)
                    }
                    return el
                })
            })
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }




    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 50, 
        editing : true, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Désignation", 
                    name : "description", 
                    custom : (tr, td) => <DescriptionCustom status = "normal" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>
                }, 
                custom_edit : (tr, td) => <DescriptionCustom status = "edit" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>,
                style : {
                    width : `calc(100% - ${100 * 4}px - 450px)`,
                }
            }, 
            {
                info : {
                    text : "Catégorie", 
                    name : "category", 
                }, 
                style : {
                    width : "250px",
                    tbody : {
                        textAlign : "justify"
                    }
                }
            }, 
            {
                info : {
                    text : "Sous-catégorie", 
                    name : "sub_category", 
                }, 
                style : {
                    width : "200px",
                }
            }, 
            {
                info : {
                    text : "Unité", 
                    name : "unit", 
                    custom : (tr, td) => <UnitCustom status = "normal" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>
                }, 
                custom_edit : (tr, td) => <UnitCustom status = "edit" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>,
                style : {
                    width : `100px`, 
                }
            }, 
            {
                info : {
                    text : "Quantité", 
                    name : "quantity", 
                }, 
                custom_edit : (tr, td) => <QuantityCustom status = "edit" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>,
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "P.U.", 
                    name : "unit_price", 
                    custom : (tr, td) => <PricingCustom name = "unit_price" status = "normal" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>
                }, 
                custom_edit : (tr, td) => <PricingCustom name = "unit_price" status = "edit" tr = {tr} td = {td} data = {props.data} setData = {props.setData}/>, 

                style : {
                    width : `100px`, 
                }
            }, 
            {
                info : {
                    text : "Total H.T", 
                    name : "total_ht", 
                    custom : (tr, td) => <TotalHTCustom status = "normal" tr = {tr} td = {td}/>
                }, 
                custom_edit : (tr, td) => <TotalHTCustom status = "normal" tr = {tr} td = {td} />, 
                style : {
                    width : `100px`, 
                }
            }, 
        ], 
        data : tbody,
        setData : props.setData,
        header : {
            buttons : {
                include : ["return", "save"], 
                actions : {
                    //add : () => navigate('/new')
                    return : () => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir quitter ?", 
                            handleYes : () => handleReturn()
                        }))
                    }, 
                    save : () => props.setManager(state => {return{...state, mode : "completion"}})
                }
            }, 
            custom : (data:any) => {
                return (
                    <div>
                        <ListingCategory 
                            data = {props.data}
                            manager = {manager}
                            setManager = {setManager}
                        />
                    </div>
                )
            }, 
        }, 
        filter_custom : (data:any) => {
            if(manager.category !== null) {
                return data.map((el:any) => {
                    if(manager.category !== null) {
                        if(StringUtils.undress_string(el[2]) === StringUtils.undress_string(manager.category)) {
                            if(manager.subCategory !== null) {
                                if(StringUtils.undress_string(el[3]) === StringUtils.undress_string(manager.subCategory)) {
                                    return el
                                }
                                return null
                            }
                            return el
                        }
                        return null
                    }
                    return el
                }).filter((el:any) => el !== null)
            }
            return data

        }, 
        autosave : {
            delay : 500, 
            action : (data) => handleAutoSave(data)
        }, 

    }
    
    return (
        <div className = "new-affair--processing">
            <Totals
                {...generate_totals(props.data.map(ld => parseFloat(ld.total_ht)).reduce((acc, curr) => acc + curr, 0))}
            />
            <Table {...table_config} />
        </div>
    )
}