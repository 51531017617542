/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ServerProps } from "../../../../../../config/server.config";
import BasePopup from "../../../../../../tools/components/general/BasePopup";
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import {Icons, Table} from "tc-minibox";
import SearchSelect from "../../../../../../tools/components/general/SearchSelect";
import ContainerLoad from "../../../../../../tools/components/general/ContainerLoad";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { addRequestStatus, set_popup_warning } from "../../../../../../store/global/actions.global";


interface EditInvAffairsProps extends ServerProps {
    handleClose : () => void,
    selected_data : any,
    setData : React.Dispatch<React.SetStateAction<any[]>>,
    handleChangeInput : any,
}

interface EditInvAffairsManager {
    load : boolean
}

export default function EditInvAffairs(props:EditInvAffairsProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()

    //* USE STATE
    const [affairs, setAffairs] = useState<any[]>(props.selected_data.id_affairs.map((af:any) => {return{...af, uuid : v4()}}))
    const [affairsInfo, setAffairsInfo] = useState<any[]>([])
    const [manager, setManager] = useState<EditInvAffairsManager>({
        load : true
    })

    //* VARIABLES
    const tbody = affairs.map((af:any, af_index:number) => {
        const total_tva_ids = affairs.filter((_, index:number) => index !== 0).map(af => af.tva.length === 0 ? 0 : parseFloat(af.tva)).reduce((a, b) => a + b, 0)
        const total_ht_ids = affairs.filter((_, index:number) => index !== 0).map(af => af.ht.length === 0 ? 0 : parseFloat(af.ht)).reduce((a, b) => a + b, 0)
        return {
            ...af, 
            ht : af_index === 0 ? (props.selected_data.total_ttc - props.selected_data.total_tva - total_ht_ids).toFixed(2) : af.ht,
            tva : af_index === 0 ? (props.selected_data.total_tva - total_tva_ids).toFixed(2) : af.tva,
            ttc : parseFloat(af.ht.length === 0 ? "0" : af.ht) + parseFloat(af.tva.length === 0 ? "0" : af.tva)
        }
    })

    const handleAdd = () => {
        setManager(state => {return{...state, load : false}})
        setAffairs((state) => {
            return [...state, {
                uuid : v4(),
                id : null, 
                description : "", 
                ht : "", 
                tva : "", 
                ttc : ""
            }]
        })

    }

    const handleDelete = (data:any) => {
        setManager(state => {return{...state, load : false}})
        setAffairs((state:any) => {
            return state.filter((aff:any) => !data.map((el:any) => el.uuid).includes(aff.uuid) )
        })
    }

    const handleTestValidity = () => {
        const data = affairs.map(aff => {
            const ht = parseFloat(`${aff.ht}`.trim().length === 0 ? "0" : aff.ht)
            const tva = parseFloat(`${aff.tva}`.trim().length === 0 ? "0" : aff.tva)
            return { 
                ...aff, 
                ht,
                tva,
                ttc : ht + tva
            }
        })

        // * TEST IF ALL HT ARE EQUAL TO ZERO
        const test_ht = data.filter(aff => aff.ht === 0).length > 0
        if(test_ht) {
            return dispatch(addRequestStatus({status : false, message : "Veuillez renseigner au moins les montants HT."}))
        }
        
        // * TEST IF THE FIRST AFFAIR (HT AND TVA) ARE NOT NEGATIVE IN REGARD OF THE SUM OF THE OTHERS

        var total_ht = data.filter((_, index:number) => index !== 0).map(aff => aff.ht).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = data.filter((_, index:number) => index !== 0).map(aff => aff.tva).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var test_total_ht = parseFloat(data[0].ht) < total_ht
        var test_total_tva = parseFloat(data[0].tva) < total_tva

        if(props.selected_data.type === "credit-note") {
            total_ht = data.filter((_, index) => index !== 0).map(aff => aff.ht).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            total_tva = data.filter((_, index) => index !== 0).map(aff => aff.tva).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

            test_total_ht = parseFloat(data[0].ht) > total_ht;
            test_total_tva = parseFloat(data[0].tva) > total_tva;
        }

        if(test_total_ht) {
            return dispatch(addRequestStatus({status : false, message : "Le montant HT de la première affaire ne peut être inférieur à la somme des autres montants HT."}))
        }

        if(test_total_tva) {
            return dispatch(addRequestStatus({status : false, message : "Le montant TVA de la première affaire ne peut être inférieur à la somme des autres montants TVA."}))
        }

        props.handleClose()
        
        return props.handleChangeInput({target : {name : "id_affairs", value : data}})
    }

    // * USE EFFECTS
    useEffect(() => {
        const affairsId = affairs.map(af => af.id)
        const affairsInfoIds = affairsInfo.map(af => af.id)
        const diff = affairsId.filter(id => !affairsInfoIds.includes(id)).filter(id => id !== null)

        if(diff.length > 0) {
            props.server.post("/invoicing/editInvAffairs/retrieve-affairs-info", {ids : diff}).then((res:any) => {
                setAffairsInfo((state:any) => {
                    return [...state, ...res.data.data]
                })
            })
        }

    }, [affairs])

    useEffect(() => {
        if(!manager.load) {
            const timer = setTimeout(() => {
                setManager(state => {return{...state, load : true}})
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [manager.load])

    //* COMPONENT CONFIG
    const table_config:TableForProps = {
        height : 35, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            // *
            {
                info : {
                    text : "Affaire",
                    name : "id", 
                    custom : (tr:any) => {
                        const trIndex = affairs.findIndex(af => af.uuid === tr.uuid)
                        const trInfo = affairsInfo.find(af => af.id === tr.id)

                        if(trIndex === 0) {
                            return <p className="current_affair">
                                <span><Icons name = "shield-quartered" color = "white" /></span>
                                <span>{trInfo ? trInfo.name : "N/A"}</span>
                            </p>
                        } else {
                            return <SearchSelect
                            {...{
                                text_no_data : "Aucune affaire trouvée", 
                                style : {
                                    "zIndex" : 600 - (trIndex)
                                }, 
                                handleClick : (data) => setAffairs((state:any) => state.map((aff:any, aff_index:number) => aff_index === trIndex ? {...aff, id : data.id} : aff)),
                                handleDelete : () => setAffairs((state:any) => state.map((aff:any, aff_index:number) => aff_index === trIndex ? {...aff, id : null} : aff)),
                                no_data_text : "Aucune affaire trouvée", 
                                server : {
                                    axios : props.server,
                                    route : "/invoicing/editInvAffairs/search", 
                                    body : {
                                        exclude_ids : affairs.map(af => af.id).filter(id => id !== null).filter(id => id !== tr.id)
                                    }
                                },
                                value : tr.id
                            }}
                            />
                        }


                    },
                }, 
                
                style : {
                    width : "calc(100% - 300px)", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "HT",
                    name : "ht", 
                    custom : (tr:any, td:any) => {
                        const trIndex = affairs.findIndex(af => af.uuid === tr.uuid)
                        if(trIndex === 0) {
                            return <p>
                                {td}
                            </p>
                        } else {
                            return (
                                <input
                                    type = "text"
                                    value = {tr.ht}
                                    onChange = {(e) => {
                                        setAffairs((state:any) => state.map((aff:any, aff_index:number) => aff_index === trIndex ? {...aff, ht : e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.')} : aff))
                                    }}
                                />
                            )
                        }

                    }
                }, 
                
 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "TVA",
                    name : "tva", 
                    custom : (tr:any, td:any) => {
                        const trIndex = affairs.findIndex(af => af.uuid === tr.uuid)

                        if(trIndex === 0) {
                            return <p>
                            {td}
                        </p>
                        } else {
                            return (
                                <input
                                    type = "text"
                                    value = {tr.tva}
                                    onChange = {(e) => {
                                        setAffairs((state:any) => state.map((aff:any, aff_index:number) => aff_index === trIndex ? {...aff, tva : e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.')} : aff))
                                    }}
                                />
                            )
                        }

                    }
                }, 
 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "TTC",
                    name : "ttc", 
                    custom : (tr:any, td:any) => {
                        const result = parseFloat(tr.ht.length === 0 ? "0" : tr.ht) + parseFloat(tr.tva.length === 0 ? "0" : tr.tva)
                        
                        const trIndex = affairs.findIndex(af => af.uuid === tr.uuid)
                        if(trIndex === 0) {
                            return <p>
                            {result.toFixed(2)}
                        </p>
                        } else {
                            return <p>
                                {result.toFixed(2)}
                            </p>
                        }

                    }
                }, 
 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
 
            

            

        ], 
        data : tbody,
        setData : setAffairs,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => handleAdd(), 
                    ...affairs.slice(1).length === 0 ? {} : {
                        delete : (data:any) => {
                            if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une affaire."}))
                            return dispatch(set_popup_warning({
                                status : true, 
                                text : "Êtes-vous sûr de vouloir supprimer ces affaires ?", 
                                handleYes : () => handleDelete(data)
                            }))
                        }
                    }, 
                    delete : (data:any) => {
                        if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une affaire."}))
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces affaires ?", 
                            handleYes : () => handleDelete(data)
                        }))
                    }
                    /* delete : (data:any) => {
                        if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une affaire."}))
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces affaires ?", 
                            handleYes : () => handleDelete(data)
                        }))
                    }*/
                }
            }, 
        }, 
        handleDoubleClick : (tr:any) => {
            //setManager(state => {return{...state, mode : "detail", selected_data : tr.id_affair}})
        }
    }

    return (
        <BasePopup nameClass = "edit-inv-affairs" handleClose={handleTestValidity}>
            <div className="edit-inv-affairs--preview">
                <iframe src={`${props.selected_data.url}`} title="Web Page Viewer" className="iframe-container"/>
            </div>
            <div className="edit-inv-affairs--editor">
                <ContainerLoad load = {!manager.load}>
                    <Table {...table_config} />
                </ContainerLoad>

            </div>

        </BasePopup>
    )
}