import { motion } from 'framer-motion';
import { Icons } from 'tc-minibox';
import { icons_config } from '../../../config/context.config';


export default function MiniLoad() {

    return (
        <motion.div 
            exit = {{ opacity : 0, scale : 0}}
            animate = {{opacity : 1, scale : 1, transition : {delay : 0.5}}}
            initial = {{opacity : 0, scale : 0}}
            className='mini-load'
        >
           <Icons name = "spinner"  {...icons_config} />
        </motion.div>
    )
}
