import {ReactComponent as Logo} from '../assets/svg/logo.svg'
import logo_min from '../assets/images/logo_minified.png'

// * COMPAGNY
export const compagny = {
    logo : <Logo />, 
    logo_min : logo_min
}

// * ICONS
export const icons_config = {
    mode : "duotone", 
    color : "#116633" 
}

// * ACCESS TYPE
export const access_type = [
    {
        id : "AccessType-01", 
        name : "neant", 
        text : "Aucun accès"
    }, 
    {
        id : "AccessType-02", 
        name : "lecture", 
        text : "Lecture"
    }, 
    {
        id : "AccessType-03", 
        name : "ecriture", 
        text : "Écriture"
    }, 
]

export const users_group = [
    {
        id : "UG-01", 
        name : "administration", 
    }, 
    {
        id : "UG-02", 
        name : "technicien", 
    }
]