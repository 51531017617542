
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react' ; 
import {ReactComponent as CircleInfo} from '../../../../assets/svg/icons/circle-info-duotone.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment' ; 
import { motion } from 'framer-motion';
import { account_user } from '../../../../store/account/selector.account';
import { ServerProps, server_host } from '../../../../config/server.config';
import { addRequestStatus, setLoaderStatus, set_popup_warning } from '../../../../store/global/actions.global';
import BasePopup from '../../../../tools/components/general/BasePopup';
import { Icons, Table } from 'tc-minibox';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import MainButton from '../../../../tools/components/general/MainButton';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import PopupUpdateInv from './update/PopupUpdateInv';
import Reassignment from './reassignment/Reassignment';
import { type_payments } from '../../../suppliers/utils.suppliers';
import { credit_note_style } from '../suppliersinv.utils';
import MemoryAffairAid from './memory_aid/MemoryAffairAid';
import ContainerLoad from '../../../../tools/components/general/ContainerLoad';


export interface ListingProps extends ServerProps {
    data : DataType,
    setData : React.SetStateAction<any>, 
    handleAdd : () => void, 
    setManager : React.SetStateAction<any>
}

export interface ListingInvManager {
    initialization : boolean, 
    preview : {
        status : boolean, 
        content : any
    }, 
    comment : {
        status : boolean, 
        mode : null | "refused" | "validated" | "sent" | "pending", 
        selected_data : string[]
    }, 
    refused : {
        status : boolean, 
        content : any
    }, 
    reassign : {
        status : boolean,
        selected_data : any
    }, 
    memory_aid : boolean, 
    status : string, 
    message : string | null, 
    filter : string, 
    selected_data : any, 
    business_manager_mode : boolean, 

}

export const validation_config = [
    {
        id : "VALID-CONFIG-NULL", 
        status : "pending", 
        text : "En attente", 
        style : {
            color : "#d28801", 
            backgroundColor : "#ffa50036"
        }
    }, 
    {
        id : "VALID-CONFIG-TRUE", 
        status : "validated", 
        text : "Validé", 
        color : "green", 
        style : {
            color : "green", 
            backgroundColor : "rgb(98 255 64 / 16%)"
        }
    }, 
    {
        id : "VALID-CONFIG-FALSE", 
        status : "refused", 
        text : "Refusé", 
        style : {
            color : "red", 
            backgroundColor : "#ff000014"
        }
    }, 

]



//const handleChangeObjectState = (setState:any, name:any, val:any) => setState((state:any) => {return{...state, [name] : val}})
/*const sortInvoices_validation = (data:any) => {
    return {
        to_validate : data.filter((dt:any) => !dt.validation.status), 
        valid : data.filter((dt:any) => dt.validation.status), 
        preview : null
    }*/


export default function ListingInv(props:ListingProps) {
    //* GLOBAL
    const dispatch = useDispatch()

    // * STATES
    const user_account = useSelector(account_user)
    const [manager, setManager] = useState<ListingInvManager>({
        initialization : false, 
        comment : {
            status : false, 
            mode : null, 
            selected_data : []
        }, 
        preview : {
            status : false,
            content : null
        }, 
        refused : {
            status : false, 
            content : {}
        }, 
        reassign : {
            status : false,
            selected_data : []
        },
        memory_aid : false, 
        status : "pending", 
        message : null, 
        filter : "all", 
        selected_data : null, 
        business_manager_mode : false, 
    })
    /*const [events, setEvents] = useState({
        active : "TO-VALIDATE"
    })*/

    // * CONFIG VARIABLES
    


    const filter_config = [
        {
            id : "FILTER-CONFIG-ALL",
            text : "Tous",
            name : "all",
            status : "all"
        }, 
        {
            id : "FILTER-CONFIG-TO-VALIDATE",
            text : "En attente",
            name : "to_validate", 
            status : "pending"
        },
        {
            id : "FILTER-CONFIG-VALID",
            text : "Validés",
            name : "valid", 
            status : "validated"
        },
        {
            id : "FILTER-CONFIG-REFUSED",
            text : "Refusés",
            name : "refused",
            status : "refused"
        },
        {
            id : "FILTER-CONFIG-SENT",
            text : "Envoyés",
            name : "sent",
            status : "sent"
        },
    ]

    const tbody:DataType = props.data.map(inv => {
        const last_comment_log = inv.comments_log.length === 0 ? null : inv.comments_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]

        
        return {
            ...inv, 
            tc_affairs_numbers :  inv.id_affairs.map((aff:any) => aff.id.affair_number).join(inv.id_affairs.length === 1 ? '' : ' ; '), 
            tc_supplier : inv.id_supplier.name, 
            tc_due_date : inv.dates.due_date,
            tc_receipt_date : inv.dates.receipt_date,
            tc_inv_date : inv.dates.inv_date,
            tc_total_ht : `${(inv.total_ttc - inv.total_tva).toFixed(2)} €`,
            tc_total_tva : `${inv.total_tva.toFixed(2)} €`,
            tc_total_ttc : `${inv.total_ttc.toFixed(2)} €`,
            //${paymentStatuses.find((ps:any) => ps.name === last_comment_log.name)?.title}
            tc_payments_mode : last_comment_log === null ? `${type_payments.find((tp:any) => tp.name === inv.id_supplier.payment_choice)?.text} (Par défaut)` : `${last_comment_log.content.length > 0 ? last_comment_log.content : "Aucun commentaire"}`, 
            tc_designations : ""
        }
    })

    // * FUNCTIONS
    const filtering_data = () => {
        var filtered_data = props.data
        if(manager.business_manager_mode) {
            filtered_data = filtered_data.filter((dt:any) => dt.id_affairs.map((aff:any)=> aff.id.manager).includes(user_account.infos._id) || dt.id_affairs.map((aff:any)=> aff.id.affair_number).includes("000"))
        }
        if(manager.filter === "all") {
            return filtered_data
        }

        if(manager.filter === "sent") {
            return filtered_data.filter((dt:any) => dt.status_log.find((st:any) => st.status === "sent"))
        } 
        
        return filtered_data.filter((dt:any) => dt.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].status === manager.filter)
    }

    const handleChangeStatus = (message : string) => {
        const formContainer = {
            status : manager.comment.mode, 
            user : user_account.infos._id, 
            date : new Date(), 
            comment : message, 
            selected_data : manager.comment.selected_data
        }

        return props.server.post('/invoicing/manage-inv-status', formContainer)
        .then(res => {
            dispatch(addRequestStatus(res.data))

            props.setData((state:any) => {
                return state.map((inv:any) => {
                    if(manager.comment.selected_data.includes(inv._id)) {
                        return {
                            ...inv, 
                            status_log : [...inv.status_log, {
                                status : manager.comment.mode, 
                                comment : message, 
                                date : new Date(), 
                                user : user_account.infos
                            }]
                        }
                    }
                    return inv
                })
            })

            setManager(state => {
                return {
                ...state, 
                comment : {status : false, mode : null, selected_data : []}
            }})
            //props.setManager((state:any) => {return {...state, initialize : false}})
            
            
            
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            setManager((state:any) => {return {...state, load : false}})
        })
        /*
        
        
        
        
        const selected_inv = selected_data.map((sd:any) => props.data[sd])

        if(selected_inv.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Les factures sélectionnés ont déjà ce statut."}))
        }

        props.setData(props.data.map(inv => {
            if(selected_inv.map((s_inv:any) => s_inv.id_inv).includes(inv.id_inv)) {
                return {...inv, validation : {...inv.validation, promise : "pending"}}
            }
            return inv
        }))

        return props.server.post('/invoicing/manage-inv-status', {selected_data : selected_inv, new_status : new_status, date : current_date, id_user : user_account.infos.id_user, message : message})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            props.setData(props.data.map(inv => {
                if(selected_inv.map((s_inv:any)=> s_inv.id_inv).includes(inv.id_inv)) {
                    return {...inv, validation : { id_user : user_account.infos.id_user ,date : current_date, status : new_status, promise : "resolve", message : message}}
                }
                return inv
            }))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })*/
    }

    const handleDelete = (selected_data:any) => {
        if(selected_data.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Aucune facture sélectionnée."}))
        }

        dispatch(setLoaderStatus({status : true}))
        return props.server.post('/invoicing/delete-inv', {id_inv : selected_data.map((el:any) => el._id)})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false}))
            props.setData((state:any) => {
                return state.filter((inv:any) => !selected_data.map((sd:any) => sd._id).includes(inv._id))
            })

            //props.setManager((state:any)=> {return{...state, initialize : false}})
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })

    }

    const handleExport = async (selected_data:any, mode:string, attachements:boolean) => {
        return props.server.post('/payments/export', {
            selected_data : selected_data,
            mode : mode, 
            attachements : attachements
        })
        .then(res => {
            //dispatch(addRequestStatus(res.data))
            const link = document.createElement('a');
            link.href = server_host(`${res.data.data}?token=${user_account.infos.token_doc}`);
            link.download = attachements ? 'Suivi facturation.zip' : "Suivi facturation.xlsx"; // Nom du fichier à télécharger
            link.target = '_blank'; // O
              // Ajouter le lien au DOM et le déclencher
                document.body.appendChild(link);
                link.click();

                // Supprimer le lien du DOM une fois le téléchargement terminé
                document.body.removeChild(link);

            dispatch(addRequestStatus(res.data))
            
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
        /*
        try {
            const res = await props.server.post('/invoicing/export', { selected_data: selected_inv, date: current_date, id_user: user_account.infos.id_user });
            dispatch(addRequestStatus(res.data));

            const link = document.createElement('a');
            link.href = server_host(res.data.data);
            link.download = 'Factures.zip'; // Nom du fichier à télécharger
            link.target = '_blank'; // O


            // Ajouter le lien au DOM et le déclencher
            document.body.appendChild(link);
            link.click();

            // Supprimer le lien du DOM une fois le téléchargement terminé
            document.body.removeChild(link);

            dispatch(setLoaderStatus({ status: false }));
        } catch (err:any) {
            dispatch(setLoaderStatus({ status: false }));
            dispatch(addRequestStatus(err.response.data));
        }*/
    }

    const handleReassign = (selected_data:any) => {
        const checking_multiple_affairs = selected_data.map((sd:any) => sd.id_affairs.length > 1).includes(true)
        if(checking_multiple_affairs) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Impossible de réaffecter une affaire à une facture qui en possède plusieurs. Veuillez modifier les affaires de ces factures avant de les réaffecter."}))
        }
        return setManager(state => {return {...state, reassign : {status : true, selected_data : selected_data}}})
    }   

    const element_sizes = {
        status : 120, 
        doc_number : 110,
        supplier : 250, 
        inv_number : 150, 
        inv_date : 100,
        reception_date : 100,
        payment_date : 110,
        total_ht : 90,
        total_tva : 90,
        total_ttc : 90,
        payment_mode : 300,
        designation : 300,
        codes : 100
    }
    const sum_sizes = Object.values(element_sizes).reduce((a:number, b:number) => a + b, 0)

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            {
                info : {
                    text : "Statut", 
                    name : "inv_status", 
                    custom : (tr:any, _) => {
                        const sorted_status_log = tr.status_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        const last_status = sorted_status_log.filter((st:any) => st.status !== "sent")[0]
                        const config_status = validation_config.find(vc => vc.status === last_status.status)

                        

     
                        return (
                            <Tippy
                            content={(
                                <div 
                                    className='status_validation--tippy'
                                    style={{ maxHeight: '110px', overflowY: 'auto'}}
                                >
                                    {
                                        sorted_status_log.map((st:any, index:number) => {
                                            const st_status_config = validation_config.find(vc => vc.status === st.status)

                                            return (
                                                <div
                                                    className={`${st.status === "sent" ? "status_validation--tippy--sent" : ""}`}
                                                    key={`SORTED_LOG_${tr._id}_${index}`}
                                                >

                                                    <div
                                                        style = {st_status_config?.style ? st_status_config.style : {}}
                                                    >
                                                        {st.status === "sent" ? "Envoyé" : st_status_config?.text}
                
                                                    </div>
                                                    <p>{`${st.comment.trim().length === 0 ? "Aucun commentaire" : st.comment}`}</p>
                                                    <p>
                                                        <span>{`Posté le ${moment(new Date(st.date)).format('DD/MM/YYYY')} à ${moment(new Date(st.date)).format('HH:mm')} `}</span>
                                                        <span>{`Par ${st.user.firstname} ${st.user.lastname}`}</span>
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            )}
                            interactive={true}
                            trigger="click"
                            placement="top"
                            popperOptions={{
                                modifiers: [
                                {
                                    name: 'flip',
                                    options: {
                                    fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                    offset: [0, 10], // Décale le tooltip de 10px vers le bas
                                    },
                                },
                                ],
                          }}
                        >
                            <div
                                className={`status_validation status_validation${last_status.status === "sent" ? "--sent" : ""}`}
                                style = {config_status?.style ? config_status.style : {}}
                            >

                                {
                                    tr.status_log.find((st:any) => st.status === "sent") ?
                                        <div id='inv-status-sent'></div>
                                    : 
                                        null
                                }
                                {`${config_status?.text}`}
                                
                            </div>
                        </Tippy>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.status}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "N° pièce comptable", 
                    name : "doc_number",
                    custom : (tr:any, _) => {

                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.doc_number}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.doc_number}px`, 
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Codes", 
                    name : "tc_affairs_numbers", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_affairs_numbers}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.codes}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "N° de facture", 
                    name : "inv_number", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.inv_number}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.inv_number}px`, 
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }
                }
            }, 
            {  
                info : {
                    text : "Fournisseur", 
                    name : "tc_supplier", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_supplier}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.supplier}px`, 
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de la facture", 
                    name : "tc_inv_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {moment(tr.tc_inv_date).format('DD/MM/YYYY')}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.inv_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de réception", 
                    name : "tc_receipt_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {moment(tr.tc_receipt_date).format('DD/MM/YYYY')}
                            </p>
                        )
                    } 
                    
                }, 
                style : {
                    width : `${element_sizes.reception_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date de règlement", 
                    name : "tc_due_date", 
                    type : "date", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {
                                    tr.is_invoice_on_receipt ? 
                                        "À réception de la facture"
                                    : 
                                        moment(tr.tc_due_date).format('DD/MM/YYYY')
                                }
                            </p>
                        )
                    } 
                }, 
                style : {
                    width :`${element_sizes.payment_date}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "H.T", 
                    name : "tc_total_ht", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_ht}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_ht}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TVA", 
                    name : "tc_total_tva", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_tva}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_tva}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TTC", 
                    name : "tc_total_ttc", 
                    custom : (tr:any, _) => {
                        return (
                            <p style = {tr.type === "credit-note" ? credit_note_style : {}} >
                                {tr.tc_total_ttc}
                            </p>
                        )
                    } 
                }, 
                style : {
                    width : `${element_sizes.total_ttc}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "Désignation", 
                    name : "comment", 
                    custom : (tr:any, _) => {
                        // tc_payments_mode
                        return (
                            <div className='tc_comment'>
                                <Tippy
                                    content={(
                                        <div>{tr.comment}</div>
                                    )}
                                    interactive={true}
                                    trigger="click"
                                    placement="bottom"
                                    popperOptions={{
                                        modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                            fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                            offset: [0, 10], // Décale le tooltip de 10px vers le bas
                                            },
                                        },
                                        ],
                                    }}
                                >
                                    <p style = {tr.type === "credit-note" ? credit_note_style : {}}>{tr.comment}</p>

                                </Tippy>
                            </div>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.designation}px`,
                    tbody : {
                        justifyContent : "start", 
                        textAlign : "justify"
                    }, 

                }
            }, 
            {
                info : {
                    text : "Mode de règlement", 
                    name : "tc_payments_mode", 
                    custom : (tr:any, _) => {
                        // tc_payments_mode
                        return (
                            <div className='tc_payments_mode'>
                                <Tippy
                                    content={(
                                        <div>{tr.tc_payments_mode}</div>
                                    )}
                                    interactive={true}
                                    trigger="click"
                                    placement="bottom"
                                    popperOptions={{
                                        modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                            fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                            offset: [0, 10], // Décale le tooltip de 10px vers le bas
                                            },
                                        },
                                        ],
                                    }}
                                >
                                    <p style = {tr.type === "credit-note" ? credit_note_style : {}}>{tr.tc_payments_mode}</p>

                                </Tippy>
                            </div>
                        )
                    }
                }, 
                style : {
                    width : `${element_sizes.payment_mode}px`,
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "start"
                    }
                }
            }, 


        ], 
        filter_custom : (data:any) => {
            var filtered_data = data


            if(manager.business_manager_mode) {
                filtered_data = filtered_data.filter((dt:any) => props.data[dt[0]].id_affairs.map((aff:any)=> aff.id.manager).includes(user_account.infos._id) || props.data[dt[0]].id_affairs.map((aff:any)=> aff.id.analytic_code).includes("000"))
            }
            if(manager.filter === "all") {
                return filtered_data
            }

            if(manager.filter === "sent") {
                return filtered_data.filter((dt:any) => props.data[dt[0]].status_log.find((st:any) => st.status === "sent"))
            } 
            
            return filtered_data.filter((dt:any) => props.data[dt[0]].status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].status === manager.filter)
            


        }
        , 
        data : tbody,
        setData : props.setData,
        header : {
            buttons : {
                include : ["delete", "add"], 
                actions : {
                    add : () => props.handleAdd(), 
                    delete : (selected_data:any) =>{
                        var get_info_selected_data = selected_data
                        get_info_selected_data = get_info_selected_data.map((st:any) => {
                            return {
                                ...st, 
                                last_status : st.status_log.sort((a:any, b:any) => b.date - a.date)[0]
                            }
                        })

                        if(get_info_selected_data.map((inv:any) => inv.last_status.status).includes("sent")) {
                            return dispatch(addRequestStatus({status : false, message : "Impossible de supprimer une facture envoyée au comptable."}))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces factures ?", 
                            handleYes : () => handleDelete(selected_data)
                        }))
                    }
                }
            }, 
            custom : (selected_data:any) => {
                var get_info_selected_data = selected_data.map((sd:any) => props.data[sd])
                get_info_selected_data = get_info_selected_data.map((st:any) => {
                    return {
                        ...st, 
                        last_status : st.status_log.sort((a:any, b:any) => b.date - a.date)[0]
                    }
                })

                return (
                    <div className="invoice-detail-header"> 
                        <div className='buttons'>
                            {
                                user_account.infos.user_group === "direction" && selected_data.length > 0 ?
                                    <React.Fragment>
                                        <div className='buttons__actions'>
                                            <div className='buttons__actions--text'>
                                                Actions
                                            </div>
                                            <div className='buttons__actions--list'>
                                                <React.Fragment>
                                                    <motion.button
                                                        whileTap={{scale : 0.95}}
                                                        whileHover={{scale : 1.05}}
                                                        onClick = {() => {
                                                            setManager(state => {return {...state, comment : {status : true, mode : "pending", selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})
                                                        }}
                                                        className='pending'
                                                    >
                                                        En attente
                                                    </motion.button>
                                                    <motion.button
                                                        whileTap={{scale : 0.95}}
                                                        whileHover={{scale : 1.05}}
                                                        onClick = {() => {
                                                            setManager(state => {return {...state, comment : {status : true, mode : "validated", selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})
                                                        }}
                                                        className='valid'
                                                    >
                                                        Valider
                                                    </motion.button>

                                                    <motion.button
                                                        whileTap={{scale : 0.95}}
                                                        whileHover={{scale : 1.05}}
                                                        onClick = {() => {
                                                            setManager(state => {return {...state, comment : {status : true, mode : "refused", selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})
                                                        }}
                                                        className='not-valid'
                                                    >
                                                        Refuser
                                                    </motion.button>
                                                    {
                                                        !get_info_selected_data.map((inv:any) => inv.last_status.status).includes("sent") ? 
                                                        <motion.button
                                                            whileTap={{scale : 0.95}}
                                                            whileHover={{scale : 1.05}}
                                                            onClick = {() => {
                                                                //const selected_data_last_status = get_info_selected_data.map((inv:any) => inv.last_status.status)

                                                                /*if(selected_data_last_status.includes("pending")) {
                                                                    return dispatch(addRequestStatus({status : false, message : "Impossible d'envoyer une facture en attente."}))
                                                                }*/

                                                                dispatch(set_popup_warning({
                                                                    status : true, 
                                                                    text : "Confirmez-vous l'envoi de cette facture au comptable ? Une fois envoyée, elle ne pourra ni être modifiée ni supprimée.", 
                                                                    handleYes : () => setManager(state => {return {...state, comment : {status : true, mode : "sent", selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})
                                                                }))

                                                            }}
                                                            className='to_send'
                                                        >
                                                        Envoyer
                                                        </motion.button>
                                                        : 
                                                            null
                                                    }


                                                </React.Fragment>

                                            </div>
                                        </div>

                                        <div 
                                            className='reassignment'
                                            onClick= {() => handleReassign(get_info_selected_data)}
                                        >
                                            Réaffectation
                                        </div>
                                    </React.Fragment>
                                : 
                                    null
                            }




                            <motion.button 
                                className='export'>
                                    Exporter
                                <div className='export--menu'>
                                    {
                                        selected_data.length > 0 ? 
                                            <React.Fragment>
                                                <div
                                                    onClick = {() => handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)}
                                                >
                                                    Exporter la sélection
                                                </div>
                                                <div
                                                    onClick = {() => handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)}
                                                >
                                                    Exporter la sélection + pièces
                                                </div>
                                            </React.Fragment>
                                        : 
                                            null
                                    }
                                    <div
                                        onClick = {() => handleExport([], "all", false)}
                                    >
                                        Exporter tout
                                    </div>
                                    <div
                                        onClick = {() => handleExport([], "all", true)}
                                    >
                                        Exporter tout + pièces
                                    </div>
                                </div>
                            </motion.button>
                        </div>

                        <div 
                            onClick={() => {
                                setManager(state => {return {...state, business_manager_mode : !state.business_manager_mode}})
                            }}
                            className = {`mode-business-manager ${manager.business_manager_mode ? "mode-business-manager--active" : ""}`}
                        >
                            {
                                manager.business_manager_mode ? 
                                    <React.Fragment>
                                        <Icons name = "xmark" color = "white" />
                                        <p>Désactiver mode resp. dossier</p>
                                    </React.Fragment>
                                : 
                                    <p>Mode resp. dossier</p>
                            }
                        </div>

                        <motion.div 
                            whileHover={{scale : 1.05}}
                            whileTap={{scale : 0.95}}
                            className='memory-affair-aid'
                            onClick={() => setManager(state => {return {...state, memory_aid : true}})}
                        >
                            <CircleInfo />
                        </motion.div>

                        <div 
                            style = {{width : `${90 * filter_config.length}px`}}
                            className='filter tc-table--custom-filter'>
                            <div 
                                style = {{
                                    width : `${100 / filter_config.length}%`, 
                                    left : `calc(${(100 / filter_config.length) * filter_config.findIndex(f => f.status === manager.filter) + 1}% - ${filter_config.findIndex(f => f.status === manager.filter) === 0 ? "2.5px" : "6.5px"})`,
                                }}
                                className='selector'></div>
                            {
                                
                                filter_config.map((filter) => (
                                    <div 
                                        style = {{width : `${100 / filter_config.length}%`}}
                                        key={filter.id} 
                                        onClick = {() => {
                                            var status = filter.status as string
                                            if(filter.name === "all") {
                                                status = "all"
                                            }
                                            setManager(state => {return {...state, filter : status}})
                                        }}
                                        className={`filter__item filter__item--${filter.name} ${manager.filter === filter.status ? "filter__item--active" : ""}`}>
                                        <p>{`${filter.text}`}</p>
                                        <p>

                                            {
                                                filter.name === "all" ? 
                                                    `${props.data.length}`
                                                : filter.name === "sent" ? 
                                                    `${props.data.filter((dt:any) => dt.status_log.find((st:any) => st.status === "sent")).length}`
                                                : 
                                                    `${props.data.map((dt:any) => dt.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]).filter((st:any) => st.status === filter.status).length}`
//// YOUHOU
                                            }
                                            {
                                                /*
                                                filter.name === "sent" ? 
                                                    `${props.data.filter((inv:any) => inv.status_log.filter((st:any) => st.status === "pending")).length}`
                                                : 
                                                    filter.name === "all" ? 
                                                    `${props.data.length}`
                                                : 
                                                    `${props.data.length}`
                                                    */
                                            }
                                        </p>
                                    </div>
                                ))
                                
                            }

                        </div>

                    </div>
                
            )  
            }
        }, 
        handleDoubleClick : (tr:any) => {
            dispatch(setLoaderStatus({status : true}))
            props.server.post('/invoicing/select-inv', {id_inv : tr._id})
            .then(res => {
                const data = {
                    ...res.data.data,
                    url : server_host(res.data.data.url)
                }
                dispatch(setLoaderStatus({status : false}))
                setManager(state => {return{...state, mode : "detail", selected_data : data}})
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus(err.response.data))
            })
        }
            

    }
    return (
        <React.Fragment>
            {
                manager.memory_aid ? 
                    <MemoryAffairAid 
                        server={props.server}
                        handleClose={() => setManager(state => {return {...state, memory_aid : false}})}
                    />
                : 
                    null
            }

            {
                manager.reassign.status ? 
                    <Reassignment 
                        handleClose = {() => {
                            setManager(state => {return {...state,  reassign : {status : false, selected_data : []}}})
                            props.setManager((state:any) => {return {...state, initialize : false}})
                        }}
                        selected_data = {manager.reassign.selected_data}
                        server={props.server}
                    />
                : 
                    null
            }
            {
                manager.comment.status ? 
                    <PopupStatusMessage
                        handleClose = {() => {
                            setManager(state => {return {...state, comment : {status : false, mode : null, selected_data : [], content : ""}}})
                        }}
                        handleSubmit = {(message:any) => handleChangeStatus(message)}
                    />
                : null
            }
            {
                manager.preview.status ?
                    <PopupInvoicePreview
                        handleClose = {() => setManager(state => {return {...state, preview : {status : false, content : null}}})}
                        url = {manager.preview.content}
                    />
                : 
                    null
            }
            {
                manager.selected_data !== null ? 
                    <PopupUpdateInv 
                        server = {props.server}
                        data = {props.data}
                        selected_data={manager.selected_data}
                        filtering_data={filtering_data().map((el:any) => el._id)}
                        setManager={setManager}
                        handleClose = {() => {
                            //props.setManager((state:any) => {return {...state, initialize : false}})
                            setManager(state => {return {...state, selected_data : null}})}
                        }
                        setData = {props.setData}
                    />
                :       
                    null
            }

            <div className = "invoice-detail" style={{width : `${sum_sizes}px`}}>
                <Table  {...table_config}/>
            </div>

        </React.Fragment>
    )
}


function PopupStatusMessage(props:any) {
    const [message, setMessage] = useState("")

    return (
        <BasePopup
            nameClass = "popup-message-refused"
            handleClose = {props.handleClose}
        >
            <p>Souhaitez-vous ajouter un commentaire ?</p>
            <textarea
                value = {message}
                onChange = {(e) => setMessage(e.target.value)}
                placeholder='Tapez ici...'
            />
            <MainButton
                handleClick = {() => props.handleSubmit(message)}
            >
                Valider
            </MainButton>
        
        </BasePopup>
    )
}

function PopupInvoicePreview(props:any) {
    return (
        <BasePopup
            nameClass = "popup-invoice-preview"
            handleClose = {props.handleClose}
        >
            <iframe src={`${props.url}`} title="Web Page Viewer" className="iframe-container"/>
        </BasePopup>
    )
}