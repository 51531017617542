import React from 'react' ; 
import {ReactComponent as NewAffairSVG} from '../../assets/svg/icons/newAffair.svg' ; 
import {ReactComponent as Folder} from '../../assets/svg/icons/folder.svg' ; 
import {ReactComponent as Briefcase} from '../../assets/svg/icons/briefcase-light.svg' ; 
//import {ReactComponent as Archive} from '../../assets/svg/icons/archive.svg' ; 
import {ReactComponent as Grid} from '../../assets/svg/icons/grid.svg' ; 
import {ReactComponent as TruckFast} from '../../assets/svg/icons/truck-fast.svg' ; 
import {ReactComponent as InvoiceFileDollar} from '../../assets/svg/icons/file-invoice-dollar-light.svg' ; 
import {ReactComponent as CreditCard} from '../../assets/svg/icons/credit-card-light.svg' ; 
import {ReactComponent as MoneyFromBracket} from '../../assets/svg/icons/money-from-bracket-light.svg' ;
import {ReactComponent as CalendarCircleUser} from '../../assets/svg/icons/calendar-circle-user-light.svg' ;
import { Axios } from 'axios';
import { AuthType } from '../../store/types.store';
import Homepage from '../base/Homepage';
import Affairs from '../affairs/Affairs';
import NewAffair from '../new_affair/NewAffair';
import Suppliers from '../suppliers/Suppliers';
import SuppliersInv from '../invoices/suppliers/SuppliersInv';
import Sales from '../sales/Sales';
import Documents from '../documents/Documents';
import Planning from '../planning/Planning';
import Payments from '../payments/Payments';

export interface NavigationProps {
    server : Axios
}

export interface NavigationParams extends NavigationProps {
    account : AuthType
}

type NavigationItem = {
    id : string,
    id_menu : number,
    content : string,
    svg : JSX.Element,
    path : string,
    component ?: JSX.Element
}

export const navigation = (props:NavigationProps):NavigationItem[] => {

    return [
        {id : 'N-00', id_menu : 1, content : "Tableau de bord", svg : <Grid/>, path : '/', component : <Homepage />}, 
        {id : 'N-01', id_menu : 2, content : "Nouvelle affaire",  svg : <NewAffairSVG />, path : '/new', component : <NewAffair {...props} />}, 
        {id : 'N-02', id_menu : 3, content : "Affaires",  svg : <Briefcase />, path : '/affairs', component : <Affairs {...props} />},
        {id : 'N-03', id_menu : 5, content : "Annuaire - Fournisseurs", svg : <TruckFast />, path : '/suppliers', component : <Suppliers {...props} />},
        {id : 'N-04', id_menu : 4, content : "Facturation - Fournisseurs", svg : <InvoiceFileDollar />, path : '/billing', component : <SuppliersInv {...props} />},  
        {id : 'N-08', id_menu : 9, content : "Règlements - Fournisseurs", svg : <CreditCard />, path : '/payment', component : <Payments {...props} />},  
        //{id : 'N-05', id_menu : 6, content : "Ventes - Clients",  svg : <MoneyFromBracket />, path : '/sales', component : <Sales {...props} />},
        //id : 'N-06', id_menu : 7, content : "Documents",  svg : <Folder />, path : '/documents', component : <Documents {...props} />},  
        //{id : 'N-07', id_menu : 8, content : "Planning & Temps",  svg : <CalendarCircleUser />, path : '/planning', component : <Planning {...props} />},  

    ]
}

export const navigation_data = (props:NavigationParams) => {
    if(props.account.infos.user_group === "direction") {
        return navigation({server : props.server})
    }
    const access_id = props.account.infos.access.map((acc:any) => acc.id_menu)
    return navigation({server : props.server}).filter(nav => [1, ...access_id].includes(nav.id_menu) )
}