/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch } from 'react-redux';
import { ServerProps } from '../../../../../config/server.config';
import {Table} from 'tc-minibox' ; 
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { GridEvents } from '../Grid';
import Totals from '../../../../base/Totals';
import { generate_totals } from '../../../utils.affairs';
//import { useNavigate } from 'react-router-dom';
import ContainerLoad from '../../../../../tools/components/general/ContainerLoad';
import { DescriptionCustom, PricingCustom, UnitCustom } from '../../../../base/tcminibox.utils';


export interface AllLinesProps extends ServerProps {
    id_affair : number|string,
    events : GridEvents, 
    key_resize : string | number,
}

interface AllinesManager {
    mode : "load" | "normal" | "error", 
    initialize : boolean, 
}

export default function AllLines(props:AllLinesProps) {
    //const navigate = useNavigate()
    const dispatch = useDispatch()
    const [manager, setManager] = useState<AllinesManager>({initialize : false, mode : "load"})
   const [lines, setLines] = useState<any[]|[]>([])

   useEffect(() => {
        if(!manager.initialize) {
            props.server.post("/affairs/get-lines", {id_affair : props.id_affair, id_amendment : "all"})
            .then(res => {
                setManager(state => {return{...state, initialize : true, mode : "normal"}})
                setLines(res.data.data)
            })
            .catch(err => {
                setManager(state => {return{...state, initialize : true, mode : "error"}})
                dispatch(err.response.data)
            })
        }

   }, [])


    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            {
                info : {
                    text : "Type",
                    name : "denomination", 
                }, 
 
                style : {
                    width : "80px", 
                    tbody : {
                        justifyContent : "center", 
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Désignation", 
                    name : "description", 
                    custom : (tr, td) => <DescriptionCustom status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <DescriptionCustom status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>,
                style : {
                    width : `calc(100% - 420px ${props.events.fullscreen === props.key_resize ? "- 500px" : ""})`, 
                }
            }, 
            ...props.events.fullscreen === props.key_resize ? 
                [
                    {
                        info : {
                            text : "Catégorie", 
                            name : "category", 
                        }, 
                        style : {
                            width : "250px"
                        }
                    }, 
                    {
                        info : {
                            text : "Sous-catégorie", 
                            name : "sub_category", 
                        }, 
                        style : {
                            width : "250px"
                        }
                    }, 
                ]
            : [],
            {
                info : {
                    text : "Unité", 
                    name : "unit", 
                    custom : (tr, td) => <UnitCustom status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <UnitCustom status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>,
                style : {
                    width : "100px"
                }
            }, 
            {
                info : {
                    text : "Quantité", 
                    name : "quantity", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "P.U.", 
                    name : "unit_price", 
                    custom : (tr, td) => <PricingCustom name = "unit_price" status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <PricingCustom name = "unit_price" status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>, 
                style : {
                    width : "100px"
                }
            }, 
            {
                info : {
                    text : "Total H.T", 
                    name : "total_ht", 
                    custom : (tr, td) => <PricingCustom name = "total_ht" status = "normal" tr = {tr} td = {td} data = {lines} setData = {setLines}/>
                }, 
                custom_edit : (tr, td) => <PricingCustom name = "total_ht" status = "edit" tr = {tr} td = {td} data = {lines} setData = {setLines}/>, 
                style : {
                    width : "100px"
                }
            }, 
        ], 
        data : lines,
        setData : setLines,
    }


    return (
        <ContainerLoad load = {manager.mode === "load"}>
            <Totals
                {...generate_totals(lines.map(ld => ld.total_ht).reduce((acc, curr) => acc + curr, 0))}
            />
            <Table {...table_config} />
        </ContainerLoad>

    
    )
}