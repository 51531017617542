import moment from "moment";
import Papa from "papaparse";


export interface PaymentStatus {
    id?: string;
    name: string;
    color?: string;
    title?: string; // Titre en français
    date? : Date;
    content? : string; 
    user? : string ; 
    textColor?: string;
}

export interface PaymentAmount {
    id?: string;
    name: string;
    date : Date;
    amount : string ; 
    user? : string ; 
}


export const paymentStatuses = [
    // En attente de règlement (Jaune moutarde)
    { id: "awaiting-payment", name: "awaiting-payment", color: "#F9A825", textColor: "#000000", title: "En attente de règlement" },

    // Paiement en attente de rapprochement bancaire (Gris foncé)
    { id: "paid-awaiting-reconciliation", name: "paid-awaiting-reconciliation", color: "#FEFEFE", textColor: "#000000", title: "Paiement en attente de rapprochement bancaire" },

    // Facture lettrée (Violet foncé)
    { id: "reconciled-invoice", name: "reconciled-invoice", color: "#7E57C2", textColor: "#FFFFFF", title: "Facture lettrée" },

    // Même pièce affectée à plusieurs affaires (Bleu vif)
    //{ id: "item-assigned-multiple-cases", name: "item-assigned-multiple-cases", color: "#42A5F5", textColor: "#FFFFFF", title: "Même pièce affectée à plusieurs affaires" },

    // Facture en litige ou en avoir (Rouge)
    { id: "invoice-dispute-credit", name: "invoice-dispute-credit", color: "#EF5350", textColor: "#FFFFFF", title: "Facture en litige ou avoir" }
];


export const payment_report = (data: any) => {
    const thead = [
        "N° de pièce comptable", 
        "N° de facture", 
        "Fournisseur", 
        "Date de facture", 
        "Date de réception",
        "Montant H.T", 
        "TVA", 
        "T.T.C", 
        "Payé", 
        "Date de paiement", 
        "Utilisateur"
    ];
    
    var result = data.map((item: any) => {
        return [
            item.doc_number,
            item.inv_number,
            item["supplier.name"],
            moment(item.invoice_date).format("DD/MM/YYYY"),
            moment(item.receipt_date).format("DD/MM/YYYY"),
            `${item.total_ttc - item.total_tva}`.replace(".", ","), 
            `${item.total_tva}`.replace(".", ","),
            `${item.total_ttc}`.replace(".", ","),
            `${item.pay_amount}`.replace(".", ","),
            moment(item.pay_date).format("DD/MM/YYYY"),
            item.pay_user
        ];
    });


    // Transformation des données en CSV
    const csv = Papa.unparse({
        fields: thead,
        data: result
    });


    // Création d'un lien pour télécharger le fichier CSV
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'payment_report.csv';
    link.click();
    URL.revokeObjectURL(link.href);
};