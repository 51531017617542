/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react' ;
import {motion} from 'framer-motion' ; 
import { useDispatch } from 'react-redux';
import { ServerProps } from '../../../../../../../config/server.config';
import { amendments_type } from '../amendments.utils';
import BasePopup from '../../../../../../../tools/components/general/BasePopup';
import ImportModule, { ImportProps } from '../../../../../../../tools/components/general/ImportModule';
import { addRequestStatus, setLoaderStatus, set_popup_warning } from '../../../../../../../store/global/actions.global';
import { DatePicker, Icons, ObjectUtils, Table } from 'tc-minibox';
import { generate_totals } from '../../../../../utils.affairs';
import Totals from '../../../../../../base/Totals';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { icons_config } from '../../../../../../../config/context.config';
import { DescriptionCustom, PricingCustom, TotalHTCustom, UnitCustom } from '../../../../../../base/tcminibox.utils';
import MainButton from '../../../../../../../tools/components/general/MainButton';
import DropDown from '../../../../../../../tools/components/general/DropDown';


export interface AmendmentsCreationProps extends ServerProps {
    rerender_detail : () => void,
    affair_info : any,
    last_amendment : number, 
    handleClose : () => void, 

}

export default function NewAmendment(props:AmendmentsCreationProps) {
    const dispatch = useDispatch()
    const [manager, setManager] = useState({
        steps : 0
    })
    const [form, setForm] = useState<any>({
        id_affair : props.affair_info._id, 
        token_affair : props.affair_info.token_affair,
        affair_number : props.affair_info.affair_number,
        amendment_number : props.last_amendment + 1, 
        denomination : "", 
        type : amendments_type[0].name, 
        date_doc : new Date(), 
        file : {}   
    })
    const [events, setEvents] = useState({
        hover : false
    })
    const [linesAmendment, setLinesAmendment] = useState<DataType>([])
    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})
    const handleChangeForm = (name:string, val:any) => setForm((state:any) => {return{...state, [name] : val}})
console.log(linesAmendment)
    // * FUNCTIONS
    const handleAutoSave = async (data_changed:any) => {
        dispatch(addRequestStatus({status : null, message : "Enregistrement des lignes modifiées..."
        }))

        props.server.post('/affairs/update-amendment-lines', data_changed)
        .then(res => {
            const tokens_lines = data_changed.map((el:any) => el.token_line)
            dispatch(addRequestStatus({status : true, message : "Modifications effectuées."}))

            setLinesAmendment((state:any) => {
                return state.map((el:any) => {
                    if(tokens_lines.includes(el.token_line)) {
                        return data_changed.find((sub:any) => sub.token_line === el.token_line)
                    }
                    return el
                })
            })
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }

    const handleSubmit = () => {
        if(form.denomination.trim().length === 0) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez renseigner une dénomination."
            }))
        }
        return handleChangeManager("steps", manager.steps + 1)
    }

    const handleReturn = () => {
        dispatch(setLoaderStatus({status : true}))

        if(manager.steps === 2) {
            dispatch(setLoaderStatus({status : false}))
            props.server.post('/affairs/definitive-delete-amendment', [form._id ? form._id : ""])
            .then(res => {
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus(err.response.data))
            })
        }
        setManager((state:any) => {return {...state, steps : 0 }})
    }

    const handleClose = () => {
        dispatch(set_popup_warning({
            status : true, 
            text : "Êtes-vous sûr de vouloir quitter ? Vous allez perdre les données saisies.", 
            handleYes : () => {
                if(manager.steps === 2) {
                    handleReturn()
                }
                props.handleClose()
            }
        }))

    }
    
    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        subtitle : "Importez votre fichier Excel",
        nameClass : 'new-affair--import', 
        handleSubmit : (file:File) => {
            const mimetypes_accepted = [
                "text/csv", 
            ]
            
            if(!mimetypes_accepted.includes(file.type)) {
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Fichier non autorisé."
                }))
            }

            dispatch(setLoaderStatus({status : true, message : "Traitement du fichier en cours..."}))
            setForm((state:any )=> {return {...state, file : file}})
            const container = new FormData()
            for (const [key, value] of Object.entries(ObjectUtils.removeKeyFromObject(form, 'file') )) {
                container.append(key, value)
            }
            container.append("file", file as File)

            props.server.post("/affairs/create-amendments", container, { headers : {'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(res => {
                handleChangeManager("steps", 2)      
                handleChangeForm("file", {})
                setForm((state:any) => {return {...state, _id : res.data._id}})
                setLinesAmendment(res.data.l_devis)
                dispatch(setLoaderStatus({status : false}))
                return dispatch(addRequestStatus({
                    status : true, 
                    message : "Enregistrement dans la base de données effectué"
                }))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                handleChangeForm("file", {})
                return dispatch(addRequestStatus(err.response.data))
            })
        }
    }

    const table_config:TableForProps = {
        height : 45, 
        editing : true, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Désignation", 
                    name : "description", 
                    custom : (tr:any, td:any) => <DescriptionCustom status = "normal" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>
                }, 
                custom_edit : (tr:any, td:any) => <DescriptionCustom status = "edit" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>,
                style : {
                    width : `calc(100% - ${100 * 4}px - 450px)`,
                }
            }, 
            {
                info : {
                    text : "Catégorie", 
                    name : "category", 
                }, 
                style : {
                    width : "250px",
                    tbody : {
                        textAlign : "justify"
                    }
                }
            }, 
            {
                info : {
                    text : "Sous-catégorie", 
                    name : "sub_category", 
                }, 
                style : {
                    width : "200px",
                }
            }, 
            {
                info : {
                    text : "Unité", 
                    name : "unit", 
                    custom : (tr:any, td:any) => <UnitCustom status = "normal" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>
                }, 
                custom_edit : (tr:any, td:any) => <UnitCustom status = "edit" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>,
                style : {
                    width : `100px`, 
                }
            }, 
            {
                info : {
                    text : "Quantité", 
                    name : "quantity", 
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "P.U.", 
                    name : "unit_price", 
                    custom : (tr:any, td:any) => <PricingCustom name = "unit_price" status = "normal" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>
                }, 
                custom_edit : (tr:any, td:any) => <PricingCustom name = "unit_price" status = "edit" tr = {tr} td = {td} data = {linesAmendment} setData = {setLinesAmendment}/>, 

                style : {
                    width : `100px`, 
                }
            }, 
            {
                info : {
                    text : "Total H.T", 
                    name : "total_ht", 
                    custom : (tr, td) => <TotalHTCustom status = "normal" tr = {tr} td = {td} />, 
                }, 
                custom_edit : (tr, td) => <TotalHTCustom status = "normal" tr = {tr} td = {td} />, 
                style : {
                    width : `100px`, 
                }
            }, 
        ], 
        data : linesAmendment,
        setData : setLinesAmendment,
        header : {
            buttons : {
                include : ["return", "save"], 
                actions : {
                    //add : () => navigate('/new')
                    return : () => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir quitter ? Vous allez perdre les données saisies.", 
                            handleYes : () => {
                                handleReturn()
                            }
                        }))
                    }, 
                    save : () => {
                        props.rerender_detail()
                        dispatch(setLoaderStatus({status : true, message : "Enregistrement des lignes modifiées..."}))
                        props.handleClose()
                    }
                }
            }
        }, 
        autosave : {
            delay : 500, 
            action : (data) => handleAutoSave(data)
        }
    }

    return (
        <BasePopup nameClass={`amendments-creation ${manager.steps === 2 ? "amendments-creation--processing" : manager.steps === 1 ? "amendments-creation--import" : "amendments-creation--completion"}`}
            handleClose = {handleClose}
        >
            {
                manager.steps === 2 ? 
                    <React.Fragment>
                    <Totals
                        {...generate_totals(linesAmendment.map((ld:any) => parseFloat(ld.total_ht)).reduce((acc, curr) => acc + curr, 0))}
                    />
                    <Table {...table_config} />

                    </React.Fragment>
                : 
                    manager.steps === 1 ? 
                        <React.Fragment>
                            {/* ARROW TURN */}
                            <motion.div 
                                exit = "exit"
                                initial = "initial"
                                animate = "animate"
                                whileHover={{scale : 1.05}}
                                whileTap={{scale : 0.95}}
                                className='button-return'
                                onClick = {() => handleReturn()}
                            >
                                <Icons name = "arrow-turn" mode = "duotone" color = "white"/>
                            </motion.div>
                            <ImportModule {...import_config} />
                        </React.Fragment>
                    : 
                        <React.Fragment>
                            <div className='number-affair'>
                                    <h3>Avenant n°</h3>
                                    <p>{form.amendment_number}</p>
                            </div>
                            <div className = "date_doc">
                                <DatePicker
                                    selected={new Date(form.date_doc)} 
                                    dateFormat="dd/MM/yyyy"
                                    onChange = {(e:any) => setForm((state:any) => {return{...state, date_doc : e}})}
                                    locale="fr"     
                                      
                                />
                            </div>

                            <div className='input'>
                                <label htmlFor='denomination'>Dénomination</label>
                                <input 
                                    type = "text" 
                                    name = "denomination"
                                    value = {form.denomination}
                                    placeholder='Votre code ici'
                                    onChange = {(e) => handleChangeForm('denomination', e.target.value)}
                                
                                />
                            </div>

                            <div className='input'>
                                <label htmlFor='prenom'>Type</label>
                                <DropDown
                                    reference = {"AMENDMENTS-TYPE"}
                                    hover = {events.hover}
                                    setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                                    value = {form.type}
                                    setValue = {(e) => handleChangeForm('type', e.name)}
                                    list = {amendments_type}
                                />
                            </div>
                            <MainButton
                                handleClick = {handleSubmit}
                            >
                                Valider
                            </MainButton>
                        </React.Fragment>

            }
        </BasePopup>
    )
}
