/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { NavigationProps } from "../nav/data.nav";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../store/account/selector.account";
import { useEffect, useState } from "react";
import { StringUtils, URLUtils } from "tc-minibox";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import {Table} from 'tc-minibox' ; 
import { DataType, TableForProps } from "tc-minibox/dist/types/table/index.table";
import BaseApp from "../../tools/components/general/BaseApp";
import Detail, { DetailProps } from "./detail/Detail";
import StepsText from "../../tools/components/general/StepsText";
import { analytic_codes } from "./utils.affairs";

export interface AffairManager {
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null, 
    filter : "all" | number
}

export default function Affairs(props:NavigationProps) {
    // * URL PARAMS && REDUX
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params:string|null = searchParams.get('id_affair');
    const navigate = useNavigate()
    //const id_amendment_params = searchParams.get('id_amendment');
    const dispatch = useDispatch()
    const account = useSelector(account_user)

    // * STATES
    const [manager, setManager] = useState<AffairManager>({ initialize : false, mode : "listing", filter : "all",  })
    const [affairs, setAffairs] = useState<DataType>([])

    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            props.server.get('/affairs/get-affairs-list', {
                headers: {
                    Authorization: `Bearer ${account.token}`
                }
            })
            .then(res => {
                setManager(state => {return{...state, mode : "listing" }})
                setAffairs(res.data.data)

            
                setManager(state => {return{...state, initialize : true }})

                if(!StringUtils.isUndefinedValue(id_affair_params)){
                    setManager(state => {return{...state, selected_data : id_affair_params !== null ? parseInt(id_affair_params) : null}})
                    URLUtils.removeURLParameter('id_affair')
                } 
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [manager.initialize])

    // * VARIABLES 
    const tbody = affairs.map(af => {
        return {
            ...af, 
            business_manager : `${af.manager.firstname} ${af.manager.lastname}`
        }
    })

    const filter_config = [
        {
            name : "all", 
            title : "Tous",
        }, 
        ...analytic_codes
    ]
    // * FUNCTIONS 
    const handleDelete = (e:any[]) => {
        if(e.length > 0) {
            dispatch(setLoaderStatus({status : true, message : "Suppression des données en cours ..."}))
            const selected_data = e.map(da => da._id)
            props.server.post('/affairs/definitive-delete', selected_data)
            .then(res => {
                
                setAffairs(state => state.filter(st => !selected_data.includes(st._id)))
                dispatch(addRequestStatus(res.data))
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    } 

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            // *
            {
                info : {
                    text : "N°",
                    name : "affair_number", 
                }, 
 
                style : {
                    width : "80px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Référence", 
                    name : "reference", 
                }, 
                style : {
                    width : "calc(100% - 600px - 250px)", 
                    tbody : {
                        width : "calc(100% - 20px)",
                        justifyContent : "flex-start",
                        padding : "0px 10px", 
                    }
                }
            }, 
            {
                info : {
                    text : "Objet", 
                    name : "object", 
                }, 
                style : {
                    width : "250px", 
                    tbody : {
                        width : "calc(100% - 20px)",
                        justifyContent : "flex-start",
                        padding : "0px 10px", 
                    }
                }
            }, 
            {
                info : {
                    text : "Date",
                    name : "costestimate_date", 
                    type : "date"
                }, 
                style : {
                    width : "150px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 

            {
                info : {
                    text : "Type", 
                    name : "type", 
                }, 
                style : {
                    width : "100px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            
            {
                info : {
                    text : "Responsable dossier", 
                    name : "business_manager", 
                }, 
                style : {
                    width : "250px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setAffairs,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => navigate('/new'), 
                    delete : (data:any) => {
                        if(data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une affaire."}))
                        if(data.map((dt:any) => dt.is_default_setting).includes(true)) return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas supprimer les affaires définies par défaut. Veuillez vous rendre dans les paramètres pour effectuer cette action."}))
                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ces affaires ?", 
                            handleYes : () => handleDelete(data)
                        }))
                    }
                }
            }, 
            custom : (data:any) => {
                return (
                    <div 
                        style = {{width : `${130 * filter_config.length}px`}}
                        className='tc-table--custom-filter'
                    >
                    <div 
                        style = {{
                            width : `${100 / filter_config.length}%`, 
                            left : `calc(${(100 / filter_config.length) * filter_config.findIndex(f => f.name === manager.filter) + 1}% - ${filter_config.findIndex(f => f.name === manager.filter) === 0 ? "2.5px" : "6.5px"})`,
                        }}
                        className='selector'></div>
                    {
                        filter_config.map((filter, index:number) => (
                            <div 
                                style = {{width : `${100 / filter_config.length}%`}}
                                key={`${filter.name}-0${index}`} 
                                onClick = {() => setManager((state:any) => {return {...state, filter : filter.name}})}
                                className={`filter__item filter__item--${filter.name} ${manager.filter === filter.name ? "filter__item--active" : ""}`}>
                                <p>{`${filter.title}`}</p>
                                <p>
                                    {
                                        filter.name === "all" ? 
                                            `${affairs.length}`
                                        : 
                                            `${affairs.filter(inv => filter.name === inv.type).length}`
                                    }
                                </p>
                            </div>
                        ))
                    }

                </div>
                )
            }
        }, 
        filter_custom : (data:any) => {
            if(manager.filter === "all") {
                return data
            }
           return data.filter((dt:any) => affairs.find((_, index:number) => dt[0] === index).type === manager.filter)
        }, 
        handleDoubleClick : (tr:any) => {
            setManager(state => {return{...state, mode : "detail", selected_data : tr._id}})
        }
    }
    const detail_config:DetailProps = {
        manager : manager, 
        setManager : setManager, 
        server : props.server, 
        selected_data : affairs.find(af => af._id === manager.selected_data)
    }

    return (
        <BaseApp>
            <BaseContainer nameClass="affairs">
                <StepsText 
                    title = "Affaires"
                    position = {{
                        x : 60, 
                        y : 0
                    }}
                />
                {
                    manager.mode === "detail" ? 
                        <Detail {...detail_config} />
                    : 
                        <Table {...table_config} />
                }
            </BaseContainer>
        </BaseApp>
    )
}