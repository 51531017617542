/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'; 
import {motion} from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addRequestStatus, setLoaderStatus } from '../../../store/global/actions.global';
import { ServerProps } from '../../../config/server.config';
import { NewAffairManager } from '../NewAffair';
import { DatePicker, Icons, setDefaultLocale, registerLocale, TableUtils } from 'tc-minibox';
import { analytic_codes } from '../../affairs/utils.affairs';
import DropDown from '../../../tools/components/general/DropDown';
import fr from 'date-fns/locale/fr';
import MainButton from '../../../tools/components/general/MainButton';

registerLocale('fr', fr);
setDefaultLocale('fr');

export interface CompletionProps extends ServerProps {
    data : any, 
    setData : React.Dispatch<React.SetStateAction<any>>,
    setManager : React.Dispatch<React.SetStateAction<NewAffairManager>>,

}

type EventsType = {
    hover : any
}

export default function Completion(props:CompletionProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    //const socket = socket_server
    const [manager, setManager] = useState({
        initialization : "pending"
    })
    const [savedGeneralities, setSavedGeneralities] = useState<any>(props.data)
    const [events, setEvents] = useState<EventsType>({
        hover : ''
    })
    const [direction, setDirection] = useState([])
    const direction_dropdown = direction.map((dr:any)=> {return {id : dr._id, name : dr._id, text : `${dr.firstname} ${dr.lastname}`}})


    //MODIFIY FORM
    const controlInputValues = (name:string, value:any) => {
        if(['total_ht', 'total_tva', 'total_ttc'].includes(name)) {
            return value.replace(/[^\d.,-]/g, '');
        }
        return value
    }

    const handleChangeInput = (input_name:string, val:any) => {
        const value = controlInputValues(input_name, val)

        return props.setData((state:any )=> {
            return {
                ...state, 
                [input_name] : value
            }
        })
    }

    const handleChangeIdentification = (choice:any) => {
        if(choice.id === parseInt(props.data.affair_number.slice(0,1))) {
            return dispatch(addRequestStatus({
                status  : null, 
                message : "Type déjà assigné"
            }))
        }
        dispatch(setLoaderStatus({status : true}))
        props.server.post('affairs/set_num_affair', { id : choice.id, token_affair : props.data.token_affair })
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            props.setData((state:any) => {
                return {
                    ...state, 
                    ...res.data.updatedData
                }
            })
            return dispatch(addRequestStatus({
                status : res.data.status, 
                message : res.data.message
            }))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(err.response.data))
        })
    }


    const handleAutoSave = async (data_changed:any) => {
        dispatch(addRequestStatus({
            status : null, 
            message : "Enregistrement des lignes modifiées..."
        }))


        props.server.post('/affairs/autosave-generalities', data_changed)
        .then(res => {

            dispatch(addRequestStatus({
                status : true, 
                message : "Modifications effectuées."
            }))
            
            setSavedGeneralities((state:any )=> {
                return {
                    ...state, 
                    ...data_changed
                }
            })

        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    //AUTO SAVE ACTION
    useEffect(() => {
        if(JSON.stringify(props.data) !== JSON.stringify(savedGeneralities)) {
            //const memory_json = savedGeneralities.map(el => JSON.stringify(el))
            //const data_changed = props.generalities.filter(el => !memory_json.includes(JSON.stringify(el)))

            const timeoutId = setTimeout(() => {
                const memory = Object.entries(savedGeneralities).map(el => JSON.stringify(el))
                const current = Object.entries(props.data)
                const data_changed = current.filter(el => !memory.includes(JSON.stringify(el))).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

                handleAutoSave({...data_changed, token_affair : props.data.token_affair})
                
            }, 500); 
        
            return () => clearTimeout(timeoutId);
 
            
            
        }

    }, [props.data])


    //INITIALIZE
    useEffect(() => {
        if(manager.initialization === "pending") {
            props.server.get('/affairs/get-direction-user')
            .then(res => {
                setManager(state => {return{...state, initialization : "resolve" }})
                setDirection(res.data.data)
                props.setData((state:any) => {
                    return {
                        ...state,
                        business_manager : res.data.data[0].id_user
                    }
                })
                //setAffairs(res.data.listing)
            })
            .catch(err => {
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [])

    return (
        <motion.div 
            className='new-affair--completion'
        >
            {/* ARROW TURN */}
            <motion.div 
                exit = "exit"
                initial = "initial"
                animate = "animate"
                whileHover={{scale : 1.05}}
                whileTap={{scale : 0.95}}
                className='return'
                onClick = {() => {
                    props.setManager((state:any) => {return {...state, mode : "processing" }})
                }}
            >
                <Icons name = "arrow-turn" mode = "duotone" color = "white"/>
            </motion.div>

            {/* IDENTIFICATION AFFAIRE */}
            <motion.div
                exit = "exit"
                initial = "initial"
                animate = "animate"
                transition = {{
                    duration : 0.2, 
                }}
                className='new-affair--completion__container identification'
            >
                <h1>Identification</h1>
                <div>

                    <div className='choice'>
                        <h3>Type d'affaire</h3>
                        <div>
                            {
                                analytic_codes.map(el => (
                                    <motion.button
                                        className={parseInt(`${props.data.affair_number}`.slice(0,1)) === el.id ? "active" : ""}
                                        key = {JSON.stringify(el)}
                                        onClick = {() => handleChangeIdentification(el)}
                                        //{...parseInt(`${props.generalities.affair_number}`.slice(0,1)) !== el.id ? {...button_action_animation} : {}}
    
                                    >
                                        {el.title}
                                    </motion.button>
                                ))
                            } 
                        </div>


                    </div>

                    <div className='number-affair'>
                            <h3>Affaire n°</h3>
                            <input
                                type="text"
                                value = {props.data.affair_number}
                                onChange = {(e) => {
                                    if(e.target.value.length <= 4) {
                                        props.setData((state:any) => {return{...state, affair_number : e.target.value.match(/^[0-9]*$/gm) ? e.target.value : state.affair_number, analytic_code : e.target.value.match(/^[0-9]*$/gm) ? e.target.value : state.analytic_code}})
                                    }
                                }}
                            />
                    </div>

                    <div className='business-manager'>
                    <label htmlFor='business-manager'>Responsable dossier</label>
                    <DropDown
                        reference = {"BUSINESS_MANAGER"}
                        hover = {events.hover}
                        setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                        value = {props.data.manager}
                        setValue = {(e) => {
                            props.setData((state:any) => {return{...state, manager : e.name}})
                        }}
                        list = {direction_dropdown}
                    />
                    </div>



                </div>

            </motion.div>

            <div className='bloc'>
                {/* INFORMATIONS AFFAIR*/}
                <motion.div
                    exit = "exit"
                    initial = "initial"
                    animate = "animate"
                    transition = {{
                        duration : 0.2, 
                        delay : 0.2
                    }}
                    className='new-affair--completion__container informations-affair'
                >
                    <h1>Informations</h1>
                    <div>
                        <label htmlFor='costestimate_date'>Date du devis</label>
                        <DatePicker
                            selected={new Date(props.data.costestimate_date)} 
                            dateFormat="dd/MM/yyyy"
                            onChange = {(e:any) => handleChangeInput("costestimate_date", e)}
                            locale="fr"       
                        />
                    </div>
                    <div className='object'>
                        <label htmlFor='object'>Objet</label>
                        <input
                            type="text"
                            name = "object"
                            value = {props.data.object}
                            onChange = {(e) => handleChangeInput(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className = "reference">
                        <label htmlFor='reference'>Référence</label>
                        <input
                            type="text"
                            name = "reference"
                            value = {props.data.reference}
                            onChange = {(e) => handleChangeInput(e.target.name, e.target.value)}
                        />
                    </div>
                </motion.div>

                {/* TOTALS*/}
                <motion.div
                    exit = "exit"
                    initial = "initial"
                    animate = "animate"
                    transition = {{
                        duration : 0.2, 
                        delay : 0.4
                    }}
                    className='new-affair--completion__container totals'
                >
                    <h1>Totaux</h1>
                    <div>
                        <div className='total_ht'>
                            <p>Total HT</p>
                            <p>{TableUtils.formatNumberWithSpaces(props.data.total_ht.toFixed(2))} €</p>
                            
                        </div>
                        <div className='total_tva'>
                            <p>Total TVA</p>
                            <p>{TableUtils.formatNumberWithSpaces(props.data.total_tva.toFixed(2))} €</p>
                        </div>
                        <div className='total_ttc'>
                            <p>Total TTC</p>
                            <p>{TableUtils.formatNumberWithSpaces(props.data.total_ttc.toFixed(2))} €</p>
                        </div>
                    </div>

                </motion.div>
            </div>
            
            <motion.div
                className='submit'
            >
                {
                    JSON.stringify(props.data) === JSON.stringify(savedGeneralities) ? 
                    <MainButton
                        handleClick = {() => {
                            dispatch(addRequestStatus({
                                status : true, 
                                message : "Votre affaire est créée avec succès !"
                            }))
                            navigate('/affairs')
                        }}
                    >
                        Valider
                    </MainButton>
                    : 
                    null

                }

            </motion.div>
        </motion.div>
    )
}