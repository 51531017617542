import React from 'react' ; 
import {motion} from 'framer-motion' ; 
import {v4} from 'uuid'; 
import {ReactComponent as Illustration} from '../../assets/svg/illustrations/error_404.svg' ; 



export default function ErrorNotFound() {

    return (
        <motion.div 
            key = {v4()}
            exit = {{scale : 0}}
            initial = {{scale : 0}}
            animate = {{scale : 1}}
            className='underconstruction'
        >
            <Illustration />
        </motion.div>
    )
}

