import { DataType } from "tc-minibox/dist/types/table/index.table"
import { icons_config } from "../../config/context.config"
import { Icons } from "tc-minibox"


interface CustomCells {
    status : "normal" | "edit", 
    tr : any, 
    td : any
}

// * DESCRIPTION
interface DescriptionCustomProps extends CustomCells {
    data : DataType,
    setData : React.Dispatch<React.SetStateAction<DataType>>
}

export function DescriptionCustom(props:DescriptionCustomProps) {

    if(props.status === "normal") {
        return (
            props.tr.unit === "$" ? 
            <div className='description--linked'>
                {props.td}
            </div>
        : 
            <p>{props.td}</p>
        )
    } else {
        return (
            <div className={`description--input ${props.tr.unit === "$" ? 'description--input--linked' : ""}`}>
                <input 
                    type = "text"
                    className = "input"
                    value = {props.td}
                    onChange = {(e) => props.setData(state => state.map((st) => {
                        if(st._id === props.tr._id) {
                            return {...st, description : e.target.value}
                        }
                        return st
                    }))}
                />
            </div>
        )
    }

}

// * UNIT 
interface UnitCustomProps extends CustomCells {
    data : DataType,
    setData : React.Dispatch<React.SetStateAction<DataType>>
}


export function UnitCustom(props:UnitCustomProps) {
    if(props.status === "normal") {
        return (
            <div className = {`unit ${props.td === "$" ? "unit--linked" : ""}`}>{
                props.td !== "$" ? props.td : <Icons name = "link" {...icons_config} />
            }</div>
        )
    } else {
        return (
            props.td === "$" ? 
            <div className = {`unit ${props.td === "$" ? "unit--linked" : ""}`}>{
                props.td !== "$" ? props.td : <Icons name = "link" color = {"blue"} mode = "duotone" />
                
            }</div>
        : 
            <div className= "unit--input">
                <input
                    type = "text"
                    className = "input"
                    value = {props.td}
                    onChange = {(e) => props.setData((state:any) => state.map((st:any) => {
                        if(st._id === props.tr._id) {
                            return {...st, unit : e.target.value}
                        }
                        return st
                    }))}
                />
            </div>
        )
    }

}

// * PRICING
interface PricingCustomProps extends CustomCells {
    name : string, 
    data : DataType,
    setData : React.Dispatch<React.SetStateAction<DataType>>
}

export function PricingCustom(props:PricingCustomProps) {
    if(props.status === "normal") {
        return (
            <div className={`pricing ${props.name}`}>
                {props.td} €
            </div>
        )
    } else {
        return (
            <div className={`pricing--input ${props.name}--input`}>
                <input 
                    type = "text"
                    className = "input"
                    value = {props.td}
                    onChange = {(e) => props.setData(state => state.map((st) => {
                        if(st._id === props.tr._id) {
                            return {...st, [props.name] : e.target.value.replace(/[^0-9,.]/g, '').replace(/,/g, '.')}
                        }
                        return st
                    }))}
                />
                <div>€</div>
            </div>
        )
    }

}

// * QUANTITY
interface QuantityCustomProps extends CustomCells {
    data : DataType,
    setData : React.Dispatch<React.SetStateAction<DataType>>
}

export function QuantityCustom(props:QuantityCustomProps) {
    if(props.status === "normal") {
        return (
            <div className={`quantity`}>
                {props.td} €
            </div>
        )
    } else {
        return (
            <div className={`quantity--input`}>
                <input 
                    type = "text"
                    className = "input"
                    value = {props.td}
                    onChange = {(e) => props.setData(state => state.map((st) => {
                        if(st._id === props.tr._id) {
                            return {
                                ...st, 
                                quantity : e.target.value.replace(/[^0-9]/g, ''), 
                                total_ht : e.target.value.replace(/[^0-9]/g, '') + st.unit_price
                            }
                        }
                        return st
                    }))}
                />
            </div>
        )
    }

}

//* TOTAL HT
export function TotalHTCustom(props:CustomCells) {
    return (
        <div className={`total_ht`}>
            {props.td} €
        </div>
    )
}