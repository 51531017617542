
// * POPUPS
export const settings_initial_state = false


// * SETTINGS
export const settings_mode_initial_state:number = 0


// * INVOICE IMPORT & PROCESSING
export const mode_test_invoice_deposit = true