import React, {useState} from 'react'
import BasePopup from '../../tools/components/general/BasePopup'
import { Icons } from 'tc-minibox'
import { icons_config } from '../../config/context.config'
import {ReactComponent as SettingIcon} from '../../assets/svg/icons/settings.svg' ; 
import { ServerProps } from '../../config/server.config';
import Access from './access/Access';
import MyAccount from './my-account/MyAccount';
import { useSelector } from 'react-redux';
import { account_user } from '../../store/account/selector.account';
//import ConfigRH from './config_rh/ConfigRH';
import { settings_mode_initial_state } from '../../config/initials_states';


export interface SettingsProps extends ServerProps { 
    handleClose : () => void
}

export interface SettingsConfig {
    id : string, 
    admin : boolean, 
    name : string, 
    svg : string, 
    component : any
}

export default function Settings(props:SettingsProps) {
    const user_account = useSelector(account_user)

    const settings_config:SettingsConfig[] = [
        {
            id : "SETTINGS_CONFIG_01", 
            admin : false, 
            name : "Mon compte", 
            svg : "user-vneck", 
            component : <MyAccount server = {props.server}/>
        }, 
        {
            id : "SETTINGS_CONFIG_02", 
            admin : true,
            name : "Accès & Comptes", 
            svg : "id-badge", 
            component : <Access server = {props.server}/>
        }, 
        {
            id : "SETTINGS_CONFIG_03", 
            admin : true,
            name : "Affaires", 
            svg : "folder", 
            component : <Access server = {props.server}/>
        }, 
        /*{
            id : "SETTINGS_CONFIG_03", 
            admin : true,
            name : "Configuration RH", 
            svg : "table-tree", 
            component : <ConfigRH server = {props.server}/>
        }, */

    ]

    if(user_account.infos.user_group === "direction") {

    }
    const [manager, setManager] = useState({
        mode : settings_config[settings_mode_initial_state].id
    })

    const filtered_modules = user_account.infos.user_group !== "direction" ? settings_config.filter(sc => sc.admin === false) : settings_config

    return (
        <BasePopup
            nameClass = "settings"
            handleClose = {props.handleClose}
        >
            <div className = "settings--header">
                <div className='settings--header--icon'>
                    <SettingIcon />
                    <p>Paramètres</p>
                </div>
                {
                    filtered_modules.map(sc => (
                        <div 
                            key = {`THEAD-${sc.id}`}
                            className={`settings--header--item ${manager.mode === sc.id ? 'active' : ''}`}
                            onClick = {() => setManager(state => {return {...state, mode : sc.id}})}
                        >
                            <Icons name = {sc.svg} {...icons_config}/>
                            <p>{sc.name}</p>
                        </div>
                    ))
                }
            </div>

            <div className = "settings--container">
                {settings_config.find((sc:SettingsConfig) => sc.id === manager.mode)?.component}
            </div>



        </BasePopup>
    )
}