/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ServerProps } from '../../../../../../config/server.config';
import { StringUtils, TableUtils, URLUtils } from 'tc-minibox';
import { AmendmentType, amendments_type } from './utils.amendments';
import BasePopup from '../../../../../../tools/components/general/BasePopup';
import DetailedLines from '../DetailedLines';
import Totals from '../../../../../base/Totals';
import { generate_totals } from '../../../../utils.affairs';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { Table } from 'tc-minibox'
import ContainerLoad from '../../../../../../tools/components/general/ContainerLoad';
import NewAmendment from './layouts/NewAmendment';
import { addRequestStatus, setLoaderStatus} from '../../../../../../store/global/actions.global';

export interface AmendmentsProps extends ServerProps {
    rerender_detail : () => void,
    id_affair : number|string, 
    selected_affair : any,
}

interface AmendmentsManager {
    mode : "load" | "normal" | "error",
    initialize : boolean,
    selected_data : null | number, 
    add : boolean
}

export default function Amendments(props:AmendmentsProps) {
    // * REDUX AND URL PARAMS
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const id_amendment_params:string|null = searchParams.get('id_amendment');
    const dispatch = useDispatch()

    // * STATES
    const [manager, setManager] = useState<AmendmentsManager>({
        initialize : false,
        add : false, 
        selected_data : null, 
        mode: "load"
    })
    const [amendments, setAmendments] = useState<any[]|[]>([])


    // * FUNCTIONS
    const handleDelete = (data:any) => {
        if(data.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Aucune ligne sélectionnée"}))
        }
        dispatch(setLoaderStatus({status : true}))

        return props.server.post('/affairs/delete-amendment', {id_affair : data[0].id_affair, id_amendments : data.map((d:any) => d._id)})
        .then(res => {
            setManager((state:any) => {return {...state, steps : 0 }})
            props.rerender_detail()
            setAmendments((state) => state.filter(st => !data.map((d:any) => d.id_amendment).includes(st.id_amendment)))
            dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }


    // * CONFIG COMPONENTS
    
    const tbody = amendments.map(am => { 
        const foundType = amendments_type.find((type: AmendmentType) => type.name === am.type);
        return {
            ...am, 
            //date_doc : moment(am.date_doc).format("DD/MM/YYYY"), 
            type : foundType ? foundType.text : "Type non trouvé", 
            total_ht : TableUtils.formatNumberWithSpaces(am.total_ht) + " €",
            total_tva : TableUtils.formatNumberWithSpaces(am.total_tva) + " €",
            total_ttc : TableUtils.formatNumberWithSpaces(am.total_ttc) + " €",
    }})

    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            {
                info : {
                    text : "N°", 
                    name : "amendment_number", 
                }, 
 
                style : {
                    width : `60px`,
                    tbody : {
                        justifyContent : "center",
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date", 
                    name : "date_doc", 
                    type : "date"
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Type", 
                    name : "type",
                }, 
                style : {
                    width : `200px`, 
                }
            }, 
            {
                info : {
                    text : "Dénomination", 
                    name : "denomination", 
                }, 
                style : {
                    width : `calc(${50}% - 150px)`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "HT", 
                    name : "total_ht", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TVA", 
                    name : "total_tva", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "end"
                    }
                }
            }, 
            {
                info : {
                    text : "TTC", 
                    name : "total_ttc", 
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        justifyContent : "center",
                        textAlign : "end"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setAmendments,
        header : {
            buttons : {
                include : ["add" , "delete"], 
                actions : {
                    add : () => handleChangeManager('add', true),
                    delete : (data:any) => handleDelete(data)
                }
            }
        }, 
        handleDoubleClick : (tr:any) => setManager(state => {return {...state, selected_data : tr._id}}),
    }


    // * USE EFFECTS
    useEffect(() => {
        if(!manager.initialize) {
            props.server.post("/affairs/get-amendments", {id_affair : props.id_affair})
            .then(res => {
                setManager(state => {return {...state, initialize : true, mode : "normal"}})
                setAmendments(res.data.data)
                if(!StringUtils.isUndefinedValue(id_amendment_params))  {
                    handleChangeManager('selected_data', id_amendment_params)
                    URLUtils.removeURLParameter('id_amendment')
                }
            })
            .catch(err => {
                setManager(state => {return {...state, initialize : true, mode : "normal"}})
                dispatch(err.response.data)
            })
        }
        

   }, [manager.initialize])

    // * HANDLERS
    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})


    return (

        <React.Fragment>
            {
                manager.selected_data !== null ? 
                    <BasePopup
                        nameClass = "amendments-detail"
                        handleClose = {() => {
                            handleChangeManager('selected_data', null)
                            if(!StringUtils.isUndefinedValue(id_amendment_params)) {
                                navigate(`/affairs?id_affair=${props.id_affair}`)
                            }
                        }}
                    >
                        <DetailedLines
                            server = {props.server} 
                            id_affair = {props.id_affair} 
                            id_amendment = {manager.selected_data}
                        />
                    </BasePopup>
                : 
                    null
            }

            {
                manager.add ? 
                    <NewAmendment
                        rerender_detail = {props.rerender_detail}
                        server = {props.server}
                        affair_info = {props.selected_affair}
                        handleClose = {() => {
                            setManager(state => {return {...state, initialize : false, mode : "load"}})
                            handleChangeManager('add', false)
                        }} 
                        last_amendment = {amendments.map(am => parseInt(am.amendment_number)).length > 0 ? amendments.map(am => parseInt(am.amendment_number)).reduce((acc, curr) => {return Math.max(acc, curr)}) : 0}
                    />
                : 
                    null
            }
            <ContainerLoad load = {manager.mode === "load"}>
                <Table {...table_config}/>
                <Totals
                    {...generate_totals(amendments.map(ld => parseFloat(ld.total_ht)).reduce((acc, curr) => acc + curr, 0))}
                />
            </ContainerLoad>




        </React.Fragment>

    )
}