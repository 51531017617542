import { ActionType, AuthType } from "../types.store"

const initialState:AuthType = { 
    status : false, 
    token : null, 
    infos : {}
}

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

export default function account_reducer(state = initialState, action:ActionType) {
    switch(action.type) {
        case LOGIN : 
            return {
                ...state, 
                ...action.payload
            }

        case LOGOUT : 
            return initialState
        
        default : 
            return state
    }
}