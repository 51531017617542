import { useState } from "react";
import { ServerProps } from "../../../../../config/server.config";
import BasePopup from "../../../../../tools/components/general/BasePopup";
import ContainerLoad from "../../../../../tools/components/general/ContainerLoad";
import { DataType } from "tc-minibox/dist/types/table/index.table";
import Affairs from "../../../../affairs/Affairs";



export interface MemoryAffairAidProps extends ServerProps {
    handleClose : () => void; 
}


interface MemoryAffairAidManager {
    initialize : true
}

export default function MemoryAffairAid(props: MemoryAffairAidProps) {
    const [affairs, setAffairs] = useState<DataType>([])
    
  return (
    <BasePopup nameClass="memory-aid" handleClose={props.handleClose}>
        <Affairs 
            server={props.server} 
        />
    </BasePopup>
  );
}