import { useEffect, useState } from "react"
import Papa from 'papaparse'
import { useDispatch } from "react-redux"
import { ServerProps } from "../../../../../config/server.config"
import ImportModule, { ImportProps } from "../../../../../tools/components/general/ImportModule"
import { addRequestStatus, setLoaderStatus } from "../../../../../store/global/actions.global"
import { import_dext } from "./utils.deposit"
import SearchSelect from "../../../../../tools/components/general/SearchSelect"


export interface InvoiceImportProps extends ServerProps {
    form : any,
    setForm : React.Dispatch<React.SetStateAction<any>>,
    setManager : any,
    setImportedData : any,
}

const handleChangeObjectState = (setState:any, name:any, val:any) => setState((state:any) => {return{...state, [name] : val}})

export default function InvoiceImport(props:InvoiceImportProps) {
    const [search, setSearch] = useState({
        open : false, 
        status : "resolve", 
        input : "", 
        list : []
    })
    const dispatch = useDispatch()



    // * CONFIG COMPONENTS
    const import_config:ImportProps = {
        subtitle : "de type .csv (Export DEXT Prepare Default)",
        nameClass : 'new-affair--import', 
        handleSubmit : (file:File) => {
            if(file) {
                const mimetype_accepted = [
                    'text/csv'
                ]

                if(!mimetype_accepted.includes(file.type)) {
                    return dispatch(addRequestStatus({
                        status : false, 
                        message : "Le fichier importé n'est pas un fichier CSV"
                    }))
                }


                if(Object.entries(file).length > 0) {
                    dispatch(setLoaderStatus({status : true}))

        
                    const reader: FileReader = new FileReader();

                    reader.onload = (event: ProgressEvent<FileReader>) => {
                        if (!event.target || !event.target.result) return;
                    
                        const arrayBuffer: ArrayBuffer = event.target.result as ArrayBuffer;
                        const dataView: DataView = new DataView(arrayBuffer);
                        const decoder: TextDecoder = new TextDecoder('utf-8');
                        const csvText: string = decoder.decode(dataView);
                    
                        Papa.parse(csvText, {
                            complete: (results: any) => {
                                props.server.get('/invoicing/initialize-deposit')
                                    .then((res: any) => {
                                        const data = results.data;
                                        const data_convert: any = import_dext(data, res.data.data);
                                        const currentDate: Date = new Date();
                                        const currentYear: number = currentDate.getFullYear();
                                        if (!data_convert.status) {
                                            dispatch(setLoaderStatus({status : false}))
                                            return dispatch(addRequestStatus(data_convert));
                                        }

                                        /*
                                        const formattedData: any[] = data_convert.data.map((el: any, index: number) => {
                                            return {
                                                ...el, 
                                                doc_number: `${currentYear}/${(index + 1) < 10 ? "0" : ""}${index + 1}`
                                            };
                                        });
                                        
                                        */
                                        /*
                                        {
                                            "date": "2023-10-26T20:00:00.000Z",
                                            "due_date": "2023-10-26T20:00:00.000Z",
                                            "inv_number": "ACP436",
                                            "supposed_supplier": "Talarmor Distri",
                                            "total_tva": 2.35,
                                            "total_ttc": 114.35,
                                            "url": "https://rbnk.me/i/uupi2-s115E",
                                            "receipt_date": null,
                                            "id_supplier": 4,
                                            "name_supplier": "Talarmor Distri",
                                            "doc_number": "2024/01"
                                        }
                        
                                        */
                    
                                        const formattedData: any[] = data_convert.data.map((el: any, index: number) => {
                                            return {
                                                id_affairs : [
                                                    {
                                                        id : props.form.id_affair, 
                                                        description :  "", 
                                                        ht : el.total_ttc - el.total_tva,
                                                        tva : el.total_tva,
                                                    }
                                                ], 
                                                type : el.type, 
                                                dates : {
                                                    inv_date : el.date,
                                                    due_date : el.due_date, 
                                                    receipt_date : el.date
                                                }, 
                                                doc_number: `${`${currentYear}`.slice(-2)}/${`${(res.data.last_number + index)}`.padStart(4, '0')}`, 
                                                inv_number : el.inv_number,
                                                total_tva : el.total_tva,
                                                total_ttc : el.total_ttc,
                                                comment : "", 
                                                supposed_supplier: el.supposed_supplier,
                                                url: el.url,
                                                ...el.id_supplier ? 
                                                    {
                                                        id_supplier : el.id_supplier, 
                                                        name_supplier : el.name_supplier
                                                    }
                                                : {id_supplier : null}
                                                
                                            };
                                        });
                                        dispatch(setLoaderStatus({status : false}))

                                        props.setImportedData(formattedData);
                                        handleChangeObjectState(props.setManager, "mode", "processing");
                                    })
                                    .catch((err: any) => {
                                        dispatch(setLoaderStatus({status : false}));
                                        dispatch(addRequestStatus(err.response.data));
                                    });
                    
                                handleChangeObjectState(props.setForm, 'file', {});
                            },
                        });
                    };
                    
                    reader.readAsArrayBuffer(file);
        
                }
            }
        }
    }
    

    //REQ TO SEARCH CLIENTS
    useEffect(() => {
        const timeoutId = setTimeout(() => { 
            if(search.input.length > 0 && search.status === "pending") {
                props.server.post('/invoicing/search-affairs',{search_string : search.input})
                .then((res) => {
                    setSearch(state => {
                        return {
                            ...state, 
                            status : "resolve", 
                            list : res.data.data
                            //////INTEGRER LA LISTE
                        }
                    })
                })
                .catch((res) => {
                    setSearch(state => {
                        return {
                            ...state, 
                            //input : "", 
                            status : "resolve", 
                            list : []
                        }
                    })

                })

            }
        }, 500); // Attendre 1000ms avant d'envoyer la requête
        return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search.input])

    return (
        <div className='invoice-import'>
            <div>
                <label htmlFor=''>Pour quelle affaire souhaitez-vous faire un dépôt ? </label>
                <SearchSelect 
                    {...{

                        text_no_data : "Aucune affaire trouvée", 
                        handleClick : (data) => props.setForm((state:any) => {return {...state, id_affair : data.id}}),
                        handleDelete : () => props.setForm((state:any) => {return {...state, id_affair : null}}),
                        no_data_text : "Aucune affaire trouvée", 
                        server : {
                            axios : props.server,
                            route : "/invoicing/search-affairs"
                        },
                        value : props.form.id_affair
                    }}
                />
            </div>

            {
                props.form.id_affair ? 
                    <ImportModule {...import_config} />
                : 
                    null
            }
        </div>
    )
}