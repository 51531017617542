import { StringUtils } from "tc-minibox";


interface DextFormat {
    dext_name: string;
    name?: string;
    type?: "date" | "string" | "float";
    index?: number;
}

const format_dext:DextFormat[] = [
    { dext_name: "Identifiant du reçu" },
    { dext_name: "Type", name: "type"},
    { dext_name: "Date", name: "date", type : "date"},
    { dext_name: "Date d'échéance", name: "due_date", type : "date" },
    { dext_name: "N° de facture", name: "inv_number", type : "string" },
    { dext_name: "Fournisseur", name : "supposed_supplier", type : "string"},
    { dext_name: "Compte" },
    { dext_name: "Client" },
    { dext_name: "Projet" },
    { dext_name: "Moyen de paiement" },
    { dext_name: "Compte Bancaire" },
    { dext_name: "Tax", name: "total_tva", type : "float" },
    { dext_name: "Total", name: "total_ttc", type : "float" },
    { dext_name: "Devise" },
    { dext_name: "Tax (EUR)" },
    { dext_name: "Total EUR" },
    { dext_name: "Statut" },
    { dext_name: "Note" },
    { dext_name: "Description" },
    { dext_name: "Image", name: "url", type : "string" }
]

const format_bdd:DextFormat[] = format_dext.map((fd, fd_ind) => ({...fd, index: fd_ind})).filter(fd => !!fd.name);

function convertDateString(dateString: string): Date {
    const moisCorrespondants: Record<string, string> = {
        "jan.": "January",
        "fév.": "February",
        "mars": "March",
        "avr.": "April",
        "mai": "May",
        "juin": "June",
        "juil.": "July",
        "août": "August",
        "sept.": "September",
        "oct.": "October",
        "nov.": "November",
        "déc.": "December"
    };

    const [jour, moisAbbr, annee] = dateString.split('-');
    const moisComplet = moisCorrespondants[moisAbbr];
    const dateISO = `${moisComplet} ${jour}, ${annee}`;
    const date = new Date(dateISO);
  
    return date;
}


interface ImportDextResult {
    status: boolean;
    message: string;
    data?: any[];
}

export const import_dext = (data: any[], suppliers: any[]):ImportDextResult => {
    if(data[0].length !== format_dext.length) {
        return {
            status : false, 
            message : "Il y a des colonnes manquantes dans le fichier importé. Veuillez réessayer."
        }
    }


    const data_filtered = data
                            .filter(dt => StringUtils.undress_string(JSON.stringify(dt)) !== StringUtils.undress_string(JSON.stringify(format_dext.map(fd => fd.dext_name))) )
                            .filter(dt => StringUtils.undress_string(`${Object.values(dt).join('')}`) !== '')
                            .filter(dt => dt.length === format_dext.length)
                            .map(dt => {
                                return format_bdd.map((fd:any)=> {
                                    let val = fd.type === "date" ? 
                                                convertDateString(dt[fd.index]) 
                                            : fd.type === "float" ? 
                                                parseFloat(dt[fd.index])
                                            : 
                                                dt[fd.index]

                                    return [fd.name, val]
                                }).reduce((acc:any, [key, value]) => {
                                    acc[key] = value;
                                    return acc;
                                }, {});
                            })
                            .map(dt => {
                                return {
                                    ...dt, 
                                    receipt_date : new Date(),
                                    due_date : new Date(),
                                    type : /^avoir/.test(StringUtils.undress_string(`${dt.type}`)) ? "credit-note" : "invoice", 
                                    is_invoice_on_receipt : false, 
                                }
                            })


    const result = data_filtered.map((rs:any) => {
        const get_match = suppliers.find(sup => StringUtils.compare_perfect_strings(StringUtils.undress_string(sup.name), StringUtils.undress_string(rs.supposed_supplier)))

        if(get_match) {
            return {...rs, id_supplier : get_match._id, name_supplier : get_match.name}
        } 
        return {...rs, id_supplier : null}
    })


    const result_final = filter_dext(result) 

    
    //let val = fd.name === "due_date" ? convertDateString(dt[fd.index]) : dt[fd.index]

    return {
        status : true, 
        message : "Données interprétées",
        data : [...result_final.to_revise, ...result_final.ready]
    }
}

type Affair = {
    id: number;
    ht: number;
    tva: number;
};

type Dates = {
    inv_date: string | null;
    due_date: string | null;
    receipt_date: string | null;
};

interface DataObject {
    id_affairs: Affair[];
    dates: Dates;
    doc_number: string;
    inv_number: string;
    total_tva: number;
    total_ttc: number;
    comment: string;
    supposed_supplier: string;
    url: string;
    id_supplier: number;
    name_supplier: string;
}

function isValueEmpty(value: any): boolean {
    if (value === null || value === undefined || value === '') return true;
    if (typeof value === 'object') {
        for (let key in value) {
            if(key === 'description') continue
            if (isValueEmpty(value[key])) return true;
        }
    }
    return false;
}

export function checkInvStatus(obj: DataObject): boolean {
    for (let key in obj) {
        // On ignore le champ 'comment'
        if (['comment', 'comments_log', "payments_log", "reconcile", "sent_to_accountant", "validation", "id_doc", "status_log"].includes(key)) continue;

        // Vérifie si la propriété est vide
        if (isValueEmpty(obj[key as keyof DataObject])) {
            return false; // Retourne false si une valeur vide est trouvée
        }
    }
    return true; // Toutes les valeurs (sauf 'comment') sont non vides
}

interface FilteredData {
    ready: any[];
    to_revise: any[];
}


export function filter_dext(data_filtered: any[]): FilteredData {
    const result = data_filtered.reduce((acc, objet) => {
        if (!checkInvStatus(objet)) {
          acc.to_revise.push(objet);
        } else {
          acc.ready.push(objet);
        }
        return acc;
      }, { ready: [], to_revise: [] });
    
      return result;
}

